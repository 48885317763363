/*------------------------------------*\
		$WRAPPER
\*------------------------------------*/

/**
 * This is ued for the Catalog wrapper
 */

.wrapper {
	max-width: 1200px;
	margin: auto;
}