/*------------------------------------*\
    #BUTTON
\*------------------------------------*/

@import "@material/ripple/mixins";

.button-secondary { 
	@include mdc-button-ink-color($mdc-theme-secondary);
	@include mdc-states($mdc-theme-secondary);
}

.button-secondary-filled { 
	@include mdc-button-filled-accessible($mdc-theme-secondary); 
}

.button-tertiary-filled {
	@include mdc-button-container-fill-color(rgba(0, 0, 0, 0.08));
	@include mdc-button-ink-color(primary);
	@include mdc-states(primary);
}