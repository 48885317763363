@import "@material/ripple/mixins";

.theme-switcher {
  display: flex;
  flex-direction: row;
  margin-top: -100px;
  display: none;
  
  .selection {
  margin-bottom: 1rem;
  margin-right: 1rem;

  label {
    @include mdc-theme-prop(color, secondary);
    display: inline-block;
    font-size: 0em;
    padding: 1rem;
    cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" style="font-size: 32px"><text y="28">🍦</text></svg>'),
  	auto;

    &.yellow{
      background-color: #FAD000;

      &:hover {
        @include mdc-theme-prop(background-color, surface);
      }
    }
    &.beige{
      background-color: #EDC7A2;

      &:hover {
        @include mdc-theme-prop(background-color, surface);
      }
    }
    &.red{
      background-color: #FF4438;

      &:hover {
        @include mdc-theme-prop(background-color, surface);
      }
    }
    &.black{
      background-color: #212721;

      &:hover {
        @include mdc-theme-prop(background-color, surface);
      }
    }

    // &:hover {
    //   @include mdc-theme-prop(background-color, surface);
    // }
  }

  input[type=radio] {
    display: none;
  }

  input[type=radio]:checked ~ label {
    @include mdc-button-ink-color($mdc-theme-primary);
    @include mdc-states($mdc-theme-primary);
  }
}
}
