.tray {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 150;
	pointer-events: none;
}

.tray--visible .tray__content {
	pointer-events: auto;
}

.tray__content {
	position: absolute;
	width: 100%;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
	bottom: 0;
	display: flex;
	flex-direction: column;
	z-index: 160;
}

.tray__content--animatable {
	will-change: transform;
	transition: all 150ms ease-out;
}

.tray__content--animate-down {
	transform: translateY(calc(100% + 8px)); // extra for shadow
}

.tray--scrim {
	pointer-events: auto;
}

.tray__scrim {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.4);
}

.tray__scrim--animate-fadeout {
	animation: TrayScrimFadeOut 0.15s ease-out;
}

.tray__scrim--animate-fadein {
	animation: TrayScrimFadeIn 0.15s ease-out;
}

@keyframes TrayScrimFadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 100;
	}
}

@keyframes TrayScrimFadeOut {
	from {
		opacity: 100;
	}
	to {
		opacity: 0;
	}
}