.section {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  height: 100vh;
  padding: 20px 30px;
  box-sizing: border-box;
  z-index: 9;
  width: 100vw;
  overflow-x: hidden;
  will-change: transform;
  transition: transform .5s cubic-bezier(0, 1.15, .5, 1);

  @include media-query(phone) {
    position: fixed;
  }
  
  &--header {
    display: flex;
    position: sticky;
    top: 5px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
  }

  &--content {
    position: absolute;
    width: 90vw;
    padding: 0 20px;
    top: 250px;
    z-index: 9;

    @include media-query(phone) {
      width: 85vw;
      padding: 0;
      top: 100px;
    }

    &--contact,
    &--jobs {
      top: 150px;

      @include media-query(phone) {
        top: 100px;
      }
    }

  }

  &--image {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    z-index: 2;

    .image--top {
      position: absolute;
      top: 30%;
      left: 0;
      height: 75%;
      width: auto;
    }
    .image--bottom {
      position: absolute;
      top: 10%;
      right: 10%;
    }
  }
  &--img {
    border: 15px solid white;
  }

  &__about,
  &__menu,
  &__location,
  &__contact,
  &__socials,
  &__jobs{
    @include mdc-theme-prop(background-color, secondary);
    transform: translate(0, -110%);
    transition: transform .5s cubic-bezier(0, 1.15, .5, 1);

    h1 {
      @include mdc-theme-prop(color, surface);
      font-size: 4rem;
      font-weight: 900;
      text-transform: uppercase;
      margin-bottom: 0;
      margin-top: 0;

      @include media-query(tablet) {
        font-size: 12rem;
      }
    }

    p {
      @include mdc-theme-prop(color, surface);
      font-size: 1.2rem;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 0;

      @include media-query(phone) {
        font-size: 1.75rem;
      }

      @include media-query(tablet) {
        font-size: 2.4rem;
      }

      .section--close {
        @include mdc-theme-prop(color, surface);
      }
    }

    &.is-visible {
      transform: translate(0, 0);
    }
  }

  &__location {
    @include mdc-theme-prop(background-color, surface);

    h1 {
      @include mdc-theme-prop(color, primary);
      text-align: center;
      justify-content: center;
    }

    a {
      @include mdc-theme-prop(color, primary);
      text-align: center;
      justify-content: center;

      .location--large{
        font-size: 2.5em;
        font-weight: 900;
        margin-top: 30px;
      }
      .location--small{
        font-size: 1.25em;
      }
    }

    .location-section--close {
      @include mdc-theme-prop(fill, primary);
    }

    .hoodburger-text-logo {
      @include mdc-theme-prop(fill, primary);
    }

    .northbridge--image {
      left: 5%;
      top: 150px;
      width: 400px;

      img {
        width: 100%;
      }
    }

    .inglewood--image {
      left: 60%;
      top: 400px;
      width: 500px;

      img {
        width: 100%;
      }
    }
  }
  &__contact {
    h1 {
      text-align: center;
      justify-content: center;
    }
  }

  &__socials{
    @include mdc-theme-prop(background-color, background);

    h1 {
      @include mdc-theme-prop(color, primary);
      text-align: center;
      justify-content: center;
    }

    p {
      @include mdc-theme-prop(color, on-surface);
    }

    a {
      @include mdc-theme-prop(color, primary);
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      margin-top: 30px;

      .socials--large{
        font-size: 2.5em;
        font-weight: 900;
      }
      .image--socials{
        margin-right: 10px;
        width: 3rem;
        height: 3rem;
      }

      &:hover {
        @include mdc-theme-prop(color, primary);
      }
    }

    .section--close {
      @include mdc-theme-prop(color, on-surface);
      @include mdc-theme-prop(fill, on-surface);
    }

    .hoodburger-text-logo {
      @include mdc-theme-prop(fill, on-surface);
    }
  }

  &__jobs,
  &__menu {
    @include mdc-theme-prop(background-color, on-surface);

    h1 {
      @include mdc-theme-prop(color, background);
      text-align: center;
      justify-content: center;
    }

    p {
      @include mdc-theme-prop(color, background);
    }

    .section--close {
      @include mdc-theme-prop(color, background);
    }

    .hoodburger-text-logo {
      @include mdc-theme-prop(fill, background);
    }
  }

  &--close {
    width: 60px;
    height: 60px;
    align-self: flex-end;
    margin-left: auto;
    margin-right: 0;
    @include mdc-theme-prop(fill, surface);

    @include media-query(tablet) {
      width: 120px;
      height: 120px;
    }

    &:hover {
      @include mdc-theme-prop(color, primary);
      cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" style="font-size: 32px"><text y="28">🍟</text></svg>'),
      auto;
    }
  }
}

.menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;

  &__item {
    display: flex;
    flex-direction: column;
    width: calc(95% - 24px);
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 32px;
    &:nth-of-type(odd) {
      margin-left: 48px;
      @include media-query(tablet) {
        margin-top: 40px;
      }
    }
    @include media-query(tablet) {
      width: calc(45% - 24px);
    }

    &:nth-child(3n+2) .menu__image {
      @include mdc-theme-prop(background-color, primary);
    }

    &:nth-child(3n+0) .menu__image  {
      @include mdc-theme-prop(background-color, secondary);
    }
  }
  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    @include mdc-theme-prop(background-color, surface);
    overflow: hidden;
    margin-bottom: 24px;

    &-img {
      display: flex;
      width: 100%;
      min-width: 300px;
      @include media-query(tablet) {
        min-width: 500px;
      }
    }
  }
  &__title {
    display: flex;
    font-size: 2rem;
    font-weight: 900;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
  }
  &__description {
    display: flex;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: 600;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
  }
}
