/*------------------------------------*\
    $ICONS
\*------------------------------------*/

/**
 * 1. Enables the SVG to accept the color attribute of the parent so it works with text.
 * https://css-tricks.com/cascading-svg-fill-color/
 */

.icon {
	width: 24px;
	height: 24px;
	fill: currentColor; /* [1] */
}