.hoodburger__header {
    @include mdc-theme-prop(color, primary);
    display: flex;
    align-items: center;
    @include mdc-theme-prop(background-color, surface);
    padding: .5rem .1rem;
    position: relative;
    overflow: hidden;
    font-size: 1.5rem;
    line-height: .75em;
    white-space: nowrap;
    text-align: left;
}
.hoodburger__headerBlock {
    display: inline-block;
    margin: 0 .4rem;
    animation: marquee 60s linear infinite reverse;
}
.shakka {
  height: 25px;
  width: auto;
  margin-left: 10px;
  margin-top: -5px;
}

.hoodburger__headerBlock:nth-child(5n-4) {
    font-family: 'Chewy', cursive;
}
.hoodburger__headerBlock:nth-child(5n-3) {
    font-family: 'Spicy Rice', cursive;
}
.hoodburger__headerBlock:nth-child(5n-2) {
    font-family: 'Lemon', cursive;
}
.hoodburger__headerBlock:nth-child(5n-1) {
    font-family: 'Titan One', cursive;
}
.hoodburger__headerBlock:nth-child(5n) {
    font-family: 'Shrikhand', cursive;
}

@keyframes marquee {
  0% {
    transform: translateX(0%); }
  100% {
    transform: translateX(-100vw); }
}


// font-family: 'Shrikhand', cursive;
// font-family: 'Lemon', cursive;
// font-family: 'Titan One', cursive;
// font-family: 'Spicy Rice', cursive;
// font-family: 'Chewy', cursive;
// font-family: 'Chango', cursive;
