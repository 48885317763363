::-webkit-scrollbar {
	width: $webkitScrollBarWidth;
}

::-webkit-scrollbar-thumb {
	@include mdc-theme-prop(background-color, primary);
	border: 2px solid;
	@include mdc-theme-prop(border-color, primary);
	min-height: 50px;
}

::-webkit-scrollbar-track {
	@include mdc-theme-prop(background-color, primary);
}

body {
  scrollbar-face-color:$mdc-theme-primary;
  scrollbar-track-color: $mdc-theme-primary;
}
