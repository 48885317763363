.homepage {
  display: flex;
  position: relative;
  z-index: 3;
  align-items: center;
  height: 100vh;
  @include media-query(phone) {
    overflow-y: hidden;
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    @include media-query(phone) {
      align-items: center;
    }
  }

  &__header {
    @include mdc-theme-prop(color, text-primary-on-light);
    font-size: 8rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 48px;

    @include media-query(phone-small){
      height: 100vh;
      margin: 0;
      box-sizing: border-box;
      padding: 40px 0px 20px 0px;
      font-size: 7.5rem;
      word-break: break-all;
      text-align: left;
      line-height: 0.8;
    }
    @include media-query(phone) {
      height: 100vh;
      width: 100vw;
      margin: 0;
      box-sizing: border-box;
      padding: 80px 10px 40px 10px;
      font-size: 8rem;
      word-break: break-all;
      text-align: left;
      line-height: 0.8;
    }
    @include media-query(phone-small) {
      font-size: 5rem;
    }

    @include media-query(tablet) {
      font-size: 12rem;
    }

    sup {
      font-size: 2rem;
    }
  }
  &__menu {
    @include mdc-theme-prop(color, text-primary-on-light);
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    font-size: 8rem;
    font-weight: 900;
    text-transform: uppercase;

    @include media-query(tablet) {
      font-size: 12rem;
    }

    &-item {
      position: relative;
      z-index: 9;
      margin: 0 20px;
      text-decoration: none;
      cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" style="font-size: 32px"><text y="28">🍟</text></svg>'),
      auto;

      a {
        text-decoration: none;
      }

      &:hover{
        @include mdc-theme-prop(color, surface);
      }

      @include media-query(phone) {
        margin: 0;
        box-sizing: border-box;
        padding: 20px;
        font-size: 8rem;
        text-align: left;
        line-height: 0.75em;
      }
    }
  }
}

.order-link {
  @include mdc-theme-prop(color, text-primary-on-light);

  &:hover{
    @include mdc-theme-prop(color, surface);
  }
}

.hoodburger-smile {
    width: 240px;
    height: 240px;
    position: sticky;
    position: -webkit-sticky;
    left: 20vw;
    transform: translate(0,5%);

    @include media-query(phone) {
      width: 125px;
      height: 125px;
      margin-bottom: -100px
    }
}

.hoodburger-text-logo {
  width: 400px;
  height: auto;
  align-items: flex-start;
  margin-left: 0;
  margin-right: auto;
  @include mdc-theme-prop(fill, surface);
}

.good-munchin {
    width: 860px;
    margin: 24px;
    height: auto;

    .outline {
      @include mdc-theme-prop(fill, surface);
    }
    .good {
      @include mdc-theme-prop(fill, surface);
    }
    .munchin {
      @include mdc-theme-prop(fill, surface);
    }
    @include media-query(phone) {
      width: auto;
      height: 50vw;
      margin-left: 40px;
      transform: rotate(45deg);
    }
}

.location-postcode {
  @include mdc-theme-prop(fill, surface);
  width: 200px;
  height: auto;
  z-index: 9;
  position: sticky;
  position: -webkit-sticky;

  &--top {
    transform: translate(0,0);
    left: 200px;
    @include media-query(phone) {
      left: 50px;
    }
  }
  &--bottom {
    transform: translate(180%,0);
    right: 200px;
    @include media-query(phone) {
      transform: translate(80%,0);
      right: 200px;
    }
    @include media-query(tablet) {
      transform: translate(300%,0);
    }
  }

  &:hover {
    @include mdc-theme-prop(color, text-primary-on-light);
  }
}

.negative-ml {
  margin-left: -120px;
}

.header__logo:hover,
.good-munchin:hover {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
