/*------------------------------------*\
		$PAGE
\*------------------------------------*/

/**
 * This is used to
 */

@import "@material/theme/mixins";

html {
	@include mdc-theme-prop(background-color, primary);
	@include mdc-theme-prop(color, text-primary-on-light);
	font-family: nimbus-sans, "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

.scroll-lock {
    max-width: 100%;
    overflow-x: hidden;
}

[hidden] {
	display: none !important;
}
