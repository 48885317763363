@function pow($number, $exponent) {
  $value: 1;
  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: $value * $number;
    }
  }
  @return $value;
}

$n: 9;
$d: 7;
$flip: 30%;
$duration: 1s;

$h: 110px;
$m: 90px;

.counter {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  margin-left: -20px;

  @include media-query(phone) {
    width: 100%;
    margin-left: -30px;
    margin-top: 60px;
    justify-content: center;
  }
  @include media-query(tablet) {
    width: 100%;
    margin-left: 0;
    justify-content: center;
  }

  &-header {
    font-family: 'M PLUS Rounded 1c', sans-serif;
    position: absolute;
    width: 100%;
    margin: 0;
    font-size: 1.4rem;
    font-weight: 900;
    color: $mdc-theme-surface;
    text-transform: uppercase;
    text-align: center;
    justify-content: center;
    @include media-query(phone) {
      font-size: 2rem;
      margin-top: 110px;
    }
    @include media-query(tablet) {
      font-size: 4rem;
      margin-top: 120px;
    }
  }
}
.digit {
  width: $m * 2 / 3;
  height: $h;
  display: inline-block;
  position: relative;

  &:nth-child(n + 2) {
    margin-left: 16px;
  }

  @include media-query(phone) {
    width: $m * 1 / 2;
    height: $h * 1 / 2;

    &:nth-child(n + 2) {
      margin-left: 6px;
    }
  }

  @include media-query(tablet) {
    width: $h * 2 / 3;

    &:nth-child(n + 2) {
      margin-left: 25px;
    }
  }
}

[class^="card"] {
  width: 100%;
  height: 100%;
  position: absolute;
  border-width: 1px 0;
  perspective: 300px;

  @include media-query(tablet) {
    perspective: 500px;
  }

  &:before, &:after {
    content: attr(data-content);

    width: 100%;
    height: 50%;
    position: absolute;
    border: 0px solid rgba(#651d18, 0.5);
    overflow: hidden;
    border-radius: 10px;
    color: rgb(255, 255, 255);
    font-size: $m;
    font-weight: bold;
    line-height: $h;
    text-align: center;
    @include media-query(phone) {
      font-size: $m * 1 / 2;
      line-height: $h * 1 / 2;
    }

    @include media-query(tablet) {
      font-size: $h;
      font-weight: bold;
      line-height: $h;
    }
  }

  &:before {
    border-top-color: rgba(#651d18, 0.25) ;
    border-width: 2px 0 2px;
    box-shadow: 0px 0px 10px 0px rgba(118,31,25,0.025);
    background-color: $mdc-theme-surface;
    transform: rotateX(0deg);
    transform-origin: bottom;
  }

  &:after {
    margin-top: $h / 2;
    border-top-width: 2px;
    box-shadow: 0px 0px 10px 0px rgba(118,31,25,0.025);
    background-color: $mdc-theme-surface;
    line-height: 0;
    transform: rotateX(0deg);
    transform-origin: top;

    @include media-query(phone) {
      margin-top: $h / 4;
    }
  }
}

@for $digit from 1 through $d {
  $var: pow($n, $d - $digit);
  $duration: $var * 1s;

  @for $i from 0 through $n - 1 {
    .digit:nth-child(#{$digit}) .card#{$i} {
      animation: card#{$digit}#{$i} $duration * $n step-end infinite;

      &:before, &:after {
        animation: $duration * $n linear infinite;
      }

      &:before {
        animation-name: top#{$digit}#{$i};
      }
      &:after {
        animation-name: bottom#{$digit}#{$i};
      }
    }

    $dura: 100% / $n;
    $animation-d: $dura * $flip / (100% * $var);
    $wait: $dura - $animation-d;
    $start: $i * 100% / $n + $wait;

    @keyframes card#{$digit}#{$i} {
      @if ($i == 0) {
        #{100% - $animation-d / 2} {
          z-index: 2;
        }
      }
      #{max($start - $wait - $animation-d / 2, 0%)} {
        z-index: 2;
      }
      #{$start + $animation-d / 2} {
        z-index: 1;
      }
      #{$start + $animation-d} {
        z-index: 0;
      }
    }

    @keyframes top#{$digit}#{$i} {
      0%, #{$start} {
        transform: rotateX(0deg);
      }
      #{$start + $animation-d / 2} {
        transform: rotateX(-90deg);
        animation-timing-function: step-end;
      }
      @if $i == 0 {
        #{100% - $animation-d} {
          transform: rotateX(0deg);
        }
      }
      100% {
        transform: rotateX(0deg);
      }
    }

    @keyframes bottom#{$digit}#{($i + 1) % $n} {
      0% {
        animation-timing-function: step-end;
        transform: rotateX(0deg); // Firefox requires this while Chrome & Safari don't
      }
      #{$start}, #{$start + $animation-d / 2} {
        transform: rotateX(90deg);
      }
      #{$start + $animation-d}, 100% {
        transform: rotateX(0deg);
      }
    }
  }
}
