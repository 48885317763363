/*------------------------------------*\
		$PAGE-LAYOUT
\*------------------------------------*/

/**
 * Layout classes for the page
 */

.page-layout-panel {
	display: flex;
}

.page-layout-content {
	padding: 32px 32px;
	display: flex;
	flex: 1;
	align-items: center;
	height: calc(100vh - 128px);
}

.image-area {
	position: fixed;
	z-index: 9;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100vw;
	height: calc(100vh - 200px);
}

.meat-star {
	height: 80px;
	width: auto;
	position: fixed;
	z-index: 9;
	animation: moveX 60s linear 0s infinite alternate, moveY 10s linear 0s infinite alternate;
}
.perth-family-restaurant {
	height: 90px;
	width: auto;
	position: fixed;
  bottom: -400px;
	z-index: 9;
	animation: moveXX 100s linear 5s infinite alternate, moveYY 30s linear 5s infinite alternate;
}
.hoodburger-script {
	height: 100px;
	width: auto;
	position: fixed;
	z-index: 9;
  top: -150px;
	animation: moveXXX 120s linear 10s infinite alternate, moveY 25s linear 10s infinite alternate;
}
.hoodburger-outlined {
	height: 110px;
	width: auto;
	position: fixed;
	z-index: 9;
	bottom: -250px;
	animation: moveXXXX 90s linear 15s infinite alternate, moveYY 90s linear 15s infinite alternate;
}
.hoodburger-h {
	height: 70px;
	width: auto;
	position: fixed;
	z-index: 9;
	top: -300px;
	animation: moveXXXXX 100s linear 20s infinite alternate, moveY 100s linear 20s infinite alternate;
}
.hoodburger-cheeseburger {
	height: 80px;
	width: auto;
	position: fixed;
	z-index: 9;
	bottom: -400px;
	animation: moveXXXXX 40s linear 20s infinite alternate, moveYY 40s linear 20s infinite alternate;
}
.hoodburger-tendersandwich {
	height: 90px;
	width: auto;
	position: fixed;
	z-index: 9;
	top: -300px;
	animation: moveXXXXXX 80s linear 25s infinite alternate, moveY 80s linear 25s infinite alternate;
}
.hoodburger-frenchfries {
	height: 100px;
	width: auto;
	position: fixed;
	z-index: 9;
	bottom: -300px;
	animation: moveXXXXXXX 60s linear 30s infinite alternate, moveYY 60s linear 30s infinite alternate;
}
@keyframes moveX {
	from { left: 0px; } to { left: 100%; }
}
@keyframes moveXX {
	from { right: 0px; } to { right: 100%; }
}
@keyframes moveXXX {
	from { left: 100px; } to { left: 100%; }
}
@keyframes moveXXXX {
	from { right: 100px; } to { right: 100%; }
}
@keyframes moveXXXXX {
	from { left: 200px; } to { left: 100%; }
}
@keyframes moveXXXXXX {
	from { right: 200px; } to { right: 100%; }
}
@keyframes moveXXXXXXX {
	from { left: 300px; } to { left: 100%; }
}
@keyframes moveXXXXXXXX {
	from { right: 300px; } to { right: 100%; }
}
@keyframes moveY {
	from { top: 0; } to { top: calc(100vh - 50px); }
}
@keyframes moveYY {
	from { bottom: 0; } to { bottom: calc(100vh - 50px); }
}
