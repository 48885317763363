/*------------------------------------*\
		$header
\*------------------------------------*/

.mdc-top-app-bar {
  width: auto;
  left: 0;
  right: 0;
  background-color: transparent;

  @include media-query(tablet) {
    width: 100%;
  }
}
.mdc-top-app-bar__row{
  height: 192px;
  @include media-query(phone) {
    height: 150px;
    width: 100vw;
  }
}

.header__logo {
  margin: 16px;
  @include media-query(phone) {
    margin: 20px auto;
  }
}

.header__logo-img {
  height: 160px;

  @include media-query(phone) {
    display: none;
  }
}
