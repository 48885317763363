@charset "UTF-8";
/*------------------------------------*\
    #HOODBURGER
\*------------------------------------*/
/**
 * CONTENTS
 *
 * SETTINGS - Global variables, site-wide settings, config switches, etc.
 *
 * TOOLS - Site-wide mixins and functions.
 *
 * GENERIC - Low-specificity, far-reaching rulesets (e.g. resets).
 *
 * ELEMENTS - Unclassed HTML elements (e.g. a {}, blockquote {}, address {}).
 *
 * COMPONENTS - Discrete, complete chunks of UI (e.g. .button, .carousel {})
 *
 * UTILITIES - High-specificity, very explicit selectors. Overrides and helper classes (e.g. .u-hidden {}).
*/
/**
 * #SETTINGS
 */
/* MDC */
/* Message Feedback */
/* Scroll Bars */
html {
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" style="font-size: 120px"><text y="120">👉🏻</text></svg>'), auto; }

a {
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" style="font-size: 32px"><text y="28">🍔</text></svg>'), auto; }

input {
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" style="font-size: 32px"><text y="28">🍦</text></svg>'), auto; }

/**
 * #TOOLS
 */
/**
 * #GENERIC
 */
body {
  margin: 0; }

/*------------------------------------*\
    $RESPONSIVE
\*------------------------------------*/
/**
 * Simple Mixin media query
 * These can be invoked later on via the `media-query()` mixin found in `_tools.responsive`.
 * Use em instead of pixel https://zellwk.com/blog/media-query-units/
 */
/**
 * phone-small 0 - 359px
 * phone 0 - 1024px
 * tablet 769px - 1024px
 * desktop 1025px +
 */
/**
 * #ELEMENTS
 */
/*------------------------------------*\
		$PAGE
\*------------------------------------*/
/**
 * This is used to
 */
html {
  background-color: #FAD000;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #FAD000);
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
  font-family: nimbus-sans, "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; }

.scroll-lock {
  max-width: 100%;
  overflow-x: hidden; }

[hidden] {
  display: none !important; }

/*------------------------------------*\
    $RESPONSIVE
\*------------------------------------*/
/**
 * #COMPONENTS
 */
:root {
  --mdc-theme-primary: #FAD000;
  --mdc-theme-secondary: #EDC7A2;
  --mdc-theme-background: #212721;
  --mdc-theme-surface: #FF4438;
  --mdc-theme-error: #b00020;
  --mdc-theme-on-primary: #000;
  --mdc-theme-on-secondary: #000;
  --mdc-theme-on-surface: #fff;
  --mdc-theme-on-error: #fff;
  --mdc-theme-text-primary-on-background: white;
  --mdc-theme-text-secondary-on-background: rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-background: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-background: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-background: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-primary-on-light: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-light: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-dark: white;
  --mdc-theme-text-secondary-on-dark: rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-dark: rgba(255, 255, 255, 0.5); }

.mdc-theme--primary {
  color: #FAD000 !important;
  /* @alternate */
  color: var(--mdc-theme-primary, #FAD000) !important; }

.mdc-theme--secondary {
  color: #EDC7A2 !important;
  /* @alternate */
  color: var(--mdc-theme-secondary, #EDC7A2) !important; }

.mdc-theme--background {
  background-color: #212721;
  /* @alternate */
  background-color: var(--mdc-theme-background, #212721); }

.mdc-theme--surface {
  background-color: #FF4438;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #FF4438); }

.mdc-theme--error {
  color: #b00020 !important;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020) !important; }

.mdc-theme--on-primary {
  color: #000 !important;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #000) !important; }

.mdc-theme--on-secondary {
  color: #000 !important;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, #000) !important; }

.mdc-theme--on-surface {
  color: #fff !important;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #fff) !important; }

.mdc-theme--on-error {
  color: #fff !important;
  /* @alternate */
  color: var(--mdc-theme-on-error, #fff) !important; }

.mdc-theme--text-primary-on-background {
  color: white !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, white) !important; }

.mdc-theme--text-secondary-on-background {
  color: rgba(255, 255, 255, 0.7) !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7)) !important; }

.mdc-theme--text-hint-on-background {
  color: rgba(255, 255, 255, 0.5) !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--text-disabled-on-background {
  color: rgba(255, 255, 255, 0.5) !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-background, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--text-icon-on-background {
  color: rgba(255, 255, 255, 0.5) !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--text-primary-on-light {
  color: rgba(0, 0, 0, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)) !important; }

.mdc-theme--text-secondary-on-light {
  color: rgba(0, 0, 0, 0.54) !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54)) !important; }

.mdc-theme--text-hint-on-light {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38)) !important; }

.mdc-theme--text-disabled-on-light {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38)) !important; }

.mdc-theme--text-icon-on-light {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-light, rgba(0, 0, 0, 0.38)) !important; }

.mdc-theme--text-primary-on-dark {
  color: white !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white) !important; }

.mdc-theme--text-secondary-on-dark {
  color: rgba(255, 255, 255, 0.7) !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)) !important; }

.mdc-theme--text-hint-on-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--text-disabled-on-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--text-icon-on-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-dark, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--primary-bg {
  background-color: #FAD000 !important;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #FAD000) !important; }

.mdc-theme--secondary-bg {
  background-color: #EDC7A2 !important;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #EDC7A2) !important; }

.mdc-elevation--z0 {
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z1 {
  -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z2 {
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z3 {
  -webkit-box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z4 {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z5 {
  -webkit-box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z6 {
  -webkit-box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z7 {
  -webkit-box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z8 {
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z9 {
  -webkit-box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z10 {
  -webkit-box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z11 {
  -webkit-box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z12 {
  -webkit-box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z13 {
  -webkit-box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z14 {
  -webkit-box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z15 {
  -webkit-box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z16 {
  -webkit-box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z17 {
  -webkit-box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z18 {
  -webkit-box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z19 {
  -webkit-box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z20 {
  -webkit-box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z21 {
  -webkit-box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z22 {
  -webkit-box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z23 {
  -webkit-box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z24 {
  -webkit-box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); }

.mdc-elevation-transition {
  -webkit-transition: -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow; }

.mdc-typography {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

.mdc-typography--headline1 {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 6rem;
  line-height: 6rem;
  font-weight: 300;
  letter-spacing: -0.01562em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline2 {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 3.75rem;
  line-height: 3.75rem;
  font-weight: 300;
  letter-spacing: -0.00833em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline3 {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 3rem;
  line-height: 3.125rem;
  font-weight: 400;
  letter-spacing: normal;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline4 {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 2.125rem;
  line-height: 2.5rem;
  font-weight: 400;
  letter-spacing: 0.00735em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline5 {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
  letter-spacing: normal;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline6 {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 600;
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--subtitle1 {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--subtitle2 {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 600;
  letter-spacing: 0.00714em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--body1 {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.03125em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--body2 {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--caption {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.03333em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--button {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 600;
  letter-spacing: 0.08929em;
  text-decoration: none;
  text-transform: uppercase; }

.mdc-typography--overline {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 2rem;
  font-weight: 600;
  letter-spacing: 0.16667em;
  text-decoration: none;
  text-transform: uppercase; }

.mdc-top-app-bar {
  background-color: #FAD000;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #FAD000);
  color: rgba(0, 0, 0, 0.87);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  z-index: 4; }
  .mdc-top-app-bar .mdc-top-app-bar__action-item,
  .mdc-top-app-bar .mdc-top-app-bar__navigation-icon {
    color: #000;
    /* @alternate */
    color: var(--mdc-theme-on-primary, #000); }
    .mdc-top-app-bar .mdc-top-app-bar__action-item::before, .mdc-top-app-bar .mdc-top-app-bar__action-item::after,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon::before,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon::after {
      background-color: #000; }
      @supports not (-ms-ime-align: auto) {
        .mdc-top-app-bar .mdc-top-app-bar__action-item::before, .mdc-top-app-bar .mdc-top-app-bar__action-item::after,
        .mdc-top-app-bar .mdc-top-app-bar__navigation-icon::before,
        .mdc-top-app-bar .mdc-top-app-bar__navigation-icon::after {
          /* @alternate */
          background-color: var(--mdc-theme-on-primary, #000); } }
    .mdc-top-app-bar .mdc-top-app-bar__action-item:hover::before,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon:hover::before {
      opacity: 0.04; }
    .mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):focus::before, .mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded--background-focused::before,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):focus::before,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--background-focused::before {
      -webkit-transition-duration: 75ms;
              transition-duration: 75ms;
      opacity: 0.12; }
    .mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded)::after,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded)::after {
      -webkit-transition: opacity 150ms linear;
      transition: opacity 150ms linear; }
    .mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):active::after,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):active::after {
      -webkit-transition-duration: 75ms;
              transition-duration: 75ms;
      opacity: 0.16; }
    .mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.16; }
  .mdc-top-app-bar__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    height: 64px; }
  .mdc-top-app-bar__section {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-width: 0;
    padding: 8px 12px;
    z-index: 1; }
    .mdc-top-app-bar__section--align-start {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-ordinal-group: 0;
          -ms-flex-order: -1;
              order: -1; }
    .mdc-top-app-bar__section--align-end {
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
      -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
              order: 1; }
  .mdc-top-app-bar__title {
    font-family: sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 600;
    letter-spacing: 0.0125em;
    text-decoration: inherit;
    text-transform: inherit;
    /* @noflip */
    padding-left: 20px;
    /* @noflip */
    padding-right: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    z-index: 1; }
    [dir="rtl"] .mdc-top-app-bar__title, .mdc-top-app-bar__title[dir="rtl"] {
      /* @noflip */
      padding-left: 0;
      /* @noflip */
      padding-right: 20px; }
  .mdc-top-app-bar__action-item, .mdc-top-app-bar__navigation-icon {
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    will-change: transform, opacity;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 48px;
    height: 48px;
    padding: 12px;
    border: none;
    outline: none;
    background-color: transparent;
    fill: currentColor;
    color: inherit;
    text-decoration: none;
    cursor: pointer; }
    .mdc-top-app-bar__action-item::before, .mdc-top-app-bar__action-item::after, .mdc-top-app-bar__navigation-icon::before, .mdc-top-app-bar__navigation-icon::after {
      position: absolute;
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      content: ""; }
    .mdc-top-app-bar__action-item::before, .mdc-top-app-bar__navigation-icon::before {
      -webkit-transition: opacity 15ms linear, background-color 15ms linear;
      transition: opacity 15ms linear, background-color 15ms linear;
      z-index: 1; }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded::before, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::before {
      -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
              transform: scale(var(--mdc-ripple-fg-scale, 1)); }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::after {
      top: 0;
      /* @noflip */
      left: 0;
      -webkit-transform: scale(0);
              transform: scale(0);
      -webkit-transform-origin: center center;
              transform-origin: center center; }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded--unbounded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--unbounded::after {
      top: var(--mdc-ripple-top, 0);
      /* @noflip */
      left: var(--mdc-ripple-left, 0); }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded--foreground-activation::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--foreground-activation::after {
      -webkit-animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
              animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded--foreground-deactivation::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--foreground-deactivation::after {
      -webkit-animation: 150ms mdc-ripple-fg-opacity-out;
              animation: 150ms mdc-ripple-fg-opacity-out;
      -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
              transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
    .mdc-top-app-bar__action-item::before, .mdc-top-app-bar__action-item::after, .mdc-top-app-bar__navigation-icon::before, .mdc-top-app-bar__navigation-icon::after {
      top: calc(50% - 50%);
      /* @noflip */
      left: calc(50% - 50%);
      width: 100%;
      height: 100%; }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded::before, .mdc-top-app-bar__action-item.mdc-ripple-upgraded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::before, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::after {
      top: var(--mdc-ripple-top, calc(50% - 50%));
      /* @noflip */
      left: var(--mdc-ripple-left, calc(50% - 50%));
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%); }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::after {
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%); }

.mdc-top-app-bar--short {
  top: 0;
  right: auto;
  left: 0;
  width: 100%;
  -webkit-transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1); }
  [dir="rtl"] .mdc-top-app-bar--short, .mdc-top-app-bar--short[dir="rtl"] {
    right: 0;
    left: auto; }
  .mdc-top-app-bar--short .mdc-top-app-bar__row {
    height: 56px; }
  .mdc-top-app-bar--short .mdc-top-app-bar__section {
    padding: 4px; }
  .mdc-top-app-bar--short .mdc-top-app-bar__title {
    -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1; }

.mdc-top-app-bar--short-collapsed {
  /* @noflip */
  border-bottom-left-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 4px;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 56px;
  -webkit-transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1); }
  [dir="rtl"] .mdc-top-app-bar--short-collapsed, .mdc-top-app-bar--short-collapsed[dir="rtl"] {
    /* @noflip */
    border-bottom-left-radius: 4px;
    /* @noflip */
    border-bottom-right-radius: 0; }
  .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__title {
    display: none; }
  .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__action-item {
    -webkit-transition: padding 150ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: padding 150ms cubic-bezier(0.4, 0, 0.2, 1); }

.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item {
  width: 112px; }
  .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 12px; }
    [dir="rtl"] .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end[dir="rtl"] {
      /* @noflip */
      padding-left: 12px;
      /* @noflip */
      padding-right: 0; }

.mdc-top-app-bar--dense .mdc-top-app-bar__row {
  height: 48px; }

.mdc-top-app-bar--dense .mdc-top-app-bar__section {
  padding: 0 4px; }

.mdc-top-app-bar--dense .mdc-top-app-bar__title {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0; }
  [dir="rtl"] .mdc-top-app-bar--dense .mdc-top-app-bar__title, .mdc-top-app-bar--dense .mdc-top-app-bar__title[dir="rtl"] {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 12px; }

.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 128px; }

.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  -ms-flex-item-align: end;
      align-self: flex-end;
  padding-bottom: 2px; }

.mdc-top-app-bar--prominent .mdc-top-app-bar__action-item,
.mdc-top-app-bar--prominent .mdc-top-app-bar__navigation-icon {
  -ms-flex-item-align: start;
      align-self: flex-start; }

.mdc-top-app-bar--fixed {
  -webkit-transition: -webkit-box-shadow 200ms linear;
  transition: -webkit-box-shadow 200ms linear;
  transition: box-shadow 200ms linear;
  transition: box-shadow 200ms linear, -webkit-box-shadow 200ms linear; }

.mdc-top-app-bar--fixed-scrolled {
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  -webkit-transition: -webkit-box-shadow 200ms linear;
  transition: -webkit-box-shadow 200ms linear;
  transition: box-shadow 200ms linear;
  transition: box-shadow 200ms linear, -webkit-box-shadow 200ms linear; }

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 96px; }

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__section {
  padding: 0 12px; }

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  /* @noflip */
  padding-left: 20px;
  /* @noflip */
  padding-right: 0;
  padding-bottom: 9px; }
  [dir="rtl"] .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title, .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title[dir="rtl"] {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 20px; }

.mdc-top-app-bar--fixed-adjust {
  padding-top: 64px; }

.mdc-top-app-bar--dense-fixed-adjust {
  padding-top: 48px; }

.mdc-top-app-bar--short-fixed-adjust {
  padding-top: 56px; }

.mdc-top-app-bar--prominent-fixed-adjust {
  padding-top: 128px; }

.mdc-top-app-bar--dense-prominent-fixed-adjust {
  padding-top: 96px; }

@media (max-width: 599px) {
  .mdc-top-app-bar__row {
    height: 56px; }
  .mdc-top-app-bar__section {
    padding: 4px; }
  .mdc-top-app-bar--short {
    -webkit-transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1); }
  .mdc-top-app-bar--short-collapsed {
    -webkit-transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1); }
    .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end {
      /* @noflip */
      padding-left: 0;
      /* @noflip */
      padding-right: 12px; }
      [dir="rtl"] .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end[dir="rtl"] {
        /* @noflip */
        padding-left: 12px;
        /* @noflip */
        padding-right: 0; }
  .mdc-top-app-bar--prominent .mdc-top-app-bar__title {
    padding-bottom: 6px; }
  .mdc-top-app-bar--fixed-adjust {
    padding-top: 56px; } }

@-webkit-keyframes mdc-ripple-fg-radius-in {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
            transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1); }
  to {
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
            transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); } }

@keyframes mdc-ripple-fg-radius-in {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
            transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1); }
  to {
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
            transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); } }

@-webkit-keyframes mdc-ripple-fg-opacity-in {
  from {
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    opacity: 0; }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0); } }

@keyframes mdc-ripple-fg-opacity-in {
  from {
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    opacity: 0; }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0); } }

@-webkit-keyframes mdc-ripple-fg-opacity-out {
  from {
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0); }
  to {
    opacity: 0; } }

@keyframes mdc-ripple-fg-opacity-out {
  from {
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0); }
  to {
    opacity: 0; } }

.mdc-ripple-surface--test-edge-var-bug {
  --mdc-ripple-surface-test-edge-var: 1px solid #000;
  visibility: hidden; }
  .mdc-ripple-surface--test-edge-var-bug::before {
    border: var(--mdc-ripple-surface-test-edge-var); }

.mdc-tab {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 600;
  letter-spacing: 0.08929em;
  text-decoration: none;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  display: table-cell;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 160px;
  min-height: 48px;
  padding: 0 24px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  vertical-align: middle; }
  .mdc-tab .mdc-tab__icon {
    color: rgba(255, 255, 255, 0.7);
    /* @alternate */
    color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7)); }
  .mdc-tab::before, .mdc-tab::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-tab::before {
    -webkit-transition: opacity 15ms linear, background-color 15ms linear;
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-tab.mdc-ripple-upgraded::before {
    -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
            transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-tab.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  .mdc-tab.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-tab.mdc-ripple-upgraded--foreground-activation::after {
    -webkit-animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
            animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-tab.mdc-ripple-upgraded--foreground-deactivation::after {
    -webkit-animation: 150ms mdc-ripple-fg-opacity-out;
            animation: 150ms mdc-ripple-fg-opacity-out;
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
            transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-tab::before, .mdc-tab::after {
    background-color: #fff; }
  .mdc-tab:hover::before {
    opacity: 0.08; }
  .mdc-tab:not(.mdc-ripple-upgraded):focus::before, .mdc-tab.mdc-ripple-upgraded--background-focused::before {
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms;
    opacity: 0.24; }
  .mdc-tab:not(.mdc-ripple-upgraded)::after {
    -webkit-transition: opacity 150ms linear;
    transition: opacity 150ms linear; }
  .mdc-tab:not(.mdc-ripple-upgraded):active::after {
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms;
    opacity: 0.32; }
  .mdc-tab.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.32; }
  .mdc-tab::before, .mdc-tab::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-tab.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-tab:hover {
    color: white;
    /* @alternate */
    color: var(--mdc-theme-text-primary-on-background, white); }
    .mdc-tab:hover .mdc-tab__icon {
      color: white;
      /* @alternate */
      color: var(--mdc-theme-text-primary-on-background, white); }
  .mdc-tab:focus {
    outline: none; }
  @media screen and (max-width: 600px) {
    .mdc-tab {
      min-width: 72px;
      padding: 0 12px; } }
  .mdc-tab__icon {
    display: block;
    margin: 0 auto;
    width: 24px;
    height: 24px; }
    .mdc-tab-bar--icons-with-text .mdc-tab__icon {
      margin-top: 4px; }
  .mdc-tab__icon-text {
    display: block;
    margin: 0 auto; }
  .mdc-tab__icon + .mdc-tab__icon-text {
    padding-top: 6px; }

.mdc-tab--active {
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, white); }
  .mdc-tab--active .mdc-tab__icon {
    color: white;
    /* @alternate */
    color: var(--mdc-theme-text-primary-on-background, white); }
  .mdc-tab--active::before {
    bottom: 0; }

.mdc-tab__indicator {
  background-color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  background-color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
  position: absolute;
  bottom: 0;
  height: 2px;
  visibility: hidden;
  left: 2px;
  width: calc(100% - 4px); }

.mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab--active .mdc-tab__indicator,
.mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab:hover .mdc-tab__indicator,
.mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab:active .mdc-tab__indicator {
  visibility: visible; }

.mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab:not(.mdc-tab--active):hover .mdc-tab__indicator {
  opacity: .38; }

.mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab--active,
.mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab:not(.mdc-tab--active):active .mdc-tab__indicator {
  opacity: .87; }

.mdc-tab-bar__indicator,
.mdc-tab__indicator {
  background-color: white;
  /* @alternate */
  background-color: var(--mdc-theme-text-primary-on-background, white); }

.mdc-tab-bar {
  display: table;
  position: relative;
  height: 48px;
  margin: 0 auto;
  text-transform: uppercase; }

.mdc-tab-bar__indicator {
  background-color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  background-color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
  position: absolute;
  bottom: 0;
  height: 2px;
  visibility: hidden;
  left: 0;
  width: 100%;
  -webkit-transform-origin: left top;
          transform-origin: left top;
  -webkit-transition: -webkit-transform 240ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: -webkit-transform 240ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: transform 240ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: transform 240ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 240ms 0ms cubic-bezier(0, 0, 0.2, 1);
  will-change: transform; }

.mdc-tab-bar--icons-with-text {
  height: 72px; }

.mdc-tab-bar-scroller {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  background-color: inherit;
  overflow: hidden; }
  .mdc-tab-bar-scroller__scroll-frame {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    overflow: hidden; }
    .mdc-tab-bar-scroller__scroll-frame__tabs {
      -webkit-transition: -webkit-transform 240ms 0ms cubic-bezier(0, 0, 0.2, 1);
      transition: -webkit-transform 240ms 0ms cubic-bezier(0, 0, 0.2, 1);
      transition: transform 240ms 0ms cubic-bezier(0, 0, 0.2, 1);
      transition: transform 240ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 240ms 0ms cubic-bezier(0, 0, 0.2, 1);
      will-change: transform; }
  .mdc-tab-bar-scroller__indicator {
    color: rgba(255, 255, 255, 0.7);
    /* @alternate */
    color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 48px;
    cursor: pointer;
    visibility: hidden; }
    .mdc-tab-bar-scroller__indicator:hover {
      color: white;
      /* @alternate */
      color: var(--mdc-theme-text-primary-on-background, white); }
    .mdc-tab-bar-scroller__indicator__inner {
      color: inherit;
      text-decoration: inherit;
      cursor: inherit; }
      .mdc-tab-bar-scroller__indicator__inner:focus {
        outline-color: inherit; }
      .mdc-tab-bar-scroller[dir="rtl"] .mdc-tab-bar-scroller__indicator__inner,
      [dir="rtl"] .mdc-tab-bar-scroller .mdc-tab-bar-scroller__indicator__inner {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
    .mdc-tab-bar-scroller__indicator__inner:hover {
      color: inherit; }
  .mdc-tab-bar-scroller__indicator--enabled {
    visibility: visible; }

.mdc-drawer {
  border-color: rgba(255, 255, 255, 0.12);
  background-color: #FF4438;
  border-radius: 0 0 0 0;
  z-index: 6;
  width: 256px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  border-right-width: 1px;
  border-right-style: solid;
  overflow: hidden; }
  .mdc-drawer .mdc-drawer__title {
    color: rgba(255, 255, 255, 0.87); }
  .mdc-drawer .mdc-list-group__subheader {
    color: rgba(255, 255, 255, 0.6); }
  .mdc-drawer .mdc-drawer__subtitle {
    color: rgba(255, 255, 255, 0.6); }
  .mdc-drawer .mdc-list-item__graphic {
    color: rgba(255, 255, 255, 0.6); }
  .mdc-drawer .mdc-list-item {
    color: rgba(255, 255, 255, 0.87); }
  .mdc-drawer .mdc-list-item--activated .mdc-list-item__graphic {
    color: #fad000; }
  .mdc-drawer .mdc-list-item--activated {
    color: rgba(250, 208, 0, 0.87); }
  [dir="rtl"] .mdc-drawer, .mdc-drawer[dir="rtl"] {
    border-radius: large 0 0 large; }
  .mdc-drawer .mdc-list-item {
    border-radius: 4px; }
    [dir="rtl"] .mdc-drawer .mdc-list-item, .mdc-drawer .mdc-list-item[dir="rtl"] {
      border-radius: 4px; }
  .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content {
    /* @noflip */
    margin-left: 256px;
    /* @noflip */
    margin-right: 0; }
    [dir="rtl"] .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content, .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content[dir="rtl"] {
      /* @noflip */
      margin-left: 0;
      /* @noflip */
      margin-right: 256px; }
  [dir="rtl"] .mdc-drawer, .mdc-drawer[dir="rtl"] {
    border-right-width: 0;
    border-left-width: 1px;
    border-right-style: none;
    border-left-style: solid; }
  .mdc-drawer .mdc-list-item {
    font-family: sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: 600;
    letter-spacing: 0.00714em;
    text-decoration: inherit;
    text-transform: inherit;
    height: calc(48px - 2 * 4px);
    margin: 8px 8px;
    padding: 0 8px; }
  .mdc-drawer .mdc-list-item:nth-child(1) {
    margin-top: 2px; }
  .mdc-drawer .mdc-list-item:nth-last-child(1) {
    margin-bottom: 0; }
  .mdc-drawer .mdc-list-group__subheader {
    font-family: sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.01786em;
    text-decoration: inherit;
    text-transform: inherit;
    display: block;
    margin-top: 0;
    line-height: normal;
    margin: 0;
    padding: 0 16px; }
    .mdc-drawer .mdc-list-group__subheader::before {
      display: inline-block;
      width: 0;
      height: 24px;
      content: "";
      vertical-align: 0; }
  .mdc-drawer .mdc-list-divider {
    margin: 3px 0 4px 0; }
  .mdc-drawer .mdc-list-item__text,
  .mdc-drawer .mdc-list-item__graphic {
    pointer-events: none; }

.mdc-drawer--open {
  -webkit-transition: -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.2, 1); }

.mdc-drawer--animate {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }
  [dir="rtl"] .mdc-drawer--animate, .mdc-drawer--animate[dir="rtl"] {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }

.mdc-drawer--opening {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition-duration: 250ms;
          transition-duration: 250ms; }
  [dir="rtl"] .mdc-drawer--opening, .mdc-drawer--opening[dir="rtl"] {
    -webkit-transform: translateX(0);
            transform: translateX(0); }

.mdc-drawer--closing {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition-duration: 200ms;
          transition-duration: 200ms; }
  [dir="rtl"] .mdc-drawer--closing, .mdc-drawer--closing[dir="rtl"] {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }

.mdc-drawer__header {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-height: 64px;
  padding: 0 16px 4px; }

.mdc-drawer__title {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 600;
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px; }
  .mdc-drawer__title::before {
    display: inline-block;
    width: 0;
    height: 36px;
    content: "";
    vertical-align: 0; }
  .mdc-drawer__title::after {
    display: inline-block;
    width: 0;
    height: 20px;
    content: "";
    vertical-align: -20px; }

.mdc-drawer__subtitle {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: 0; }
  .mdc-drawer__subtitle::before {
    display: inline-block;
    width: 0;
    height: 20px;
    content: "";
    vertical-align: 0; }

.mdc-drawer__content {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.mdc-drawer--dismissible {
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  display: none;
  position: absolute;
  top: 0; }
  [dir="rtl"] .mdc-drawer--dismissible, .mdc-drawer--dismissible[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 0; }
  .mdc-drawer--dismissible.mdc-drawer--open {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }

.mdc-drawer-app-content {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
  position: relative; }
  [dir="rtl"] .mdc-drawer-app-content, .mdc-drawer-app-content[dir="rtl"] {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 0; }
  .mdc-drawer--closing + .mdc-drawer-app-content {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 0; }
    [dir="rtl"] .mdc-drawer--closing + .mdc-drawer-app-content, .mdc-drawer--closing + .mdc-drawer-app-content[dir="rtl"] {
      /* @noflip */
      margin-left: 0;
      /* @noflip */
      margin-right: 0; }

.mdc-drawer--modal {
  -webkit-box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  display: none;
  position: fixed; }
  .mdc-drawer--modal + .mdc-drawer-scrim {
    background-color: rgba(255, 255, 255, 0.32); }
  [dir="rtl"] .mdc-drawer--modal, .mdc-drawer--modal[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 0; }
  .mdc-drawer--modal.mdc-drawer--open {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }

.mdc-drawer-scrim {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 5; }
  .mdc-drawer--open + .mdc-drawer-scrim {
    display: block; }
  .mdc-drawer--animate + .mdc-drawer-scrim {
    opacity: 0; }
  .mdc-drawer--opening + .mdc-drawer-scrim {
    -webkit-transition-duration: 250ms;
            transition-duration: 250ms;
    opacity: 1; }
  .mdc-drawer--closing + .mdc-drawer-scrim {
    -webkit-transition-duration: 200ms;
            transition-duration: 200ms;
    opacity: 0; }

.mdc-list {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, white);
  margin: 0;
  padding: 8px 0;
  /* @alternate */
  line-height: 1.5rem;
  list-style-type: none; }

.mdc-list-item__secondary-text {
  color: rgba(255, 255, 255, 0.7);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7)); }

.mdc-list-item__graphic {
  background-color: transparent; }

.mdc-list-item__graphic {
  color: rgba(255, 255, 255, 0.5);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5)); }

.mdc-list-item__meta {
  color: rgba(255, 255, 255, 0.5);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5)); }

.mdc-list--dense {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: .812rem; }

.mdc-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  height: 48px;
  padding: 0 16px;
  overflow: hidden; }
  .mdc-list-item:focus {
    outline: none; }

.mdc-list-item--selected,
.mdc-list-item--activated {
  color: #FAD000;
  /* @alternate */
  color: var(--mdc-theme-primary, #FAD000); }
  .mdc-list-item--selected .mdc-list-item__graphic,
  .mdc-list-item--activated .mdc-list-item__graphic {
    color: #FAD000;
    /* @alternate */
    color: var(--mdc-theme-primary, #FAD000); }

.mdc-list-item--disabled {
  color: rgba(255, 255, 255, 0.5);
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-background, rgba(255, 255, 255, 0.5)); }

.mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 32px;
  width: 24px;
  height: 24px;
  font-size: 24px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  fill: currentColor; }
  .mdc-list-item[dir="rtl"] .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 32px;
    /* @noflip */
    margin-right: 0; }

.mdc-list-item__meta {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0; }
  .mdc-list-item[dir="rtl"] .mdc-list-item__meta,
  [dir="rtl"] .mdc-list-item .mdc-list-item__meta {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: auto; }

.mdc-list-item__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.mdc-list-item__primary-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  line-height: normal;
  margin-bottom: -20px;
  display: block; }
  .mdc-list-item__primary-text::before {
    display: inline-block;
    width: 0;
    height: 32px;
    content: "";
    vertical-align: 0; }
  .mdc-list-item__primary-text::after {
    display: inline-block;
    width: 0;
    height: 20px;
    content: "";
    vertical-align: -20px; }
  .mdc-list--dense .mdc-list-item__primary-text {
    display: block;
    margin-top: 0;
    line-height: normal;
    margin-bottom: -20px; }
    .mdc-list--dense .mdc-list-item__primary-text::before {
      display: inline-block;
      width: 0;
      height: 24px;
      content: "";
      vertical-align: 0; }
    .mdc-list--dense .mdc-list-item__primary-text::after {
      display: inline-block;
      width: 0;
      height: 20px;
      content: "";
      vertical-align: -20px; }

.mdc-list-item__secondary-text {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  line-height: normal;
  display: block; }
  .mdc-list-item__secondary-text::before {
    display: inline-block;
    width: 0;
    height: 20px;
    content: "";
    vertical-align: 0; }
  .mdc-list--dense .mdc-list-item__secondary-text {
    display: block;
    margin-top: 0;
    line-height: normal;
    font-size: inherit; }
    .mdc-list--dense .mdc-list-item__secondary-text::before {
      display: inline-block;
      width: 0;
      height: 20px;
      content: "";
      vertical-align: 0; }

.mdc-list--dense .mdc-list-item {
  height: 40px; }

.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 36px;
  width: 20px;
  height: 20px;
  font-size: 20px; }
  .mdc-list-item[dir="rtl"] .mdc-list--dense .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list--dense .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 36px;
    /* @noflip */
    margin-right: 0; }

.mdc-list--avatar-list .mdc-list-item {
  height: 56px; }

.mdc-list--avatar-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 40px;
  height: 40px;
  font-size: 40px;
  border-radius: 50%; }
  .mdc-list-item[dir="rtl"] .mdc-list--avatar-list .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list--avatar-list .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 16px;
    /* @noflip */
    margin-right: 0; }

.mdc-list--two-line .mdc-list-item__text {
  -ms-flex-item-align: start;
      align-self: flex-start; }

.mdc-list--two-line .mdc-list-item {
  height: 72px; }

.mdc-list--two-line.mdc-list--dense .mdc-list-item {
  height: 60px; }

.mdc-list--avatar-list.mdc-list--dense .mdc-list-item {
  height: 60px; }

.mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 20px;
  width: 36px;
  height: 36px;
  font-size: 36px; }
  .mdc-list-item[dir="rtl"] .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 20px;
    /* @noflip */
    margin-right: 0; }

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before {
    -webkit-transition: opacity 15ms linear, background-color 15ms linear;
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::before {
    -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
            transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-activation::after {
    -webkit-animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
            animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-deactivation::after {
    -webkit-animation: 150ms mdc-ripple-fg-opacity-out;
            animation: 150ms mdc-ripple-fg-opacity-out;
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
            transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
    background-color: #fff; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:hover::before {
    opacity: 0.08; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--background-focused::before {
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms;
    opacity: 0.24; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded)::after {
    -webkit-transition: opacity 150ms linear;
    transition: opacity 150ms linear; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):active::after {
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms;
    opacity: 0.32; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.32; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before {
    opacity: 0.24; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::after {
    background-color: #FAD000; }
    @supports not (-ms-ime-align: auto) {
      :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::after {
        /* @alternate */
        background-color: var(--mdc-theme-primary, #FAD000); } }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:hover::before {
    opacity: 0.32; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded--background-focused::before {
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms;
    opacity: 0.48; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded)::after {
    -webkit-transition: opacity 150ms linear;
    transition: opacity 150ms linear; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):active::after {
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms;
    opacity: 0.56; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.56; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before {
    opacity: 0.16; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::after {
    background-color: #FAD000; }
    @supports not (-ms-ime-align: auto) {
      :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::after {
        /* @alternate */
        background-color: var(--mdc-theme-primary, #FAD000); } }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:hover::before {
    opacity: 0.24; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded--background-focused::before {
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms;
    opacity: 0.4; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded)::after {
    -webkit-transition: opacity 150ms linear;
    transition: opacity 150ms linear; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):active::after {
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms;
    opacity: 0.48; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.48; }

a.mdc-list-item {
  color: inherit;
  text-decoration: none; }

.mdc-list-divider {
  height: 0;
  margin: 0;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid; }

.mdc-list-divider {
  border-bottom-color: rgba(255, 255, 255, 0.2); }

.mdc-list-divider--padded {
  margin: 0 16px; }

.mdc-list-divider--inset {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px); }
  .mdc-list-group[dir="rtl"] .mdc-list-divider--inset,
  [dir="rtl"] .mdc-list-group .mdc-list-divider--inset {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 72px; }

.mdc-list-divider--inset.mdc-list-divider--padded {
  width: calc(100% - 72px - 16px); }

.mdc-list-group .mdc-list {
  padding: 0; }

.mdc-list-group__subheader {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  margin: 0.75rem 16px; }

.mdc-list-group__subheader {
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, white); }

.mdc-button {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 600;
  letter-spacing: 0.08929em;
  text-decoration: none;
  text-transform: uppercase;
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  padding: 0 8px 0 8px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 64px;
  height: 36px;
  border: none;
  outline: none;
  /* @alternate */
  line-height: inherit;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-appearance: none;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 2px; }
  .mdc-button::before, .mdc-button::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-button::before {
    -webkit-transition: opacity 15ms linear, background-color 15ms linear;
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-button.mdc-ripple-upgraded::before {
    -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
            transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-button.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  .mdc-button.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-button.mdc-ripple-upgraded--foreground-activation::after {
    -webkit-animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
            animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-button.mdc-ripple-upgraded--foreground-deactivation::after {
    -webkit-animation: 150ms mdc-ripple-fg-opacity-out;
            animation: 150ms mdc-ripple-fg-opacity-out;
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
            transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-button::before, .mdc-button::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-button.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-button::-moz-focus-inner {
    padding: 0;
    border: 0; }
  .mdc-button:active {
    outline: none; }
  .mdc-button:hover {
    cursor: pointer; }
  .mdc-button:disabled {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.37);
    cursor: default;
    pointer-events: none; }
  .mdc-button:not(:disabled) {
    background-color: transparent; }
  .mdc-button:not(:disabled) {
    color: #FAD000;
    /* @alternate */
    color: var(--mdc-theme-primary, #FAD000); }
  .mdc-button::before, .mdc-button::after {
    background-color: #FAD000; }
    @supports not (-ms-ime-align: auto) {
      .mdc-button::before, .mdc-button::after {
        /* @alternate */
        background-color: var(--mdc-theme-primary, #FAD000); } }
  .mdc-button:hover::before {
    opacity: 0.08; }
  .mdc-button:not(.mdc-ripple-upgraded):focus::before, .mdc-button.mdc-ripple-upgraded--background-focused::before {
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms;
    opacity: 0.24; }
  .mdc-button:not(.mdc-ripple-upgraded)::after {
    -webkit-transition: opacity 150ms linear;
    transition: opacity 150ms linear; }
  .mdc-button:not(.mdc-ripple-upgraded):active::after {
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms;
    opacity: 0.32; }
  .mdc-button.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.32; }
  .mdc-button .mdc-button__icon {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 8px;
    display: inline-block;
    width: 18px;
    height: 18px;
    font-size: 18px;
    vertical-align: top; }
    [dir="rtl"] .mdc-button .mdc-button__icon, .mdc-button .mdc-button__icon[dir="rtl"] {
      /* @noflip */
      margin-left: 8px;
      /* @noflip */
      margin-right: 0; }
  .mdc-button svg.mdc-button__icon {
    fill: currentColor; }

.mdc-button--raised .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 8px; }
  [dir="rtl"] .mdc-button--raised .mdc-button__icon, .mdc-button--raised .mdc-button__icon[dir="rtl"], [dir="rtl"]
  .mdc-button--unelevated .mdc-button__icon,
  .mdc-button--unelevated .mdc-button__icon[dir="rtl"], [dir="rtl"]
  .mdc-button--outlined .mdc-button__icon,
  .mdc-button--outlined .mdc-button__icon[dir="rtl"] {
    /* @noflip */
    margin-left: 8px;
    /* @noflip */
    margin-right: -4px; }

.mdc-button--raised,
.mdc-button--unelevated {
  padding: 0 16px 0 16px; }
  .mdc-button--raised:disabled,
  .mdc-button--unelevated:disabled {
    background-color: rgba(255, 255, 255, 0.12);
    color: rgba(255, 255, 255, 0.37); }
  .mdc-button--raised:not(:disabled),
  .mdc-button--unelevated:not(:disabled) {
    background-color: #FAD000; }
    @supports not (-ms-ime-align: auto) {
      .mdc-button--raised:not(:disabled),
      .mdc-button--unelevated:not(:disabled) {
        /* @alternate */
        background-color: var(--mdc-theme-primary, #FAD000); } }
  .mdc-button--raised:not(:disabled),
  .mdc-button--unelevated:not(:disabled) {
    color: #000;
    /* @alternate */
    color: var(--mdc-theme-on-primary, #000); }
  .mdc-button--raised::before, .mdc-button--raised::after,
  .mdc-button--unelevated::before,
  .mdc-button--unelevated::after {
    background-color: #000; }
    @supports not (-ms-ime-align: auto) {
      .mdc-button--raised::before, .mdc-button--raised::after,
      .mdc-button--unelevated::before,
      .mdc-button--unelevated::after {
        /* @alternate */
        background-color: var(--mdc-theme-on-primary, #000); } }
  .mdc-button--raised:hover::before,
  .mdc-button--unelevated:hover::before {
    opacity: 0.04; }
  .mdc-button--raised:not(.mdc-ripple-upgraded):focus::before, .mdc-button--raised.mdc-ripple-upgraded--background-focused::before,
  .mdc-button--unelevated:not(.mdc-ripple-upgraded):focus::before,
  .mdc-button--unelevated.mdc-ripple-upgraded--background-focused::before {
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-button--raised:not(.mdc-ripple-upgraded)::after,
  .mdc-button--unelevated:not(.mdc-ripple-upgraded)::after {
    -webkit-transition: opacity 150ms linear;
    transition: opacity 150ms linear; }
  .mdc-button--raised:not(.mdc-ripple-upgraded):active::after,
  .mdc-button--unelevated:not(.mdc-ripple-upgraded):active::after {
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-button--raised.mdc-ripple-upgraded,
  .mdc-button--unelevated.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }

.mdc-button--raised {
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  -webkit-transition: -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1); }
  .mdc-button--raised:hover, .mdc-button--raised:focus {
    -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
            box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .mdc-button--raised:active {
    -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
            box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }
  .mdc-button--raised:disabled {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
            box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mdc-button--outlined {
  border-style: solid;
  padding: 0 14px 0 14px;
  border-width: 2px;
  line-height: 32px; }
  .mdc-button--outlined:disabled {
    border-color: rgba(255, 255, 255, 0.37); }
  .mdc-button--outlined.mdc-button--dense {
    line-height: 27px; }
  .mdc-button--outlined:not(:disabled) {
    border-color: #FAD000;
    /* @alternate */
    border-color: var(--mdc-theme-primary, #FAD000); }

.mdc-button--dense {
  height: 32px;
  font-size: .8125rem;
  line-height: 32px; }

.mdc-image-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 auto;
  padding: 0; }

.mdc-image-list__item,
.mdc-image-list__image-aspect-container {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.mdc-image-list__item {
  list-style-type: none; }

.mdc-image-list__image {
  width: 100%; }

.mdc-image-list__image-aspect-container .mdc-image-list__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

.mdc-image-list__image-aspect-container {
  padding-bottom: calc(100% / 1); }

.mdc-image-list__supporting {
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 8px 0;
  line-height: 24px; }

.mdc-image-list__label {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.mdc-image-list--with-text-protection .mdc-image-list__supporting {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 48px;
  padding: 0 16px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff; }

.mdc-image-list--masonry {
  display: block; }
  .mdc-image-list--masonry .mdc-image-list__item {
    -webkit-column-break-inside: avoid;
       page-break-inside: avoid;
            break-inside: avoid-column; }
  .mdc-image-list--masonry .mdc-image-list__image {
    display: block;
    height: auto; }

:root {
  --mdc-layout-grid-margin-desktop: 24px;
  --mdc-layout-grid-gutter-desktop: 24px;
  --mdc-layout-grid-column-width-desktop: 72px;
  --mdc-layout-grid-margin-tablet: 16px;
  --mdc-layout-grid-gutter-tablet: 16px;
  --mdc-layout-grid-column-width-tablet: 72px;
  --mdc-layout-grid-margin-phone: 16px;
  --mdc-layout-grid-gutter-phone: 16px;
  --mdc-layout-grid-column-width-phone: 72px; }

@media (min-width: 840px) {
  .mdc-layout-grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0 auto;
    padding: 24px;
    padding: var(--mdc-layout-grid-margin-desktop, 24px); } }

@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-tablet, 16px); } }

@media (max-width: 479px) {
  .mdc-layout-grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-phone, 16px); } }

@media (min-width: 840px) {
  .mdc-layout-grid__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    margin: -12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2 * -1); }
    @supports (display: grid) {
      .mdc-layout-grid__inner {
        display: grid;
        margin: 0;
        grid-gap: 24px;
        grid-gap: var(--mdc-layout-grid-gutter-desktop, 24px);
        grid-template-columns: repeat(12, minmax(0, 1fr)); } } }

@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2 * -1); }
    @supports (display: grid) {
      .mdc-layout-grid__inner {
        display: grid;
        margin: 0;
        grid-gap: 16px;
        grid-gap: var(--mdc-layout-grid-gutter-tablet, 16px);
        grid-template-columns: repeat(8, minmax(0, 1fr)); } } }

@media (max-width: 479px) {
  .mdc-layout-grid__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2 * -1); }
    @supports (display: grid) {
      .mdc-layout-grid__inner {
        display: grid;
        margin: 0;
        grid-gap: 16px;
        grid-gap: var(--mdc-layout-grid-gutter-phone, 16px);
        grid-template-columns: repeat(4, minmax(0, 1fr)); } } }

@media (min-width: 840px) {
  .mdc-layout-grid__cell {
    width: calc(33.33333% - 24px);
    width: calc(33.33333% - var(--mdc-layout-grid-gutter-desktop, 24px));
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2); }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        width: auto;
        grid-column-end: span 4; } }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        margin: 0; } }
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-desktop {
      width: calc(8.33333% - 24px);
      width: calc(8.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-1,
        .mdc-layout-grid__cell--span-1-desktop {
          width: auto;
          grid-column-end: span 1; } }
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-desktop {
      width: calc(16.66667% - 24px);
      width: calc(16.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-2,
        .mdc-layout-grid__cell--span-2-desktop {
          width: auto;
          grid-column-end: span 2; } }
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-desktop {
      width: calc(25% - 24px);
      width: calc(25% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-3,
        .mdc-layout-grid__cell--span-3-desktop {
          width: auto;
          grid-column-end: span 3; } }
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-desktop {
      width: calc(33.33333% - 24px);
      width: calc(33.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-4,
        .mdc-layout-grid__cell--span-4-desktop {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-desktop {
      width: calc(41.66667% - 24px);
      width: calc(41.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-5,
        .mdc-layout-grid__cell--span-5-desktop {
          width: auto;
          grid-column-end: span 5; } }
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-desktop {
      width: calc(50% - 24px);
      width: calc(50% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-6,
        .mdc-layout-grid__cell--span-6-desktop {
          width: auto;
          grid-column-end: span 6; } }
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-desktop {
      width: calc(58.33333% - 24px);
      width: calc(58.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-7,
        .mdc-layout-grid__cell--span-7-desktop {
          width: auto;
          grid-column-end: span 7; } }
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-desktop {
      width: calc(66.66667% - 24px);
      width: calc(66.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-8,
        .mdc-layout-grid__cell--span-8-desktop {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-desktop {
      width: calc(75% - 24px);
      width: calc(75% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-9,
        .mdc-layout-grid__cell--span-9-desktop {
          width: auto;
          grid-column-end: span 9; } }
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-desktop {
      width: calc(83.33333% - 24px);
      width: calc(83.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-10,
        .mdc-layout-grid__cell--span-10-desktop {
          width: auto;
          grid-column-end: span 10; } }
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-desktop {
      width: calc(91.66667% - 24px);
      width: calc(91.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-11,
        .mdc-layout-grid__cell--span-11-desktop {
          width: auto;
          grid-column-end: span 11; } }
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-desktop {
      width: calc(100% - 24px);
      width: calc(100% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-12,
        .mdc-layout-grid__cell--span-12-desktop {
          width: auto;
          grid-column-end: span 12; } } }

@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid__cell {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2); }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        width: auto;
        grid-column-end: span 4; } }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        margin: 0; } }
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-tablet {
      width: calc(12.5% - 16px);
      width: calc(12.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-1,
        .mdc-layout-grid__cell--span-1-tablet {
          width: auto;
          grid-column-end: span 1; } }
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-tablet {
      width: calc(25% - 16px);
      width: calc(25% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-2,
        .mdc-layout-grid__cell--span-2-tablet {
          width: auto;
          grid-column-end: span 2; } }
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-tablet {
      width: calc(37.5% - 16px);
      width: calc(37.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-3,
        .mdc-layout-grid__cell--span-3-tablet {
          width: auto;
          grid-column-end: span 3; } }
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-tablet {
      width: calc(50% - 16px);
      width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-4,
        .mdc-layout-grid__cell--span-4-tablet {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-tablet {
      width: calc(62.5% - 16px);
      width: calc(62.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-5,
        .mdc-layout-grid__cell--span-5-tablet {
          width: auto;
          grid-column-end: span 5; } }
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-tablet {
      width: calc(75% - 16px);
      width: calc(75% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-6,
        .mdc-layout-grid__cell--span-6-tablet {
          width: auto;
          grid-column-end: span 6; } }
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-tablet {
      width: calc(87.5% - 16px);
      width: calc(87.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-7,
        .mdc-layout-grid__cell--span-7-tablet {
          width: auto;
          grid-column-end: span 7; } }
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-tablet {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-8,
        .mdc-layout-grid__cell--span-8-tablet {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-tablet {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-9,
        .mdc-layout-grid__cell--span-9-tablet {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-tablet {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-10,
        .mdc-layout-grid__cell--span-10-tablet {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-tablet {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-11,
        .mdc-layout-grid__cell--span-11-tablet {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-tablet {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-12,
        .mdc-layout-grid__cell--span-12-tablet {
          width: auto;
          grid-column-end: span 8; } } }

@media (max-width: 479px) {
  .mdc-layout-grid__cell {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2); }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        width: auto;
        grid-column-end: span 4; } }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        margin: 0; } }
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-phone {
      width: calc(25% - 16px);
      width: calc(25% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-1,
        .mdc-layout-grid__cell--span-1-phone {
          width: auto;
          grid-column-end: span 1; } }
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-phone {
      width: calc(50% - 16px);
      width: calc(50% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-2,
        .mdc-layout-grid__cell--span-2-phone {
          width: auto;
          grid-column-end: span 2; } }
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-phone {
      width: calc(75% - 16px);
      width: calc(75% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-3,
        .mdc-layout-grid__cell--span-3-phone {
          width: auto;
          grid-column-end: span 3; } }
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-4,
        .mdc-layout-grid__cell--span-4-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-5,
        .mdc-layout-grid__cell--span-5-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-6,
        .mdc-layout-grid__cell--span-6-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-7,
        .mdc-layout-grid__cell--span-7-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-8,
        .mdc-layout-grid__cell--span-8-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-9,
        .mdc-layout-grid__cell--span-9-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-10,
        .mdc-layout-grid__cell--span-10-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-11,
        .mdc-layout-grid__cell--span-11-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-12,
        .mdc-layout-grid__cell--span-12-phone {
          width: auto;
          grid-column-end: span 4; } } }

.mdc-layout-grid__cell--order-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1; }

.mdc-layout-grid__cell--order-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2; }

.mdc-layout-grid__cell--order-3 {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3; }

.mdc-layout-grid__cell--order-4 {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4; }

.mdc-layout-grid__cell--order-5 {
  -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5; }

.mdc-layout-grid__cell--order-6 {
  -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
          order: 6; }

.mdc-layout-grid__cell--order-7 {
  -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
          order: 7; }

.mdc-layout-grid__cell--order-8 {
  -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
          order: 8; }

.mdc-layout-grid__cell--order-9 {
  -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
          order: 9; }

.mdc-layout-grid__cell--order-10 {
  -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
          order: 10; }

.mdc-layout-grid__cell--order-11 {
  -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
          order: 11; }

.mdc-layout-grid__cell--order-12 {
  -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
          order: 12; }

.mdc-layout-grid__cell--align-top {
  -ms-flex-item-align: start;
      align-self: flex-start; }
  @supports (display: grid) {
    .mdc-layout-grid__cell--align-top {
      -ms-flex-item-align: start;
          align-self: start; } }

.mdc-layout-grid__cell--align-middle {
  -ms-flex-item-align: center;
      align-self: center; }

.mdc-layout-grid__cell--align-bottom {
  -ms-flex-item-align: end;
      align-self: flex-end; }
  @supports (display: grid) {
    .mdc-layout-grid__cell--align-bottom {
      -ms-flex-item-align: end;
          align-self: end; } }

@media (min-width: 840px) {
  .mdc-layout-grid--fixed-column-width {
    width: 1176px;
    width: calc( var(--mdc-layout-grid-column-width-desktop, 72px) * 12 + var(--mdc-layout-grid-gutter-desktop, 24px) * 11 + var(--mdc-layout-grid-margin-desktop, 24px) * 2); } }

@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid--fixed-column-width {
    width: 720px;
    width: calc( var(--mdc-layout-grid-column-width-tablet, 72px) * 8 + var(--mdc-layout-grid-gutter-tablet, 16px) * 7 + var(--mdc-layout-grid-margin-tablet, 16px) * 2); } }

@media (max-width: 479px) {
  .mdc-layout-grid--fixed-column-width {
    width: 368px;
    width: calc( var(--mdc-layout-grid-column-width-phone, 72px) * 4 + var(--mdc-layout-grid-gutter-phone, 16px) * 3 + var(--mdc-layout-grid-margin-phone, 16px) * 2); } }

.mdc-layout-grid--align-left {
  margin-right: auto;
  margin-left: 0; }

.mdc-layout-grid--align-right {
  margin-right: 0;
  margin-left: auto; }

@-webkit-keyframes mdc-select-float-native-control {
  0% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes mdc-select-float-native-control {
  0% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

.mdc-line-ripple {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 180ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 180ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: 2; }

.mdc-line-ripple--active {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  opacity: 1; }

.mdc-line-ripple--deactivating {
  opacity: 0; }

.mdc-floating-label {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  position: absolute;
  bottom: 8px;
  left: 0;
  -webkit-transform-origin: left top;
          transform-origin: left top;
  -webkit-transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  line-height: 1.15rem;
  cursor: text; }
  [dir="rtl"] .mdc-floating-label, .mdc-floating-label[dir="rtl"] {
    /* @noflip */
    right: 0;
    /* @noflip */
    left: auto;
    /* @noflip */
    -webkit-transform-origin: right top;
            transform-origin: right top; }

.mdc-floating-label--float-above {
  cursor: auto; }

.mdc-floating-label--float-above {
  -webkit-transform: translateY(-100%) scale(0.75);
          transform: translateY(-100%) scale(0.75); }

.mdc-floating-label--shake {
  -webkit-animation: mdc-floating-label-shake-float-above-standard 250ms 1;
          animation: mdc-floating-label-shake-float-above-standard 250ms 1; }

@-webkit-keyframes mdc-floating-label-shake-float-above-standard {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-100%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-100%) scale(0.75); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-100%) scale(0.75);
            transform: translateX(calc(4% - 0%)) translateY(-100%) scale(0.75); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-100%) scale(0.75);
            transform: translateX(calc(-4% - 0%)) translateY(-100%) scale(0.75); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-100%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-100%) scale(0.75); } }

@keyframes mdc-floating-label-shake-float-above-standard {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-100%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-100%) scale(0.75); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-100%) scale(0.75);
            transform: translateX(calc(4% - 0%)) translateY(-100%) scale(0.75); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-100%) scale(0.75);
            transform: translateX(calc(-4% - 0%)) translateY(-100%) scale(0.75); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-100%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-100%) scale(0.75); } }

.mdc-select {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%230%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E");
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 52px;
  background-repeat: no-repeat;
  background-position: right 8px bottom 12px;
  overflow: hidden; }
  .mdc-select:not(.mdc-select--disabled) {
    background-color: transparent; }
  .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control {
    color: rgba(255, 255, 255, 0.87); }
  .mdc-select:not(.mdc-select--disabled) .mdc-floating-label {
    color: rgba(255, 255, 255, 0.6); }
  .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control {
    border-bottom-color: rgba(255, 255, 255, 0.42); }
  .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:focus ~ .mdc-line-ripple {
    background-color: #FAD000;
    /* @alternate */
    background-color: var(--mdc-theme-primary, #FAD000); }
  .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:focus ~ .mdc-floating-label {
    color: rgba(250, 208, 0, 0.87); }
  .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:hover {
    border-bottom-color: rgba(255, 255, 255, 0.87); }
  [dir="rtl"] .mdc-select, .mdc-select[dir="rtl"] {
    background-position: left 8px bottom 12px; }
  .mdc-select__native-control {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 26px;
    font-family: sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: 0.00937em;
    text-decoration: inherit;
    text-transform: inherit;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 4px;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
    outline: none;
    background-color: transparent;
    white-space: nowrap;
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none; }
    [dir="rtl"] .mdc-select__native-control, .mdc-select__native-control[dir="rtl"] {
      /* @noflip */
      padding-left: 26px;
      /* @noflip */
      padding-right: 0; }
    .mdc-select__native-control::-ms-expand {
      display: none; }

@-moz-document url-prefix("") {
  .mdc-select__native-control {
    text-indent: -2px; } }
  .mdc-select .mdc-floating-label {
    pointer-events: none; }
  .mdc-select .mdc-select__native-control:focus ~ .mdc-line-ripple::after {
    -webkit-transform: scale(1, 2);
            transform: scale(1, 2);
    opacity: 1; }

.mdc-select--box {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  height: 56px;
  border-radius: 4px 4px 0 0;
  background-position: right 10px center; }
  .mdc-select--box:not(.mdc-select--disabled) {
    background-color: #ff4b40; }
  .mdc-select--box::before, .mdc-select--box::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-select--box::before {
    -webkit-transition: opacity 15ms linear, background-color 15ms linear;
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-select--box.mdc-ripple-upgraded::before {
    -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
            transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-select--box.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  .mdc-select--box.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-select--box.mdc-ripple-upgraded--foreground-activation::after {
    -webkit-animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
            animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-select--box.mdc-ripple-upgraded--foreground-deactivation::after {
    -webkit-animation: 150ms mdc-ripple-fg-opacity-out;
            animation: 150ms mdc-ripple-fg-opacity-out;
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
            transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-select--box::before, .mdc-select--box::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-select--box.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-select--box::before, .mdc-select--box::after {
    background-color: rgba(255, 255, 255, 0.87); }
  .mdc-select--box:hover::before {
    opacity: 0.08; }
  .mdc-select--box:not(.mdc-ripple-upgraded):focus::before, .mdc-select--box:not(.mdc-ripple-upgraded):focus-within::before, .mdc-select--box.mdc-ripple-upgraded--background-focused::before {
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms;
    opacity: 0.24; }
  [dir="rtl"] .mdc-select--box, .mdc-select--box[dir="rtl"] {
    background-position: left 10px center; }
  .mdc-select--box .mdc-select__native-control {
    /* @noflip */
    padding-left: 16px;
    /* @noflip */
    padding-right: 26px;
    height: 56px;
    border-radius: 4px 4px 0 0; }
    [dir="rtl"] .mdc-select--box .mdc-select__native-control, .mdc-select--box .mdc-select__native-control[dir="rtl"] {
      /* @noflip */
      padding-left: 26px;
      /* @noflip */
      padding-right: 16px; }
  .mdc-select--box .mdc-floating-label {
    /* @noflip */
    left: 16px;
    /* @noflip */
    right: initial;
    bottom: 12px;
    line-height: 1.75rem; }
    [dir="rtl"] .mdc-select--box .mdc-floating-label, .mdc-select--box .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 16px; }
    .mdc-select--box .mdc-floating-label--float-above {
      -webkit-transform: translateY(-40%) scale(0.75, 0.75);
              transform: translateY(-40%) scale(0.75, 0.75); }

.mdc-select--disabled {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23#fff%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E");
  opacity: .38;
  cursor: default;
  pointer-events: none; }
  .mdc-select--disabled.mdc-select--box {
    background-color: #ff483c; }
  .mdc-select--disabled .mdc-floating-label {
    color: rgba(255, 255, 255, 0.37); }
  .mdc-select--disabled .mdc-line-ripple {
    display: none; }
  .mdc-select--disabled .mdc-select__native-control {
    border-bottom-style: dotted; }

.mdc-notched-outline {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 1px);
  height: calc(100% - 2px);
  -webkit-transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  overflow: hidden; }
  .mdc-notched-outline svg {
    position: absolute;
    width: 100%;
    height: 100%; }

.mdc-notched-outline__idle {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  -webkit-transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1), border-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1), border-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid;
  opacity: 1; }

.mdc-notched-outline__path {
  stroke-width: 1px;
  -webkit-transition: stroke 150ms cubic-bezier(0.4, 0, 0.2, 1), stroke-width 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: stroke 150ms cubic-bezier(0.4, 0, 0.2, 1), stroke-width 150ms cubic-bezier(0.4, 0, 0.2, 1);
  fill: transparent; }

.mdc-notched-outline--notched {
  opacity: 1; }

.mdc-notched-outline--notched ~ .mdc-notched-outline__idle {
  opacity: 0; }

.mdc-text-field-helper-text {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.03333em;
  text-decoration: inherit;
  text-transform: inherit;
  margin: 0;
  -webkit-transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  will-change: opacity; }
  .mdc-text-field + .mdc-text-field-helper-text {
    margin-bottom: 8px; }

.mdc-text-field-helper-text--persistent {
  -webkit-transition: none;
  transition: none;
  opacity: 1;
  will-change: initial; }

.mdc-text-field--with-leading-icon .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  position: absolute;
  bottom: 16px;
  cursor: pointer; }

.mdc-text-field__icon:not([tabindex]),
.mdc-text-field__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none; }

.mdc-text-field {
  display: inline-block;
  position: relative;
  margin-bottom: 8px;
  will-change: opacity, transform, color; }
  .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
    border-bottom-color: rgba(255, 255, 255, 0.42); }
  .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input:hover {
    border-bottom-color: rgba(255, 255, 255, 0.87); }
  .mdc-text-field .mdc-line-ripple {
    background-color: #FAD000;
    /* @alternate */
    background-color: var(--mdc-theme-primary, #FAD000); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: rgba(255, 255, 255, 0.87); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: rgba(255, 255, 255, 0.6); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.6); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.6); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.6); }
  .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-text {
    color: rgba(255, 255, 255, 0.6); }
  .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--textarea) {
    border-bottom-color: rgba(255, 255, 255, 0.12); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon {
    color: rgba(255, 255, 255, 0.54); }
  .mdc-text-field .mdc-text-field__input {
    caret-color: #FAD000;
    /* @alternate */
    caret-color: var(--mdc-theme-primary, #FAD000); }

.mdc-text-field__input {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  width: 100%;
  height: 30px;
  padding: 20px 0 1px;
  -webkit-transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .mdc-text-field__input::-webkit-input-placeholder {
    -webkit-transition: color 180ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: color 180ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1; }
  .mdc-text-field__input::-moz-placeholder {
    -webkit-transition: color 180ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: color 180ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1; }
  .mdc-text-field__input::-ms-input-placeholder {
    -webkit-transition: color 180ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: color 180ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1; }
  .mdc-text-field__input::placeholder {
    -webkit-transition: color 180ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: color 180ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1; }
  .mdc-text-field__input:focus {
    outline: none; }
  .mdc-text-field__input:invalid {
    -webkit-box-shadow: none;
            box-shadow: none; }
  .mdc-text-field__input:-webkit-autofill + .mdc-floating-label {
    -webkit-transform: translateY(-100%) scale(0.75);
            transform: translateY(-100%) scale(0.75);
    cursor: auto; }

.mdc-text-field--outlined {
  height: 56px;
  border: none; }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__idle {
    border-color: rgba(255, 255, 255, 0.24); }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__path {
    stroke: rgba(255, 255, 255, 0.24); }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline__idle,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline__idle {
    border-color: rgba(255, 255, 255, 0.87); }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__path,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__path {
    stroke: rgba(255, 255, 255, 0.87); }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__path {
    stroke: #FAD000;
    /* @alternate */
    stroke: var(--mdc-theme-primary, #FAD000); }
  .mdc-text-field--outlined .mdc-floating-label--float-above {
    -webkit-transform: translateY(-130%) scale(0.75);
            transform: translateY(-130%) scale(0.75); }
  .mdc-text-field--outlined .mdc-floating-label--shake {
    -webkit-animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1;
            animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1; }
  .mdc-text-field--outlined .mdc-notched-outline {
    border-radius: 4px; }
  .mdc-text-field--outlined .mdc-notched-outline__idle {
    border-radius: 4px; }
  .mdc-text-field--outlined .mdc-text-field__input {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 12px;
    border: none !important;
    background-color: transparent;
    z-index: 1; }
  .mdc-text-field--outlined .mdc-floating-label {
    /* @noflip */
    left: 16px;
    /* @noflip */
    right: initial;
    position: absolute;
    bottom: 20px; }
    [dir="rtl"] .mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--outlined .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 16px; }
  .mdc-text-field--outlined .mdc-text-field__icon {
    z-index: 2; }

.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__path {
  stroke-width: 2px; }

.mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.6); }

.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__idle {
  border-color: rgba(255, 255, 255, 0.06); }

.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__path {
  stroke: rgba(255, 255, 255, 0.06); }

.mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
  border-bottom: none; }

.mdc-text-field--outlined.mdc-text-field--dense {
  height: 48px; }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
    -webkit-transform: translateY(-110%) scale(0.923);
            transform: translateY(-110%) scale(0.923); }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake {
    -webkit-animation: mdc-floating-label-shake-float-above-text-field-outlined-dense 250ms 1;
            animation: mdc-floating-label-shake-float-above-text-field-outlined-dense 250ms 1; }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__input {
    padding: 12px 12px 7px; }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label {
    bottom: 16px; }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__icon {
    top: 12px; }

.mdc-text-field--box {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  border-radius: 4px 4px 0 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  height: 56px;
  margin-top: 16px;
  overflow: hidden; }
  .mdc-text-field--box::before, .mdc-text-field--box::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-text-field--box::before {
    -webkit-transition: opacity 15ms linear, background-color 15ms linear;
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-text-field--box.mdc-ripple-upgraded::before {
    -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
            transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-text-field--box.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  .mdc-text-field--box.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-text-field--box.mdc-ripple-upgraded--foreground-activation::after {
    -webkit-animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
            animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-text-field--box.mdc-ripple-upgraded--foreground-deactivation::after {
    -webkit-animation: 150ms mdc-ripple-fg-opacity-out;
            animation: 150ms mdc-ripple-fg-opacity-out;
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
            transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-text-field--box::before, .mdc-text-field--box::after {
    background-color: rgba(255, 255, 255, 0.87); }
  .mdc-text-field--box:hover::before {
    opacity: 0.08; }
  .mdc-text-field--box:not(.mdc-ripple-upgraded):focus::before, .mdc-text-field--box:not(.mdc-ripple-upgraded):focus-within::before, .mdc-text-field--box.mdc-ripple-upgraded--background-focused::before {
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms;
    opacity: 0.24; }
  .mdc-text-field--box::before, .mdc-text-field--box::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-text-field--box.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-text-field--box:not(.mdc-text-field--disabled) {
    background-color: #ff4b40; }
  .mdc-text-field--box .mdc-floating-label--float-above {
    -webkit-transform: translateY(-50%) scale(0.75);
            transform: translateY(-50%) scale(0.75); }
  .mdc-text-field--box .mdc-floating-label--shake {
    -webkit-animation: mdc-floating-label-shake-float-above-text-field-box 250ms 1;
            animation: mdc-floating-label-shake-float-above-text-field-box 250ms 1; }
  .mdc-text-field--box .mdc-text-field__input {
    -ms-flex-item-align: end;
        align-self: flex-end;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    height: 100%;
    padding: 20px 16px 0; }
  .mdc-text-field--box .mdc-floating-label {
    /* @noflip */
    left: 16px;
    /* @noflip */
    right: initial;
    position: absolute;
    bottom: 20px;
    width: calc(100% - 48px);
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    overflow: hidden;
    will-change: transform; }
    [dir="rtl"] .mdc-text-field--box .mdc-floating-label, .mdc-text-field--box .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 16px; }

.mdc-text-field--box.mdc-text-field--disabled {
  background-color: #ff483c;
  border-bottom: none; }
  .mdc-text-field--box.mdc-text-field--disabled .mdc-text-field__input {
    border-bottom-color: rgba(255, 255, 255, 0.06); }
  .mdc-text-field--box.mdc-text-field--disabled:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: rgba(255, 255, 255, 0.37); }
  .mdc-text-field--box.mdc-text-field--disabled:not(.mdc-text-field--disabled) .mdc-text-field__input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.37); }
  .mdc-text-field--box.mdc-text-field--disabled:not(.mdc-text-field--disabled) .mdc-text-field__input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.37); }
  .mdc-text-field--box.mdc-text-field--disabled:not(.mdc-text-field--disabled) .mdc-text-field__input::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.37); }
  .mdc-text-field--box.mdc-text-field--disabled:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.37); }

.mdc-text-field--box.mdc-text-field--dense .mdc-floating-label--float-above {
  -webkit-transform: translateY(-70%) scale(0.923);
          transform: translateY(-70%) scale(0.923); }

.mdc-text-field--box.mdc-text-field--dense .mdc-floating-label--shake {
  -webkit-animation: mdc-floating-label-shake-float-above-text-field-box-dense 250ms 1;
          animation: mdc-floating-label-shake-float-above-text-field-box-dense 250ms 1; }

.mdc-text-field--box.mdc-text-field--dense .mdc-text-field__input {
  padding: 12px 12px 0; }

.mdc-text-field--with-leading-icon .mdc-text-field__icon {
  /* @noflip */
  left: 15px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon .mdc-text-field__icon, .mdc-text-field--with-leading-icon .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 15px; }

.mdc-text-field--with-leading-icon .mdc-text-field__input {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 15px; }
  [dir="rtl"] .mdc-text-field--with-leading-icon .mdc-text-field__input, .mdc-text-field--with-leading-icon .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 15px;
    /* @noflip */
    padding-right: 48px; }

.mdc-text-field--with-leading-icon .mdc-floating-label {
  /* @noflip */
  left: 48px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon .mdc-floating-label, .mdc-text-field--with-leading-icon .mdc-floating-label[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 48px; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  -webkit-transform: translateY(-130%) translateX(-32px) scale(0.75);
          transform: translateY(-130%) translateX(-32px) scale(0.75); }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above[dir="rtl"] {
    -webkit-transform: translateY(-130%) translateX(32px) scale(0.75);
            transform: translateY(-130%) translateX(32px) scale(0.75); }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake {
  -webkit-animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon 250ms 1;
          animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon 250ms 1; }

[dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir="rtl"] .mdc-floating-label--shake {
  -webkit-animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl 250ms 1;
          animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl 250ms 1; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
  -webkit-transform: translateY(-110%) translateX(-21px) scale(0.923);
          transform: translateY(-110%) translateX(-21px) scale(0.923); }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above[dir="rtl"] {
    -webkit-transform: translateY(-110%) translateX(21px) scale(0.923);
            transform: translateY(-110%) translateX(21px) scale(0.923); }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake {
  -webkit-animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense 250ms 1;
          animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense 250ms 1; }

[dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense[dir="rtl"] .mdc-floating-label--shake {
  -webkit-animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl 250ms 1;
          animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl 250ms 1; }

.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 15px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon .mdc-text-field__icon, .mdc-text-field--with-trailing-icon .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: 15px;
    /* @noflip */
    right: initial; }

.mdc-text-field--with-trailing-icon .mdc-text-field__input {
  /* @noflip */
  padding-left: 15px;
  /* @noflip */
  padding-right: 48px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon .mdc-text-field__input, .mdc-text-field--with-trailing-icon .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 48px;
    /* @noflip */
    padding-right: 15px; }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
  bottom: 16px;
  -webkit-transform: scale(0.8);
          transform: scale(0.8); }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 12px; }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input {
  /* @noflip */
  padding-left: 38px;
  /* @noflip */
  padding-right: 12px; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 12px;
    /* @noflip */
    padding-right: 38px; }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label {
  /* @noflip */
  left: 38px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 38px; }

.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: 12px;
    /* @noflip */
    right: initial; }

.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 38px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 38px;
    /* @noflip */
    padding-right: 12px; }

.mdc-text-field--upgraded:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box) {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-top: 16px; }
  .mdc-text-field--upgraded:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box):not(.mdc-text-field--textarea):not(.mdc-text-field--outlined) {
    height: 48px; }

.mdc-text-field--dense {
  margin-top: 12px;
  margin-bottom: 4px; }
  .mdc-text-field--dense .mdc-floating-label--float-above {
    -webkit-transform: translateY(-110%) scale(0.923);
            transform: translateY(-110%) scale(0.923); }
  .mdc-text-field--dense .mdc-floating-label {
    font-size: .813rem; }

.mdc-text-field__input:required + .mdc-floating-label::after {
  margin-left: 1px;
  content: "*"; }

.mdc-text-field--textarea {
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: initial;
  -webkit-transition: none;
  transition: none;
  border: 1px solid;
  overflow: hidden; }
  .mdc-text-field--textarea .mdc-floating-label {
    border-radius: 4px 4px 0 0; }
  .mdc-text-field--textarea .mdc-text-field__input {
    border-radius: 2px; }
  .mdc-text-field--textarea:not(.mdc-text-field--disabled) {
    border-color: rgba(255, 255, 255, 0.73); }
    .mdc-text-field--textarea:not(.mdc-text-field--disabled) .mdc-text-field__input:focus {
      border-color: rgba(255, 255, 255, 0.73); }
  .mdc-text-field--textarea .mdc-floating-label--float-above {
    -webkit-transform: translateY(-50%) scale(0.923);
            transform: translateY(-50%) scale(0.923); }
  .mdc-text-field--textarea .mdc-floating-label--shake {
    -webkit-animation: mdc-floating-label-shake-float-above-textarea 250ms 1;
            animation: mdc-floating-label-shake-float-above-textarea 250ms 1; }
  .mdc-text-field--textarea .mdc-text-field__input {
    height: auto;
    margin: 0;
    padding: 16px;
    padding-top: 32px;
    border: 1px solid transparent; }
  .mdc-text-field--textarea .mdc-floating-label {
    background-color: #ff4438;
    /* @noflip */
    left: 1px;
    /* @noflip */
    right: 0;
    /* @noflip */
    margin-left: 8px;
    /* @noflip */
    margin-right: 0;
    top: 18px;
    bottom: auto;
    margin-top: 2px;
    padding: 12px 8px 8px 8px;
    line-height: 1.15;
    pointer-events: none; }
    [dir="rtl"] .mdc-text-field--textarea .mdc-floating-label, .mdc-text-field--textarea .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: 0;
      /* @noflip */
      right: 1px; }
    [dir="rtl"] .mdc-text-field--textarea .mdc-floating-label, .mdc-text-field--textarea .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      margin-left: 0;
      /* @noflip */
      margin-right: 8px; }

.mdc-text-field--fullwidth {
  width: 100%; }
  .mdc-text-field--fullwidth .mdc-text-field__input {
    resize: vertical; }
  .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    height: 56px;
    margin: 0;
    border: none;
    border-bottom: 1px solid;
    outline: none; }
    .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) .mdc-text-field__input {
      width: 100%;
      height: 100%;
      padding: 0;
      resize: none;
      border: none !important; }

.mdc-text-field--fullwidth.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--textarea) {
  border-bottom-color: #b00020; }

.mdc-text-field--dense + .mdc-text-field-helper-text {
  margin-bottom: 4px; }

.mdc-text-field--box + .mdc-text-field-helper-text,
.mdc-text-field--outlined + .mdc-text-field-helper-text {
  margin-right: 16px;
  margin-left: 16px; }

.mdc-form-field > .mdc-text-field + label {
  -ms-flex-item-align: start;
      align-self: flex-start; }

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(250, 208, 0, 0.87); }

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-text-field__input::-webkit-input-placeholder {
  color: rgba(250, 208, 0, 0.87); }

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-text-field__input::-moz-placeholder {
  color: rgba(250, 208, 0, 0.87); }

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-text-field__input::-ms-input-placeholder {
  color: rgba(250, 208, 0, 0.87); }

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
  color: rgba(250, 208, 0, 0.87); }

.mdc-text-field--focused .mdc-text-field__input:required + .mdc-floating-label::after {
  color: #b00020; }

.mdc-text-field--focused + .mdc-text-field-helper-text:not(.mdc-text-field-helper-text--validation-msg) {
  opacity: 1; }

.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled) {
  border-color: #FAD000;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #FAD000); }
  .mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-text-field__input:focus {
    border-color: #FAD000;
    /* @alternate */
    border-color: var(--mdc-theme-primary, #FAD000); }

.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
  border-bottom-color: #b00020; }

.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input:hover {
  border-bottom-color: #b00020; }

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple {
  background-color: #b00020; }

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #b00020; }

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input::-webkit-input-placeholder {
  color: #b00020; }

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input::-moz-placeholder {
  color: #b00020; }

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input::-ms-input-placeholder {
  color: #b00020; }

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
  color: #b00020; }

.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-text--validation-msg {
  color: #b00020; }

.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: #b00020; }

.mdc-text-field--invalid.mdc-text-field--with-trailing-icon:not(.mdc-text-field--disabled) .mdc-text-field__icon {
  color: #b00020; }

.mdc-text-field--invalid + .mdc-text-field-helper-text--validation-msg {
  opacity: 1; }

.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) {
  border-color: #b00020; }
  .mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input:focus {
    border-color: #b00020; }

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__idle {
  border-color: #b00020; }

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__path {
  stroke: #b00020; }

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline__idle,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline__idle {
  border-color: #b00020; }

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__path,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__path {
  stroke: #b00020; }

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__path {
  stroke: #b00020; }

.mdc-text-field--disabled {
  pointer-events: none; }
  .mdc-text-field--disabled .mdc-text-field__input {
    border-bottom-color: rgba(35, 31, 32, 0.26); }
  .mdc-text-field--disabled .mdc-text-field__input {
    color: rgba(255, 255, 255, 0.37); }
  .mdc-text-field--disabled .mdc-floating-label {
    color: rgba(255, 255, 255, 0.37); }
  .mdc-text-field--disabled .mdc-text-field__input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.37); }
  .mdc-text-field--disabled .mdc-text-field__input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.37); }
  .mdc-text-field--disabled .mdc-text-field__input::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.37); }
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.37); }
  .mdc-text-field--disabled + .mdc-text-field-helper-text {
    color: rgba(255, 255, 255, 0.37); }
  .mdc-text-field--disabled .mdc-text-field__icon {
    color: rgba(255, 255, 255, 0.3); }
  .mdc-text-field--disabled:not(.mdc-text-field--textarea) {
    border-bottom-color: rgba(255, 255, 255, 0.12); }
  .mdc-text-field--disabled .mdc-text-field__input {
    border-bottom: 1px dotted; }
  .mdc-text-field--disabled .mdc-floating-label {
    cursor: default; }

.mdc-text-field--textarea.mdc-text-field--disabled {
  border-color: rgba(35, 31, 32, 0.26);
  background-color: #f9f9f9;
  border-style: solid; }
  .mdc-text-field--textarea.mdc-text-field--disabled .mdc-text-field__input:focus {
    border-color: rgba(35, 31, 32, 0.26); }
  .mdc-text-field--textarea.mdc-text-field--disabled .mdc-text-field__input {
    border: 1px solid transparent; }
  .mdc-text-field--textarea.mdc-text-field--disabled .mdc-floating-label {
    background-color: #f9f9f9; }

@-webkit-keyframes mdc-floating-label-shake-float-above-text-field-box {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.75);
            transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.75); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.75);
            transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.75); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75); } }

@keyframes mdc-floating-label-shake-float-above-text-field-box {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.75);
            transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.75); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.75);
            transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.75); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75); } }

@-webkit-keyframes mdc-floating-label-shake-float-above-text-field-box-dense {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.923); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-70%) scale(0.923);
            transform: translateX(calc(4% - 0%)) translateY(-70%) scale(0.923); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-70%) scale(0.923);
            transform: translateX(calc(-4% - 0%)) translateY(-70%) scale(0.923); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.923); } }

@keyframes mdc-floating-label-shake-float-above-text-field-box-dense {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.923); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-70%) scale(0.923);
            transform: translateX(calc(4% - 0%)) translateY(-70%) scale(0.923); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-70%) scale(0.923);
            transform: translateX(calc(-4% - 0%)) translateY(-70%) scale(0.923); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.923); } }

@-webkit-keyframes mdc-floating-label-shake-float-above-text-field-outlined {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-130%) scale(0.75);
            transform: translateX(calc(4% - 0%)) translateY(-130%) scale(0.75); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-130%) scale(0.75);
            transform: translateX(calc(-4% - 0%)) translateY(-130%) scale(0.75); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-130%) scale(0.75);
            transform: translateX(calc(4% - 0%)) translateY(-130%) scale(0.75); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-130%) scale(0.75);
            transform: translateX(calc(-4% - 0%)) translateY(-130%) scale(0.75); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75); } }

@-webkit-keyframes mdc-floating-label-shake-float-above-text-field-outlined-dense {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-110%) scale(0.923); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-110%) scale(0.923);
            transform: translateX(calc(4% - 0%)) translateY(-110%) scale(0.923); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-110%) scale(0.923);
            transform: translateX(calc(-4% - 0%)) translateY(-110%) scale(0.923); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-110%) scale(0.923); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-dense {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-110%) scale(0.923); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-110%) scale(0.923);
            transform: translateX(calc(4% - 0%)) translateY(-110%) scale(0.923); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-110%) scale(0.923);
            transform: translateX(calc(-4% - 0%)) translateY(-110%) scale(0.923); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-110%) scale(0.923); } }

@-webkit-keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon {
  0% {
    -webkit-transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(4% - 32px)) translateY(-130%) scale(0.75); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(-4% - 32px)) translateY(-130%) scale(0.75); }
  100% {
    -webkit-transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon {
  0% {
    -webkit-transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(4% - 32px)) translateY(-130%) scale(0.75); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(-4% - 32px)) translateY(-130%) scale(0.75); }
  100% {
    -webkit-transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75); } }

@-webkit-keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense {
  0% {
    -webkit-transform: translateX(calc(0 - 21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - 21px)) translateY(-110%) scale(0.923); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(4% - 21px)) translateY(-110%) scale(0.923); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(-4% - 21px)) translateY(-110%) scale(0.923); }
  100% {
    -webkit-transform: translateX(calc(0 - 21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - 21px)) translateY(-110%) scale(0.923); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense {
  0% {
    -webkit-transform: translateX(calc(0 - 21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - 21px)) translateY(-110%) scale(0.923); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(4% - 21px)) translateY(-110%) scale(0.923); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(-4% - 21px)) translateY(-110%) scale(0.923); }
  100% {
    -webkit-transform: translateX(calc(0 - 21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - 21px)) translateY(-110%) scale(0.923); } }

@-webkit-keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl {
  0% {
    -webkit-transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - -32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(4% - -32px)) translateY(-130%) scale(0.75); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - -32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(-4% - -32px)) translateY(-130%) scale(0.75); }
  100% {
    -webkit-transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl {
  0% {
    -webkit-transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - -32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(4% - -32px)) translateY(-130%) scale(0.75); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - -32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(-4% - -32px)) translateY(-130%) scale(0.75); }
  100% {
    -webkit-transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75);
            transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75); } }

@-webkit-keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl {
  0% {
    -webkit-transform: translateX(calc(0 - -21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - -21px)) translateY(-110%) scale(0.923); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - -21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(4% - -21px)) translateY(-110%) scale(0.923); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - -21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(-4% - -21px)) translateY(-110%) scale(0.923); }
  100% {
    -webkit-transform: translateX(calc(0 - -21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - -21px)) translateY(-110%) scale(0.923); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl {
  0% {
    -webkit-transform: translateX(calc(0 - -21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - -21px)) translateY(-110%) scale(0.923); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - -21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(4% - -21px)) translateY(-110%) scale(0.923); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - -21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(-4% - -21px)) translateY(-110%) scale(0.923); }
  100% {
    -webkit-transform: translateX(calc(0 - -21px)) translateY(-110%) scale(0.923);
            transform: translateX(calc(0 - -21px)) translateY(-110%) scale(0.923); } }

@-webkit-keyframes mdc-floating-label-shake-float-above-textarea {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.923); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.923);
            transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.923); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.923);
            transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.923); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.923); } }

@keyframes mdc-floating-label-shake-float-above-textarea {
  0% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.923); }
  33% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.923);
            transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.923); }
  66% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.923);
            transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.923); }
  100% {
    -webkit-transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.923);
            transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.923); } }

.mdc-text-field-helper-text {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.03333em;
  text-decoration: inherit;
  text-transform: inherit;
  margin: 0;
  -webkit-transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  will-change: opacity; }
  .mdc-text-field + .mdc-text-field-helper-text {
    margin-bottom: 8px; }

.mdc-text-field-helper-text--persistent {
  -webkit-transition: none;
  transition: none;
  opacity: 1;
  will-change: initial; }

.mdc-form-field {
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit;
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, white);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  vertical-align: middle; }
  .mdc-form-field > label {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
    margin-right: auto;
    padding-left: 4px; }
  [dir="rtl"] .mdc-form-field > label, .mdc-form-field[dir="rtl"] > label {
    margin-left: auto;
    padding-right: 4px; }

.mdc-form-field--align-end > label {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
  margin-left: auto;
  padding-right: 4px; }

[dir="rtl"] .mdc-form-field--align-end > label, .mdc-form-field--align-end[dir="rtl"] > label {
  margin-right: auto;
  padding-left: 4px; }

@-webkit-keyframes mdc-checkbox-unchecked-checked-checkmark-path {
  0%,
  50% {
    stroke-dashoffset: 29.78334; }
  50% {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1); }
  100% {
    stroke-dashoffset: 0; } }

@keyframes mdc-checkbox-unchecked-checked-checkmark-path {
  0%,
  50% {
    stroke-dashoffset: 29.78334; }
  50% {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1); }
  100% {
    stroke-dashoffset: 0; } }

@-webkit-keyframes mdc-checkbox-unchecked-indeterminate-mixedmark {
  0%,
  68.2% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0); }
  68.2% {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
            animation-timing-function: cubic-bezier(0, 0, 0, 1); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@keyframes mdc-checkbox-unchecked-indeterminate-mixedmark {
  0%,
  68.2% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0); }
  68.2% {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
            animation-timing-function: cubic-bezier(0, 0, 0, 1); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes mdc-checkbox-checked-unchecked-checkmark-path {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
            animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    opacity: 1;
    stroke-dashoffset: 0; }
  to {
    opacity: 0;
    stroke-dashoffset: -29.78334; } }

@keyframes mdc-checkbox-checked-unchecked-checkmark-path {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
            animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    opacity: 1;
    stroke-dashoffset: 0; }
  to {
    opacity: 0;
    stroke-dashoffset: -29.78334; } }

@-webkit-keyframes mdc-checkbox-checked-indeterminate-checkmark {
  from {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 1; }
  to {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    opacity: 0; } }

@keyframes mdc-checkbox-checked-indeterminate-checkmark {
  from {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 1; }
  to {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    opacity: 0; } }

@-webkit-keyframes mdc-checkbox-indeterminate-checked-checkmark {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
            animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    opacity: 0; }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    opacity: 1; } }

@keyframes mdc-checkbox-indeterminate-checked-checkmark {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
            animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    opacity: 0; }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    opacity: 1; } }

@-webkit-keyframes mdc-checkbox-checked-indeterminate-mixedmark {
  from {
    -webkit-animation-timing-function: mdc-animation-deceleration-curve-timing-function;
            animation-timing-function: mdc-animation-deceleration-curve-timing-function;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    opacity: 0; }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 1; } }

@keyframes mdc-checkbox-checked-indeterminate-mixedmark {
  from {
    -webkit-animation-timing-function: mdc-animation-deceleration-curve-timing-function;
            animation-timing-function: mdc-animation-deceleration-curve-timing-function;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    opacity: 0; }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 1; } }

@-webkit-keyframes mdc-checkbox-indeterminate-checked-mixedmark {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
            animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 1; }
  to {
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg);
    opacity: 0; } }

@keyframes mdc-checkbox-indeterminate-checked-mixedmark {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
            animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 1; }
  to {
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg);
    opacity: 0; } }

@-webkit-keyframes mdc-checkbox-indeterminate-unchecked-mixedmark {
  0% {
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    opacity: 1; }
  32.8%,
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    opacity: 0; } }

@keyframes mdc-checkbox-indeterminate-unchecked-mixedmark {
  0% {
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    opacity: 1; }
  32.8%,
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    opacity: 0; } }

.mdc-checkbox {
  display: inline-block;
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 18px;
          flex: 0 0 18px;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  width: 18px;
  height: 18px;
  padding: 11px;
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: bottom;
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity; }
  .mdc-checkbox::before, .mdc-checkbox::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-checkbox::before {
    -webkit-transition: opacity 15ms linear, background-color 15ms linear;
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-checkbox.mdc-ripple-upgraded::before {
    -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
            transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-checkbox.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  .mdc-checkbox.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-checkbox.mdc-ripple-upgraded--foreground-activation::after {
    -webkit-animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
            animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-checkbox.mdc-ripple-upgraded--foreground-deactivation::after {
    -webkit-animation: 150ms mdc-ripple-fg-opacity-out;
            animation: 150ms mdc-ripple-fg-opacity-out;
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
            transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-checkbox::before, .mdc-checkbox::after {
    background-color: #EDC7A2; }
    @supports not (-ms-ime-align: auto) {
      .mdc-checkbox::before, .mdc-checkbox::after {
        /* @alternate */
        background-color: var(--mdc-theme-secondary, #EDC7A2); } }
  .mdc-checkbox:hover::before {
    opacity: 0.08; }
  .mdc-checkbox:not(.mdc-ripple-upgraded):focus::before, .mdc-checkbox.mdc-ripple-upgraded--background-focused::before {
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms;
    opacity: 0.24; }
  .mdc-checkbox:not(.mdc-ripple-upgraded)::after {
    -webkit-transition: opacity 150ms linear;
    transition: opacity 150ms linear; }
  .mdc-checkbox:not(.mdc-ripple-upgraded):active::after {
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms;
    opacity: 0.32; }
  .mdc-checkbox.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.32; }
  .mdc-checkbox::before, .mdc-checkbox::after {
    top: calc(50% - 50%);
    /* @noflip */
    left: calc(50% - 50%);
    width: 100%;
    height: 100%; }
  .mdc-checkbox.mdc-ripple-upgraded::before, .mdc-checkbox.mdc-ripple-upgraded::after {
    top: var(--mdc-ripple-top, calc(50% - 50%));
    /* @noflip */
    left: var(--mdc-ripple-left, calc(50% - 50%));
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-checkbox.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }

.mdc-checkbox__checkmark {
  color: #000; }

.mdc-checkbox__mixedmark {
  border-color: #000; }

.mdc-checkbox__background::before {
  background-color: #EDC7A2; }
  @supports not (-ms-ime-align: auto) {
    .mdc-checkbox__background::before {
      /* @alternate */
      background-color: var(--mdc-theme-secondary, #EDC7A2); } }

.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
  border-color: rgba(255, 255, 255, 0.54);
  background-color: transparent; }

.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
  border-color: #EDC7A2;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #EDC7A2);
  background-color: #EDC7A2;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #EDC7A2); }

@-webkit-keyframes mdc-checkbox-fade-in-background-0 {
  0% {
    border-color: rgba(255, 255, 255, 0.54);
    background-color: transparent; }
  50% {
    border-color: #EDC7A2;
    /* @alternate */
    border-color: var(--mdc-theme-secondary, #EDC7A2);
    background-color: #EDC7A2;
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #EDC7A2); } }

@keyframes mdc-checkbox-fade-in-background-0 {
  0% {
    border-color: rgba(255, 255, 255, 0.54);
    background-color: transparent; }
  50% {
    border-color: #EDC7A2;
    /* @alternate */
    border-color: var(--mdc-theme-secondary, #EDC7A2);
    background-color: #EDC7A2;
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #EDC7A2); } }

@-webkit-keyframes mdc-checkbox-fade-out-background-0 {
  0%, 80% {
    border-color: #EDC7A2;
    /* @alternate */
    border-color: var(--mdc-theme-secondary, #EDC7A2);
    background-color: #EDC7A2;
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #EDC7A2); }
  100% {
    border-color: rgba(255, 255, 255, 0.54);
    background-color: transparent; } }

@keyframes mdc-checkbox-fade-out-background-0 {
  0%, 80% {
    border-color: #EDC7A2;
    /* @alternate */
    border-color: var(--mdc-theme-secondary, #EDC7A2);
    background-color: #EDC7A2;
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #EDC7A2); }
  100% {
    border-color: rgba(255, 255, 255, 0.54);
    background-color: transparent; } }

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  -webkit-animation-name: mdc-checkbox-fade-in-background-0;
          animation-name: mdc-checkbox-fade-in-background-0; }

.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  -webkit-animation-name: mdc-checkbox-fade-out-background-0;
          animation-name: mdc-checkbox-fade-out-background-0; }

.mdc-checkbox__native-control:disabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
  border-color: rgba(255, 255, 255, 0.26); }

.mdc-checkbox__native-control:disabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:disabled:indeterminate ~ .mdc-checkbox__background {
  border-color: transparent;
  background-color: rgba(255, 255, 255, 0.26); }

@media screen and (-ms-high-contrast: active) {
  .mdc-checkbox__mixedmark {
    margin: 0 1px; } }

.mdc-checkbox--disabled {
  cursor: default;
  pointer-events: none; }

.mdc-checkbox__background {
  /* @noflip */
  left: 11px;
  /* @noflip */
  right: initial;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: absolute;
  top: 11px;
  bottom: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 45%;
  height: 45%;
  -webkit-transition: background-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: background-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  border: 2px solid currentColor;
  border-radius: 2px;
  background-color: transparent;
  pointer-events: none;
  will-change: background-color, border-color; }
  .mdc-checkbox[dir="rtl"] .mdc-checkbox__background,
  [dir="rtl"] .mdc-checkbox .mdc-checkbox__background {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 11px; }

.mdc-checkbox__checkmark {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-transition: opacity 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  opacity: 0; }
  .mdc-checkbox--upgraded .mdc-checkbox__checkmark {
    opacity: 1; }

.mdc-checkbox__checkmark-path {
  -webkit-transition: stroke-dashoffset 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: stroke-dashoffset 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  stroke: currentColor;
  stroke-width: 3.12px;
  stroke-dashoffset: 29.78334;
  stroke-dasharray: 29.78334; }

.mdc-checkbox__mixedmark {
  width: 100%;
  height: 0;
  -webkit-transform: scaleX(0) rotate(0deg);
          transform: scaleX(0) rotate(0deg);
  -webkit-transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  border-width: 1px;
  border-style: solid;
  opacity: 0; }

.mdc-checkbox--upgraded .mdc-checkbox__background,
.mdc-checkbox--upgraded .mdc-checkbox__checkmark,
.mdc-checkbox--upgraded .mdc-checkbox__checkmark-path,
.mdc-checkbox--upgraded .mdc-checkbox__mixedmark {
  -webkit-transition: none !important;
  transition: none !important; }

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__background, .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__background, .mdc-checkbox--anim-checked-unchecked .mdc-checkbox__background, .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__background {
  -webkit-animation-duration: 180ms;
          animation-duration: 180ms;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__checkmark-path {
  -webkit-animation: 180ms linear 0s mdc-checkbox-unchecked-checked-checkmark-path;
          animation: 180ms linear 0s mdc-checkbox-unchecked-checked-checkmark-path;
  -webkit-transition: none;
  transition: none; }

.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__mixedmark {
  -webkit-animation: 90ms linear 0s mdc-checkbox-unchecked-indeterminate-mixedmark;
          animation: 90ms linear 0s mdc-checkbox-unchecked-indeterminate-mixedmark;
  -webkit-transition: none;
  transition: none; }

.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__checkmark-path {
  -webkit-animation: 90ms linear 0s mdc-checkbox-checked-unchecked-checkmark-path;
          animation: 90ms linear 0s mdc-checkbox-checked-unchecked-checkmark-path;
  -webkit-transition: none;
  transition: none; }

.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__checkmark {
  -webkit-animation: 90ms linear 0s mdc-checkbox-checked-indeterminate-checkmark;
          animation: 90ms linear 0s mdc-checkbox-checked-indeterminate-checkmark;
  -webkit-transition: none;
  transition: none; }

.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__mixedmark {
  -webkit-animation: 90ms linear 0s mdc-checkbox-checked-indeterminate-mixedmark;
          animation: 90ms linear 0s mdc-checkbox-checked-indeterminate-mixedmark;
  -webkit-transition: none;
  transition: none; }

.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__checkmark {
  -webkit-animation: 500ms linear 0s mdc-checkbox-indeterminate-checked-checkmark;
          animation: 500ms linear 0s mdc-checkbox-indeterminate-checked-checkmark;
  -webkit-transition: none;
  transition: none; }

.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__mixedmark {
  -webkit-animation: 500ms linear 0s mdc-checkbox-indeterminate-checked-mixedmark;
          animation: 500ms linear 0s mdc-checkbox-indeterminate-checked-mixedmark;
  -webkit-transition: none;
  transition: none; }

.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__mixedmark {
  -webkit-animation: 300ms linear 0s mdc-checkbox-indeterminate-unchecked-mixedmark;
          animation: 300ms linear 0s mdc-checkbox-indeterminate-unchecked-mixedmark;
  -webkit-transition: none;
  transition: none; }

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background {
  -webkit-transition: border-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1), background-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: border-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1), background-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1); }
  .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path,
  .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path {
    stroke-dashoffset: 0; }

.mdc-checkbox__background::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
  -webkit-transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
  will-change: opacity, transform; }

.mdc-ripple-upgraded--unbounded .mdc-checkbox__background::before {
  content: none; }

.mdc-checkbox__native-control:focus ~ .mdc-checkbox__background::before {
  -webkit-transform: scale(2.75, 2.75);
          transform: scale(2.75, 2.75);
  -webkit-transition: opacity 80ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 80ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 80ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 80ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 80ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 80ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 80ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 80ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 80ms 0ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 0.26; }

.mdc-checkbox__native-control {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit; }
  .mdc-checkbox__native-control:disabled {
    cursor: default;
    pointer-events: none; }

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  -webkit-transition: opacity 180ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 180ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 180ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 180ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 1; }

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  -webkit-transform: scaleX(1) rotate(-45deg);
          transform: scaleX(1) rotate(-45deg); }

.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  opacity: 0; }

.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  -webkit-transform: scaleX(1) rotate(0deg);
          transform: scaleX(1) rotate(0deg);
  opacity: 1; }

.mdc-menu {
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: #212721;
  /* @alternate */
  background-color: var(--mdc-theme-background, #212721);
  display: none;
  position: absolute;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 170px;
  max-width: calc(100vw - 32px);
  max-height: calc(100vh - 32px);
  margin: 0;
  padding: 0;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: top left;
          transform-origin: top left;
  border-radius: 2px;
  opacity: 0;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: auto;
  will-change: transform, opacity;
  z-index: 4; }
  .mdc-menu:focus {
    outline: none; }
  .mdc-menu--animating-open {
    display: inline-block;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transition: opacity 0.03s linear, -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.03s linear, -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.03s linear, transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.03s linear, transform 0.12s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    opacity: 0;
    overflow-y: hidden; }
  .mdc-menu--open {
    display: inline-block;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  .mdc-menu--animating-closed {
    display: inline-block;
    -webkit-transition: opacity 0.075s linear;
    transition: opacity 0.075s linear;
    opacity: 0;
    overflow-y: hidden; }
  .mdc-menu__items {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transform: scale(1);
            transform: scale(1);
    overflow-x: hidden;
    overflow-y: auto;
    will-change: transform; }
    .mdc-menu__items > .mdc-list-item {
      cursor: pointer; }
    .mdc-menu--animating .mdc-menu__items {
      overflow-y: hidden; }
  .mdc-menu--animating-open > .mdc-menu__items {
    -webkit-transform: scale(1.25);
            transform: scale(1.25); }
  .mdc-menu--open > .mdc-menu__items {
    -webkit-transform: scale(1);
            transform: scale(1); }
  [dir="rtl"] .mdc-menu {
    -webkit-transform-origin: top right;
            transform-origin: top right; }
  .mdc-menu .mdc-list-group,
  .mdc-menu .mdc-list {
    padding: 8px 0; }
  .mdc-menu .mdc-list-item {
    font-family: sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: 0.00937em;
    text-decoration: inherit;
    text-transform: inherit;
    position: relative;
    outline: none;
    color: inherit;
    text-decoration: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .mdc-menu .mdc-list-item__graphic {
    color: rgba(255, 255, 255, 0.7);
    /* @alternate */
    color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7)); }
  .mdc-menu .mdc-list-item[aria-disabled="true"] {
    color: rgba(255, 255, 255, 0.5);
    /* @alternate */
    color: var(--mdc-theme-text-disabled-on-background, rgba(255, 255, 255, 0.5));
    cursor: default;
    pointer-events: none; }
    .mdc-menu .mdc-list-item[aria-disabled="true"]:focus::before {
      opacity: 0; }

.mdc-menu-anchor {
  position: relative;
  overflow: visible; }

.mdc-icon-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  width: 48px;
  height: 48px;
  padding: 12px;
  font-size: 24px;
  display: inline-block;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: none;
  outline: none;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .mdc-icon-button::before, .mdc-icon-button::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-icon-button::before {
    -webkit-transition: opacity 15ms linear, background-color 15ms linear;
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-icon-button.mdc-ripple-upgraded::before {
    -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
            transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-icon-button.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  .mdc-icon-button.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-icon-button.mdc-ripple-upgraded--foreground-activation::after {
    -webkit-animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
            animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-icon-button.mdc-ripple-upgraded--foreground-deactivation::after {
    -webkit-animation: 150ms mdc-ripple-fg-opacity-out;
            animation: 150ms mdc-ripple-fg-opacity-out;
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
            transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-icon-button::before, .mdc-icon-button::after {
    top: calc(50% - 50%);
    /* @noflip */
    left: calc(50% - 50%);
    width: 100%;
    height: 100%; }
  .mdc-icon-button.mdc-ripple-upgraded::before, .mdc-icon-button.mdc-ripple-upgraded::after {
    top: var(--mdc-ripple-top, calc(50% - 50%));
    /* @noflip */
    left: var(--mdc-ripple-left, calc(50% - 50%));
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-icon-button.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-icon-button svg,
  .mdc-icon-button img {
    width: 24px;
    height: 24px; }
  .mdc-icon-button:disabled {
    color: rgba(0, 0, 0, 0.38);
    /* @alternate */
    color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
    cursor: default;
    pointer-events: none; }
  .mdc-icon-button::before, .mdc-icon-button::after {
    background-color: #fff; }
  .mdc-icon-button:hover::before {
    opacity: 0.08; }
  .mdc-icon-button:not(.mdc-ripple-upgraded):focus::before, .mdc-icon-button.mdc-ripple-upgraded--background-focused::before {
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms;
    opacity: 0.24; }
  .mdc-icon-button:not(.mdc-ripple-upgraded)::after {
    -webkit-transition: opacity 150ms linear;
    transition: opacity 150ms linear; }
  .mdc-icon-button:not(.mdc-ripple-upgraded):active::after {
    -webkit-transition-duration: 75ms;
            transition-duration: 75ms;
    opacity: 0.32; }
  .mdc-icon-button.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.32; }

.mdc-icon-button__icon {
  display: inline-block; }
  .mdc-icon-button__icon.mdc-icon-button__icon--on {
    display: none; }

.mdc-icon-button--on .mdc-icon-button__icon {
  display: none; }
  .mdc-icon-button--on .mdc-icon-button__icon.mdc-icon-button__icon--on {
    display: inline-block; }

@-webkit-keyframes primary-indeterminate-translate {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  20% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  59.15% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(83.67142%);
            transform: translateX(83.67142%); }
  100% {
    -webkit-transform: translateX(200.61106%);
            transform: translateX(200.61106%); } }

@keyframes primary-indeterminate-translate {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  20% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  59.15% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(83.67142%);
            transform: translateX(83.67142%); }
  100% {
    -webkit-transform: translateX(200.61106%);
            transform: translateX(200.61106%); } }

@-webkit-keyframes primary-indeterminate-scale {
  0% {
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08); }
  36.65% {
    -webkit-animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
            animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08); }
  69.15% {
    -webkit-animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
            animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
    -webkit-transform: scaleX(0.66148);
            transform: scaleX(0.66148); }
  100% {
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08); } }

@keyframes primary-indeterminate-scale {
  0% {
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08); }
  36.65% {
    -webkit-animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
            animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08); }
  69.15% {
    -webkit-animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
            animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
    -webkit-transform: scaleX(0.66148);
            transform: scaleX(0.66148); }
  100% {
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08); } }

@-webkit-keyframes secondary-indeterminate-translate {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
            animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  25% {
    -webkit-animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
            animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
    -webkit-transform: translateX(37.65191%);
            transform: translateX(37.65191%); }
  48.35% {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
            animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
    -webkit-transform: translateX(84.38617%);
            transform: translateX(84.38617%); }
  100% {
    -webkit-transform: translateX(160.27778%);
            transform: translateX(160.27778%); } }

@keyframes secondary-indeterminate-translate {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
            animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  25% {
    -webkit-animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
            animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
    -webkit-transform: translateX(37.65191%);
            transform: translateX(37.65191%); }
  48.35% {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
            animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
    -webkit-transform: translateX(84.38617%);
            transform: translateX(84.38617%); }
  100% {
    -webkit-transform: translateX(160.27778%);
            transform: translateX(160.27778%); } }

@-webkit-keyframes secondary-indeterminate-scale {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
            animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08); }
  19.15% {
    -webkit-animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
            animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
    -webkit-transform: scaleX(0.4571);
            transform: scaleX(0.4571); }
  44.15% {
    -webkit-animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
            animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
    -webkit-transform: scaleX(0.72796);
            transform: scaleX(0.72796); }
  100% {
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08); } }

@keyframes secondary-indeterminate-scale {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
            animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08); }
  19.15% {
    -webkit-animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
            animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
    -webkit-transform: scaleX(0.4571);
            transform: scaleX(0.4571); }
  44.15% {
    -webkit-animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
            animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
    -webkit-transform: scaleX(0.72796);
            transform: scaleX(0.72796); }
  100% {
    -webkit-transform: scaleX(0.08);
            transform: scaleX(0.08); } }

@-webkit-keyframes buffering {
  to {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px); } }

@keyframes buffering {
  to {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px); } }

@-webkit-keyframes primary-indeterminate-translate-reverse {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  20% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  59.15% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(-83.67142%);
            transform: translateX(-83.67142%); }
  100% {
    -webkit-transform: translateX(-200.61106%);
            transform: translateX(-200.61106%); } }

@keyframes primary-indeterminate-translate-reverse {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  20% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
            animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  59.15% {
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
            animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    -webkit-transform: translateX(-83.67142%);
            transform: translateX(-83.67142%); }
  100% {
    -webkit-transform: translateX(-200.61106%);
            transform: translateX(-200.61106%); } }

@-webkit-keyframes secondary-indeterminate-translate-reverse {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
            animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  25% {
    -webkit-animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
            animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
    -webkit-transform: translateX(-37.65191%);
            transform: translateX(-37.65191%); }
  48.35% {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
            animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
    -webkit-transform: translateX(-84.38617%);
            transform: translateX(-84.38617%); }
  100% {
    -webkit-transform: translateX(-160.27778%);
            transform: translateX(-160.27778%); } }

@keyframes secondary-indeterminate-translate-reverse {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
            animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  25% {
    -webkit-animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
            animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
    -webkit-transform: translateX(-37.65191%);
            transform: translateX(-37.65191%); }
  48.35% {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
            animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
    -webkit-transform: translateX(-84.38617%);
            transform: translateX(-84.38617%); }
  100% {
    -webkit-transform: translateX(-160.27778%);
            transform: translateX(-160.27778%); } }

@-webkit-keyframes buffering-reverse {
  to {
    -webkit-transform: translateX(10px);
            transform: translateX(10px); } }

@keyframes buffering-reverse {
  to {
    -webkit-transform: translateX(10px);
            transform: translateX(10px); } }

.mdc-linear-progress {
  position: relative;
  width: 100%;
  height: 4px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-transition: opacity 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  overflow: hidden; }
  .mdc-linear-progress__bar {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-animation: none;
            animation: none;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transition: -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    transition: -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1); }
  .mdc-linear-progress__bar-inner {
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-animation: none;
            animation: none; }
  .mdc-linear-progress__buffering-dots {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-animation: buffering 250ms infinite linear;
            animation: buffering 250ms infinite linear;
    background-repeat: repeat-x;
    background-size: 10px 4px; }
  .mdc-linear-progress__buffer {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transition: -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    transition: -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1), -webkit-transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1); }
  .mdc-linear-progress__primary-bar {
    -webkit-transform: scaleX(0);
            transform: scaleX(0); }
  .mdc-linear-progress__secondary-bar {
    visibility: hidden; }
  .mdc-linear-progress--indeterminate .mdc-linear-progress__bar {
    -webkit-transition: none;
    transition: none; }
  .mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar {
    left: -145.166611%;
    -webkit-animation: primary-indeterminate-translate 2s infinite linear;
            animation: primary-indeterminate-translate 2s infinite linear; }
    .mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar > .mdc-linear-progress__bar-inner {
      -webkit-animation: primary-indeterminate-scale 2s infinite linear;
              animation: primary-indeterminate-scale 2s infinite linear; }
  .mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar {
    left: -54.888891%;
    -webkit-animation: secondary-indeterminate-translate 2s infinite linear;
            animation: secondary-indeterminate-translate 2s infinite linear;
    visibility: visible; }
    .mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar > .mdc-linear-progress__bar-inner {
      -webkit-animation: secondary-indeterminate-scale 2s infinite linear;
              animation: secondary-indeterminate-scale 2s infinite linear; }
  .mdc-linear-progress--reversed .mdc-linear-progress__bar,
  .mdc-linear-progress--reversed .mdc-linear-progress__buffer {
    right: 0;
    -webkit-transform-origin: center right;
            transform-origin: center right; }
  .mdc-linear-progress--reversed .mdc-linear-progress__primary-bar {
    -webkit-animation-name: primary-indeterminate-translate-reverse;
            animation-name: primary-indeterminate-translate-reverse; }
  .mdc-linear-progress--reversed .mdc-linear-progress__secondary-bar {
    -webkit-animation-name: secondary-indeterminate-translate-reverse;
            animation-name: secondary-indeterminate-translate-reverse; }
  .mdc-linear-progress--reversed .mdc-linear-progress__buffering-dots {
    -webkit-animation: buffering-reverse 250ms infinite linear;
            animation: buffering-reverse 250ms infinite linear; }
  .mdc-linear-progress--closed {
    opacity: 0; }

.mdc-linear-progress__bar-inner {
  background-color: #FAD000;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #FAD000); }

.mdc-linear-progress__buffering-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='%23e6e6e6'/%3E%3C/svg%3E"); }

.mdc-linear-progress__buffer {
  background-color: #e6e6e6; }

.mdc-linear-progress--indeterminate.mdc-linear-progress--reversed .mdc-linear-progress__primary-bar {
  right: -145.166611%;
  left: auto; }

.mdc-linear-progress--indeterminate.mdc-linear-progress--reversed .mdc-linear-progress__secondary-bar {
  right: -54.888891%;
  left: auto; }

/*------------------------------------*\
    $ICONS
\*------------------------------------*/
/**
 * 1. Enables the SVG to accept the color attribute of the parent so it works with text.
 * https://css-tricks.com/cascading-svg-fill-color/
 */
.icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
  /* [1] */ }

/*------------------------------------*\
    #BUTTON
\*------------------------------------*/
.button-secondary:not(:disabled) {
  color: #EDC7A2; }

.button-secondary::before, .button-secondary::after {
  background-color: #EDC7A2; }

.button-secondary:hover::before {
  opacity: 0.08; }

.button-secondary:not(.mdc-ripple-upgraded):focus::before, .button-secondary.mdc-ripple-upgraded--background-focused::before {
  -webkit-transition-duration: 75ms;
          transition-duration: 75ms;
  opacity: 0.24; }

.button-secondary:not(.mdc-ripple-upgraded)::after {
  -webkit-transition: opacity 150ms linear;
  transition: opacity 150ms linear; }

.button-secondary:not(.mdc-ripple-upgraded):active::after {
  -webkit-transition-duration: 75ms;
          transition-duration: 75ms;
  opacity: 0.32; }

.button-secondary.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.32; }

.button-secondary-filled:not(:disabled) {
  background-color: #EDC7A2; }

.button-secondary-filled:not(:disabled) {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); }

.button-secondary-filled::before, .button-secondary-filled::after {
  background-color: rgba(0, 0, 0, 0.87); }
  @supports not (-ms-ime-align: auto) {
    .button-secondary-filled::before, .button-secondary-filled::after {
      /* @alternate */
      background-color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); } }

.button-secondary-filled:hover::before {
  opacity: 0.04; }

.button-secondary-filled:not(.mdc-ripple-upgraded):focus::before, .button-secondary-filled.mdc-ripple-upgraded--background-focused::before {
  -webkit-transition-duration: 75ms;
          transition-duration: 75ms;
  opacity: 0.12; }

.button-secondary-filled:not(.mdc-ripple-upgraded)::after {
  -webkit-transition: opacity 150ms linear;
  transition: opacity 150ms linear; }

.button-secondary-filled:not(.mdc-ripple-upgraded):active::after {
  -webkit-transition-duration: 75ms;
          transition-duration: 75ms;
  opacity: 0.16; }

.button-secondary-filled.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.16; }

.button-tertiary-filled:not(:disabled) {
  background-color: rgba(0, 0, 0, 0.08); }

.button-tertiary-filled:not(:disabled) {
  color: #FAD000;
  /* @alternate */
  color: var(--mdc-theme-primary, #FAD000); }

.button-tertiary-filled::before, .button-tertiary-filled::after {
  background-color: #FAD000; }
  @supports not (-ms-ime-align: auto) {
    .button-tertiary-filled::before, .button-tertiary-filled::after {
      /* @alternate */
      background-color: var(--mdc-theme-primary, #FAD000); } }

.button-tertiary-filled:hover::before {
  opacity: 0.08; }

.button-tertiary-filled:not(.mdc-ripple-upgraded):focus::before, .button-tertiary-filled.mdc-ripple-upgraded--background-focused::before {
  -webkit-transition-duration: 75ms;
          transition-duration: 75ms;
  opacity: 0.24; }

.button-tertiary-filled:not(.mdc-ripple-upgraded)::after {
  -webkit-transition: opacity 150ms linear;
  transition: opacity 150ms linear; }

.button-tertiary-filled:not(.mdc-ripple-upgraded):active::after {
  -webkit-transition-duration: 75ms;
          transition-duration: 75ms;
  opacity: 0.32; }

.button-tertiary-filled.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.32; }

/*------------------------------------*\
	#LOADER
\*------------------------------------*/
.loader {
  -webkit-animation: rotate 2s linear;
          animation: rotate 2s linear;
  height: 50px;
  width: 50px;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s; }

.loader--small {
  -webkit-animation: rotate 2s linear;
          animation: rotate 2s linear;
  height: 36px;
  width: 36px; }

.loader__path {
  stroke: #FAD000;
  /* @alternate */
  stroke: var(--mdc-theme-primary, #FAD000);
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-dasharray: 1,200;
  stroke-dashoffset: 0;
  stroke-linecap: round; }
  .loader--small .loader__path {
    -webkit-animation: dash-small 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
            animation: dash-small 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-dasharray: 1,200; }

.loader__path--on-dark {
  stroke: rgba(255, 255, 255, 0.7);
  /* @alternate */
  stroke: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)); }

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes dash-small {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -20; }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -60; } }

@keyframes dash-small {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -20; }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -60; } }

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35; }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124; } }

@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35; }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124; } }

.tray {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 150;
  pointer-events: none; }

.tray--visible .tray__content {
  pointer-events: auto; }

.tray__content {
  position: absolute;
  width: 100%;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  z-index: 160; }

.tray__content--animatable {
  will-change: transform;
  -webkit-transition: all 150ms ease-out;
  transition: all 150ms ease-out; }

.tray__content--animate-down {
  -webkit-transform: translateY(calc(100% + 8px));
          transform: translateY(calc(100% + 8px)); }

.tray--scrim {
  pointer-events: auto; }

.tray__scrim {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); }

.tray__scrim--animate-fadeout {
  -webkit-animation: TrayScrimFadeOut 0.15s ease-out;
          animation: TrayScrimFadeOut 0.15s ease-out; }

.tray__scrim--animate-fadein {
  -webkit-animation: TrayScrimFadeIn 0.15s ease-out;
          animation: TrayScrimFadeIn 0.15s ease-out; }

@-webkit-keyframes TrayScrimFadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 100; } }

@keyframes TrayScrimFadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 100; } }

@-webkit-keyframes TrayScrimFadeOut {
  from {
    opacity: 100; }
  to {
    opacity: 0; } }

@keyframes TrayScrimFadeOut {
  from {
    opacity: 100; }
  to {
    opacity: 0; } }

::-webkit-scrollbar {
  width: 0px; }

::-webkit-scrollbar-thumb {
  background-color: #FAD000;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #FAD000);
  border: 2px solid;
  border-color: #FAD000;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #FAD000);
  min-height: 50px; }

::-webkit-scrollbar-track {
  background-color: #FAD000;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #FAD000); }

body {
  scrollbar-face-color: #FAD000;
  scrollbar-track-color: #FAD000; }

.homepage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 3;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100vh; }
  @media screen and (max-width: 64em) {
    .homepage {
      overflow-y: hidden; } }
  .homepage__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline; }
    @media screen and (max-width: 64em) {
      .homepage__content {
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; } }
  .homepage__header {
    color: rgba(0, 0, 0, 0.87);
    /* @alternate */
    color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
    font-size: 8rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 48px; }
    @media screen and (max-width: 22.438em) {
      .homepage__header {
        height: 100vh;
        margin: 0;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 40px 0px 20px 0px;
        font-size: 7.5rem;
        word-break: break-all;
        text-align: left;
        line-height: 0.8; } }
    @media screen and (max-width: 64em) {
      .homepage__header {
        height: 100vh;
        width: 100vw;
        margin: 0;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 80px 10px 40px 10px;
        font-size: 8rem;
        word-break: break-all;
        text-align: left;
        line-height: 0.8; } }
    @media screen and (max-width: 22.438em) {
      .homepage__header {
        font-size: 5rem; } }
    @media screen and (min-width: 48em) {
      .homepage__header {
        font-size: 12rem; } }
    .homepage__header sup {
      font-size: 2rem; }
  .homepage__menu {
    color: rgba(0, 0, 0, 0.87);
    /* @alternate */
    color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    font-size: 8rem;
    font-weight: 900;
    text-transform: uppercase; }
    @media screen and (min-width: 48em) {
      .homepage__menu {
        font-size: 12rem; } }
    .homepage__menu-item {
      position: relative;
      z-index: 9;
      margin: 0 20px;
      text-decoration: none;
      cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" style="font-size: 32px"><text y="28">🍟</text></svg>'), auto; }
      .homepage__menu-item a {
        text-decoration: none; }
      .homepage__menu-item:hover {
        color: #FF4438;
        /* @alternate */
        color: var(--mdc-theme-surface, #FF4438); }
      @media screen and (max-width: 64em) {
        .homepage__menu-item {
          margin: 0;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
          padding: 20px;
          font-size: 8rem;
          text-align: left;
          line-height: 0.75em; } }

.order-link {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); }
  .order-link:hover {
    color: #FF4438;
    /* @alternate */
    color: var(--mdc-theme-surface, #FF4438); }

.hoodburger-smile {
  width: 240px;
  height: 240px;
  position: sticky;
  position: -webkit-sticky;
  left: 20vw;
  -webkit-transform: translate(0, 5%);
          transform: translate(0, 5%); }
  @media screen and (max-width: 64em) {
    .hoodburger-smile {
      width: 125px;
      height: 125px;
      margin-bottom: -100px; } }

.hoodburger-text-logo {
  width: 400px;
  height: auto;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-left: 0;
  margin-right: auto;
  fill: #FF4438;
  /* @alternate */
  fill: var(--mdc-theme-surface, #FF4438); }

.good-munchin {
  width: 860px;
  margin: 24px;
  height: auto; }
  .good-munchin .outline {
    fill: #FF4438;
    /* @alternate */
    fill: var(--mdc-theme-surface, #FF4438); }
  .good-munchin .good {
    fill: #FF4438;
    /* @alternate */
    fill: var(--mdc-theme-surface, #FF4438); }
  .good-munchin .munchin {
    fill: #FF4438;
    /* @alternate */
    fill: var(--mdc-theme-surface, #FF4438); }
  @media screen and (max-width: 64em) {
    .good-munchin {
      width: auto;
      height: 50vw;
      margin-left: 40px;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); } }

.location-postcode {
  fill: #FF4438;
  /* @alternate */
  fill: var(--mdc-theme-surface, #FF4438);
  width: 200px;
  height: auto;
  z-index: 9;
  position: sticky;
  position: -webkit-sticky; }
  .location-postcode--top {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    left: 200px; }
    @media screen and (max-width: 64em) {
      .location-postcode--top {
        left: 50px; } }
  .location-postcode--bottom {
    -webkit-transform: translate(180%, 0);
            transform: translate(180%, 0);
    right: 200px; }
    @media screen and (max-width: 64em) {
      .location-postcode--bottom {
        -webkit-transform: translate(80%, 0);
                transform: translate(80%, 0);
        right: 200px; } }
    @media screen and (min-width: 48em) {
      .location-postcode--bottom {
        -webkit-transform: translate(300%, 0);
                transform: translate(300%, 0); } }
  .location-postcode:hover {
    color: rgba(0, 0, 0, 0.87);
    /* @alternate */
    color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); }

.negative-ml {
  margin-left: -120px; }

.header__logo:hover,
.good-munchin:hover {
  -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
          animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px; }

@-webkit-keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0); }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0); } }

@keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0); }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0); } }

/* MDC */
/* Message Feedback */
/* Scroll Bars */
html {
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" style="font-size: 120px"><text y="120">👉🏻</text></svg>'), auto; }

a {
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" style="font-size: 32px"><text y="28">🍔</text></svg>'), auto; }

input {
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" style="font-size: 32px"><text y="28">🍦</text></svg>'), auto; }

.theme-switcher {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-top: -100px;
  display: none; }
  .theme-switcher .selection {
    margin-bottom: 1rem;
    margin-right: 1rem; }
    .theme-switcher .selection label {
      color: #EDC7A2;
      /* @alternate */
      color: var(--mdc-theme-secondary, #EDC7A2);
      display: inline-block;
      font-size: 0em;
      padding: 1rem;
      cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" style="font-size: 32px"><text y="28">🍦</text></svg>'), auto; }
      .theme-switcher .selection label.yellow {
        background-color: #FAD000; }
        .theme-switcher .selection label.yellow:hover {
          background-color: #FF4438;
          /* @alternate */
          background-color: var(--mdc-theme-surface, #FF4438); }
      .theme-switcher .selection label.beige {
        background-color: #EDC7A2; }
        .theme-switcher .selection label.beige:hover {
          background-color: #FF4438;
          /* @alternate */
          background-color: var(--mdc-theme-surface, #FF4438); }
      .theme-switcher .selection label.red {
        background-color: #FF4438; }
        .theme-switcher .selection label.red:hover {
          background-color: #FF4438;
          /* @alternate */
          background-color: var(--mdc-theme-surface, #FF4438); }
      .theme-switcher .selection label.black {
        background-color: #212721; }
        .theme-switcher .selection label.black:hover {
          background-color: #FF4438;
          /* @alternate */
          background-color: var(--mdc-theme-surface, #FF4438); }
    .theme-switcher .selection input[type=radio] {
      display: none; }
    .theme-switcher .selection input[type=radio]:checked ~ label:not(:disabled) {
      color: #FAD000; }
    .theme-switcher .selection input[type=radio]:checked ~ label::before, .theme-switcher .selection input[type=radio]:checked ~ label::after {
      background-color: #FAD000; }
    .theme-switcher .selection input[type=radio]:checked ~ label:hover::before {
      opacity: 0.08; }
    .theme-switcher .selection input[type=radio]:checked ~ label:not(.mdc-ripple-upgraded):focus::before, .theme-switcher .selection input[type=radio]:checked ~ label.mdc-ripple-upgraded--background-focused::before {
      -webkit-transition-duration: 75ms;
              transition-duration: 75ms;
      opacity: 0.24; }
    .theme-switcher .selection input[type=radio]:checked ~ label:not(.mdc-ripple-upgraded)::after {
      -webkit-transition: opacity 150ms linear;
      transition: opacity 150ms linear; }
    .theme-switcher .selection input[type=radio]:checked ~ label:not(.mdc-ripple-upgraded):active::after {
      -webkit-transition-duration: 75ms;
              transition-duration: 75ms;
      opacity: 0.32; }
    .theme-switcher .selection input[type=radio]:checked ~ label.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.32; }

.section {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  top: 0;
  left: 0;
  height: 100vh;
  padding: 20px 30px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 9;
  width: 100vw;
  overflow-x: hidden;
  will-change: transform;
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0, 1.15, 0.5, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0, 1.15, 0.5, 1);
  transition: transform 0.5s cubic-bezier(0, 1.15, 0.5, 1);
  transition: transform 0.5s cubic-bezier(0, 1.15, 0.5, 1), -webkit-transform 0.5s cubic-bezier(0, 1.15, 0.5, 1); }
  @media screen and (max-width: 64em) {
    .section {
      position: fixed; } }
  .section--header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    top: 5px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    z-index: 99; }
  .section--content {
    position: absolute;
    width: 90vw;
    padding: 0 20px;
    top: 250px;
    z-index: 9; }
    @media screen and (max-width: 64em) {
      .section--content {
        width: 85vw;
        padding: 0;
        top: 100px; } }
    .section--content--contact, .section--content--jobs {
      top: 150px; }
      @media screen and (max-width: 64em) {
        .section--content--contact, .section--content--jobs {
          top: 100px; } }
  .section--image {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    z-index: 2; }
    .section--image .image--top {
      position: absolute;
      top: 30%;
      left: 0;
      height: 75%;
      width: auto; }
    .section--image .image--bottom {
      position: absolute;
      top: 10%;
      right: 10%; }
  .section--img {
    border: 15px solid white; }
  .section__about, .section__menu, .section__location, .section__contact, .section__socials, .section__jobs {
    background-color: #EDC7A2;
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #EDC7A2);
    -webkit-transform: translate(0, -110%);
            transform: translate(0, -110%);
    -webkit-transition: -webkit-transform 0.5s cubic-bezier(0, 1.15, 0.5, 1);
    transition: -webkit-transform 0.5s cubic-bezier(0, 1.15, 0.5, 1);
    transition: transform 0.5s cubic-bezier(0, 1.15, 0.5, 1);
    transition: transform 0.5s cubic-bezier(0, 1.15, 0.5, 1), -webkit-transform 0.5s cubic-bezier(0, 1.15, 0.5, 1); }
    .section__about h1, .section__menu h1, .section__location h1, .section__contact h1, .section__socials h1, .section__jobs h1 {
      color: #FF4438;
      /* @alternate */
      color: var(--mdc-theme-surface, #FF4438);
      font-size: 4rem;
      font-weight: 900;
      text-transform: uppercase;
      margin-bottom: 0;
      margin-top: 0; }
      @media screen and (min-width: 48em) {
        .section__about h1, .section__menu h1, .section__location h1, .section__contact h1, .section__socials h1, .section__jobs h1 {
          font-size: 12rem; } }
    .section__about p, .section__menu p, .section__location p, .section__contact p, .section__socials p, .section__jobs p {
      color: #FF4438;
      /* @alternate */
      color: var(--mdc-theme-surface, #FF4438);
      font-size: 1.2rem;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 0; }
      @media screen and (max-width: 64em) {
        .section__about p, .section__menu p, .section__location p, .section__contact p, .section__socials p, .section__jobs p {
          font-size: 1.75rem; } }
      @media screen and (min-width: 48em) {
        .section__about p, .section__menu p, .section__location p, .section__contact p, .section__socials p, .section__jobs p {
          font-size: 2.4rem; } }
      .section__about p .section--close, .section__menu p .section--close, .section__location p .section--close, .section__contact p .section--close, .section__socials p .section--close, .section__jobs p .section--close {
        color: #FF4438;
        /* @alternate */
        color: var(--mdc-theme-surface, #FF4438); }
    .section__about.is-visible, .section__menu.is-visible, .section__location.is-visible, .section__contact.is-visible, .section__socials.is-visible, .section__jobs.is-visible {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0); }
  .section__location {
    background-color: #FF4438;
    /* @alternate */
    background-color: var(--mdc-theme-surface, #FF4438); }
    .section__location h1 {
      color: #FAD000;
      /* @alternate */
      color: var(--mdc-theme-primary, #FAD000);
      text-align: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
    .section__location a {
      color: #FAD000;
      /* @alternate */
      color: var(--mdc-theme-primary, #FAD000);
      text-align: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      .section__location a .location--large {
        font-size: 2.5em;
        font-weight: 900;
        margin-top: 30px; }
      .section__location a .location--small {
        font-size: 1.25em; }
    .section__location .location-section--close {
      fill: #FAD000;
      /* @alternate */
      fill: var(--mdc-theme-primary, #FAD000); }
    .section__location .hoodburger-text-logo {
      fill: #FAD000;
      /* @alternate */
      fill: var(--mdc-theme-primary, #FAD000); }
    .section__location .northbridge--image {
      left: 5%;
      top: 150px;
      width: 400px; }
      .section__location .northbridge--image img {
        width: 100%; }
    .section__location .inglewood--image {
      left: 60%;
      top: 400px;
      width: 500px; }
      .section__location .inglewood--image img {
        width: 100%; }
  .section__contact h1 {
    text-align: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .section__socials {
    background-color: #212721;
    /* @alternate */
    background-color: var(--mdc-theme-background, #212721); }
    .section__socials h1 {
      color: #FAD000;
      /* @alternate */
      color: var(--mdc-theme-primary, #FAD000);
      text-align: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
    .section__socials p {
      color: #fff;
      /* @alternate */
      color: var(--mdc-theme-on-surface, #fff); }
    .section__socials a {
      color: #FAD000;
      /* @alternate */
      color: var(--mdc-theme-primary, #FAD000);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      text-align: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      margin-top: 30px; }
      .section__socials a .socials--large {
        font-size: 2.5em;
        font-weight: 900; }
      .section__socials a .image--socials {
        margin-right: 10px;
        width: 3rem;
        height: 3rem; }
      .section__socials a:hover {
        color: #FAD000;
        /* @alternate */
        color: var(--mdc-theme-primary, #FAD000); }
    .section__socials .section--close {
      color: #fff;
      /* @alternate */
      color: var(--mdc-theme-on-surface, #fff);
      fill: #fff;
      /* @alternate */
      fill: var(--mdc-theme-on-surface, #fff); }
    .section__socials .hoodburger-text-logo {
      fill: #fff;
      /* @alternate */
      fill: var(--mdc-theme-on-surface, #fff); }
  .section__jobs, .section__menu {
    background-color: #fff;
    /* @alternate */
    background-color: var(--mdc-theme-on-surface, #fff); }
    .section__jobs h1, .section__menu h1 {
      color: #212721;
      /* @alternate */
      color: var(--mdc-theme-background, #212721);
      text-align: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
    .section__jobs p, .section__menu p {
      color: #212721;
      /* @alternate */
      color: var(--mdc-theme-background, #212721); }
    .section__jobs .section--close, .section__menu .section--close {
      color: #212721;
      /* @alternate */
      color: var(--mdc-theme-background, #212721); }
    .section__jobs .hoodburger-text-logo, .section__menu .hoodburger-text-logo {
      fill: #212721;
      /* @alternate */
      fill: var(--mdc-theme-background, #212721); }
  .section--close {
    width: 60px;
    height: 60px;
    -ms-flex-item-align: end;
        align-self: flex-end;
    margin-left: auto;
    margin-right: 0;
    fill: #FF4438;
    /* @alternate */
    fill: var(--mdc-theme-surface, #FF4438); }
    @media screen and (min-width: 48em) {
      .section--close {
        width: 120px;
        height: 120px; } }
    .section--close:hover {
      color: #FAD000;
      /* @alternate */
      color: var(--mdc-theme-primary, #FAD000);
      cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" style="font-size: 32px"><text y="28">🍟</text></svg>'), auto; }

.menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 8px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .menu__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: calc(95% - 24px);
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 32px; }
    .menu__item:nth-of-type(odd) {
      margin-left: 48px; }
      @media screen and (min-width: 48em) {
        .menu__item:nth-of-type(odd) {
          margin-top: 40px; } }
    @media screen and (min-width: 48em) {
      .menu__item {
        width: calc(45% - 24px); } }
    .menu__item:nth-child(3n+2) .menu__image {
      background-color: #FAD000;
      /* @alternate */
      background-color: var(--mdc-theme-primary, #FAD000); }
    .menu__item:nth-child(3n+0) .menu__image {
      background-color: #EDC7A2;
      /* @alternate */
      background-color: var(--mdc-theme-secondary, #EDC7A2); }
  .menu__image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background-color: #FF4438;
    /* @alternate */
    background-color: var(--mdc-theme-surface, #FF4438);
    overflow: hidden;
    margin-bottom: 24px; }
    .menu__image-img {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      min-width: 300px; }
      @media screen and (min-width: 48em) {
        .menu__image-img {
          min-width: 500px; } }
  .menu__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 2rem;
    font-weight: 900;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    text-transform: uppercase; }
  .menu__description {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: 600;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    text-transform: uppercase; }

.counter {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-top: 20px;
  margin-left: -20px; }
  @media screen and (max-width: 64em) {
    .counter {
      width: 100%;
      margin-left: -30px;
      margin-top: 60px;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; } }
  @media screen and (min-width: 48em) {
    .counter {
      width: 100%;
      margin-left: 0;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; } }
  .counter-header {
    font-family: 'M PLUS Rounded 1c', sans-serif;
    position: absolute;
    width: 100%;
    margin: 0;
    font-size: 1.4rem;
    font-weight: 900;
    color: #FF4438;
    text-transform: uppercase;
    text-align: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media screen and (max-width: 64em) {
      .counter-header {
        font-size: 2rem;
        margin-top: 110px; } }
    @media screen and (min-width: 48em) {
      .counter-header {
        font-size: 4rem;
        margin-top: 120px; } }

.digit {
  width: 60px;
  height: 110px;
  display: inline-block;
  position: relative; }
  .digit:nth-child(n + 2) {
    margin-left: 16px; }
  @media screen and (max-width: 64em) {
    .digit {
      width: 45px;
      height: 55px; }
      .digit:nth-child(n + 2) {
        margin-left: 6px; } }
  @media screen and (min-width: 48em) {
    .digit {
      width: 73.33333px; }
      .digit:nth-child(n + 2) {
        margin-left: 25px; } }

[class^="card"] {
  width: 100%;
  height: 100%;
  position: absolute;
  border-width: 1px 0;
  -webkit-perspective: 300px;
          perspective: 300px; }
  @media screen and (min-width: 48em) {
    [class^="card"] {
      -webkit-perspective: 500px;
              perspective: 500px; } }
  [class^="card"]:before, [class^="card"]:after {
    content: attr(data-content);
    width: 100%;
    height: 50%;
    position: absolute;
    border: 0px solid rgba(101, 29, 24, 0.5);
    overflow: hidden;
    border-radius: 10px;
    color: white;
    font-size: 90px;
    font-weight: bold;
    line-height: 110px;
    text-align: center; }
    @media screen and (max-width: 64em) {
      [class^="card"]:before, [class^="card"]:after {
        font-size: 45px;
        line-height: 55px; } }
    @media screen and (min-width: 48em) {
      [class^="card"]:before, [class^="card"]:after {
        font-size: 110px;
        font-weight: bold;
        line-height: 110px; } }
  [class^="card"]:before {
    border-top-color: rgba(101, 29, 24, 0.25);
    border-width: 2px 0 2px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(118, 31, 25, 0.025);
            box-shadow: 0px 0px 10px 0px rgba(118, 31, 25, 0.025);
    background-color: #FF4438;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom; }
  [class^="card"]:after {
    margin-top: 55px;
    border-top-width: 2px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(118, 31, 25, 0.025);
            box-shadow: 0px 0px 10px 0px rgba(118, 31, 25, 0.025);
    background-color: #FF4438;
    line-height: 0;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top; }
    @media screen and (max-width: 64em) {
      [class^="card"]:after {
        margin-top: 27.5px; } }

.digit:nth-child(1) .card0 {
  -webkit-animation: card10 4782969s step-end infinite;
          animation: card10 4782969s step-end infinite; }
  .digit:nth-child(1) .card0:before, .digit:nth-child(1) .card0:after {
    -webkit-animation: 4782969s linear infinite;
            animation: 4782969s linear infinite; }
  .digit:nth-child(1) .card0:before {
    -webkit-animation-name: top10;
            animation-name: top10; }
  .digit:nth-child(1) .card0:after {
    -webkit-animation-name: bottom10;
            animation-name: bottom10; }

@-webkit-keyframes card10 {
  100.0% {
    z-index: 2; }
  0% {
    z-index: 2; }
  11.11111% {
    z-index: 1; }
  11.11111% {
    z-index: 0; } }

@keyframes card10 {
  100.0% {
    z-index: 2; }
  0% {
    z-index: 2; }
  11.11111% {
    z-index: 1; }
  11.11111% {
    z-index: 0; } }

@-webkit-keyframes top10 {
  0%, 11.1111% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  11.11111% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  99.99999% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top10 {
  0%, 11.1111% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  11.11111% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  99.99999% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom11 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  11.1111%, 11.11111% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  11.11111%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom11 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  11.1111%, 11.11111% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  11.11111%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(1) .card1 {
  -webkit-animation: card11 4782969s step-end infinite;
          animation: card11 4782969s step-end infinite; }
  .digit:nth-child(1) .card1:before, .digit:nth-child(1) .card1:after {
    -webkit-animation: 4782969s linear infinite;
            animation: 4782969s linear infinite; }
  .digit:nth-child(1) .card1:before {
    -webkit-animation-name: top11;
            animation-name: top11; }
  .digit:nth-child(1) .card1:after {
    -webkit-animation-name: bottom11;
            animation-name: bottom11; }

@-webkit-keyframes card11 {
  11.11111% {
    z-index: 2; }
  22.22222% {
    z-index: 1; }
  22.22222% {
    z-index: 0; } }

@keyframes card11 {
  11.11111% {
    z-index: 2; }
  22.22222% {
    z-index: 1; }
  22.22222% {
    z-index: 0; } }

@-webkit-keyframes top11 {
  0%, 22.22222% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  22.22222% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top11 {
  0%, 22.22222% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  22.22222% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom12 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  22.22222%, 22.22222% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  22.22222%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom12 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  22.22222%, 22.22222% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  22.22222%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(1) .card2 {
  -webkit-animation: card12 4782969s step-end infinite;
          animation: card12 4782969s step-end infinite; }
  .digit:nth-child(1) .card2:before, .digit:nth-child(1) .card2:after {
    -webkit-animation: 4782969s linear infinite;
            animation: 4782969s linear infinite; }
  .digit:nth-child(1) .card2:before {
    -webkit-animation-name: top12;
            animation-name: top12; }
  .digit:nth-child(1) .card2:after {
    -webkit-animation-name: bottom12;
            animation-name: bottom12; }

@-webkit-keyframes card12 {
  22.22222% {
    z-index: 2; }
  33.33333% {
    z-index: 1; }
  33.33333% {
    z-index: 0; } }

@keyframes card12 {
  22.22222% {
    z-index: 2; }
  33.33333% {
    z-index: 1; }
  33.33333% {
    z-index: 0; } }

@-webkit-keyframes top12 {
  0%, 33.33333% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  33.33333% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top12 {
  0%, 33.33333% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  33.33333% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom13 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  33.33333%, 33.33333% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  33.33333%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom13 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  33.33333%, 33.33333% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  33.33333%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(1) .card3 {
  -webkit-animation: card13 4782969s step-end infinite;
          animation: card13 4782969s step-end infinite; }
  .digit:nth-child(1) .card3:before, .digit:nth-child(1) .card3:after {
    -webkit-animation: 4782969s linear infinite;
            animation: 4782969s linear infinite; }
  .digit:nth-child(1) .card3:before {
    -webkit-animation-name: top13;
            animation-name: top13; }
  .digit:nth-child(1) .card3:after {
    -webkit-animation-name: bottom13;
            animation-name: bottom13; }

@-webkit-keyframes card13 {
  33.33333% {
    z-index: 2; }
  44.44444% {
    z-index: 1; }
  44.44444% {
    z-index: 0; } }

@keyframes card13 {
  33.33333% {
    z-index: 2; }
  44.44444% {
    z-index: 1; }
  44.44444% {
    z-index: 0; } }

@-webkit-keyframes top13 {
  0%, 44.44444% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.44444% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top13 {
  0%, 44.44444% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.44444% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom14 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.44444%, 44.44444% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  44.44444%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom14 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.44444%, 44.44444% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  44.44444%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(1) .card4 {
  -webkit-animation: card14 4782969s step-end infinite;
          animation: card14 4782969s step-end infinite; }
  .digit:nth-child(1) .card4:before, .digit:nth-child(1) .card4:after {
    -webkit-animation: 4782969s linear infinite;
            animation: 4782969s linear infinite; }
  .digit:nth-child(1) .card4:before {
    -webkit-animation-name: top14;
            animation-name: top14; }
  .digit:nth-child(1) .card4:after {
    -webkit-animation-name: bottom14;
            animation-name: bottom14; }

@-webkit-keyframes card14 {
  44.44444% {
    z-index: 2; }
  55.55555% {
    z-index: 1; }
  55.55556% {
    z-index: 0; } }

@keyframes card14 {
  44.44444% {
    z-index: 2; }
  55.55555% {
    z-index: 1; }
  55.55556% {
    z-index: 0; } }

@-webkit-keyframes top14 {
  0%, 55.55555% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.55555% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top14 {
  0%, 55.55555% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.55555% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom15 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.55555%, 55.55555% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  55.55556%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom15 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.55555%, 55.55555% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  55.55556%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(1) .card5 {
  -webkit-animation: card15 4782969s step-end infinite;
          animation: card15 4782969s step-end infinite; }
  .digit:nth-child(1) .card5:before, .digit:nth-child(1) .card5:after {
    -webkit-animation: 4782969s linear infinite;
            animation: 4782969s linear infinite; }
  .digit:nth-child(1) .card5:before {
    -webkit-animation-name: top15;
            animation-name: top15; }
  .digit:nth-child(1) .card5:after {
    -webkit-animation-name: bottom15;
            animation-name: bottom15; }

@-webkit-keyframes card15 {
  55.55555% {
    z-index: 2; }
  66.66666% {
    z-index: 1; }
  66.66667% {
    z-index: 0; } }

@keyframes card15 {
  55.55555% {
    z-index: 2; }
  66.66666% {
    z-index: 1; }
  66.66667% {
    z-index: 0; } }

@-webkit-keyframes top15 {
  0%, 66.66666% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.66666% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top15 {
  0%, 66.66666% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.66666% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom16 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.66666%, 66.66666% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  66.66667%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom16 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.66666%, 66.66666% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  66.66667%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(1) .card6 {
  -webkit-animation: card16 4782969s step-end infinite;
          animation: card16 4782969s step-end infinite; }
  .digit:nth-child(1) .card6:before, .digit:nth-child(1) .card6:after {
    -webkit-animation: 4782969s linear infinite;
            animation: 4782969s linear infinite; }
  .digit:nth-child(1) .card6:before {
    -webkit-animation-name: top16;
            animation-name: top16; }
  .digit:nth-child(1) .card6:after {
    -webkit-animation-name: bottom16;
            animation-name: bottom16; }

@-webkit-keyframes card16 {
  66.66666% {
    z-index: 2; }
  77.77777% {
    z-index: 1; }
  77.77778% {
    z-index: 0; } }

@keyframes card16 {
  66.66666% {
    z-index: 2; }
  77.77777% {
    z-index: 1; }
  77.77778% {
    z-index: 0; } }

@-webkit-keyframes top16 {
  0%, 77.77777% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.77777% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top16 {
  0%, 77.77777% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.77777% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom17 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.77777%, 77.77777% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  77.77778%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom17 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.77777%, 77.77777% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  77.77778%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(1) .card7 {
  -webkit-animation: card17 4782969s step-end infinite;
          animation: card17 4782969s step-end infinite; }
  .digit:nth-child(1) .card7:before, .digit:nth-child(1) .card7:after {
    -webkit-animation: 4782969s linear infinite;
            animation: 4782969s linear infinite; }
  .digit:nth-child(1) .card7:before {
    -webkit-animation-name: top17;
            animation-name: top17; }
  .digit:nth-child(1) .card7:after {
    -webkit-animation-name: bottom17;
            animation-name: bottom17; }

@-webkit-keyframes card17 {
  77.77777% {
    z-index: 2; }
  88.88889% {
    z-index: 1; }
  88.88889% {
    z-index: 0; } }

@keyframes card17 {
  77.77777% {
    z-index: 2; }
  88.88889% {
    z-index: 1; }
  88.88889% {
    z-index: 0; } }

@-webkit-keyframes top17 {
  0%, 88.88888% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.88889% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top17 {
  0%, 88.88888% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.88889% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom18 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.88888%, 88.88889% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  88.88889%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom18 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.88888%, 88.88889% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  88.88889%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(1) .card8 {
  -webkit-animation: card18 4782969s step-end infinite;
          animation: card18 4782969s step-end infinite; }
  .digit:nth-child(1) .card8:before, .digit:nth-child(1) .card8:after {
    -webkit-animation: 4782969s linear infinite;
            animation: 4782969s linear infinite; }
  .digit:nth-child(1) .card8:before {
    -webkit-animation-name: top18;
            animation-name: top18; }
  .digit:nth-child(1) .card8:after {
    -webkit-animation-name: bottom18;
            animation-name: bottom18; }

@-webkit-keyframes card18 {
  88.88889% {
    z-index: 2; }
  100.0% {
    z-index: 1; }
  100% {
    z-index: 0; } }

@keyframes card18 {
  88.88889% {
    z-index: 2; }
  100.0% {
    z-index: 1; }
  100% {
    z-index: 0; } }

@-webkit-keyframes top18 {
  0%, 99.99999% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  100.0% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top18 {
  0%, 99.99999% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  100.0% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom10 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  99.99999%, 100.0% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  100%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom10 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  99.99999%, 100.0% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  100%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(2) .card0 {
  -webkit-animation: card20 531441s step-end infinite;
          animation: card20 531441s step-end infinite; }
  .digit:nth-child(2) .card0:before, .digit:nth-child(2) .card0:after {
    -webkit-animation: 531441s linear infinite;
            animation: 531441s linear infinite; }
  .digit:nth-child(2) .card0:before {
    -webkit-animation-name: top20;
            animation-name: top20; }
  .digit:nth-child(2) .card0:after {
    -webkit-animation-name: bottom20;
            animation-name: bottom20; }

@-webkit-keyframes card20 {
  99.99997% {
    z-index: 2; }
  0% {
    z-index: 2; }
  11.11108% {
    z-index: 1; }
  11.11111% {
    z-index: 0; } }

@keyframes card20 {
  99.99997% {
    z-index: 2; }
  0% {
    z-index: 2; }
  11.11108% {
    z-index: 1; }
  11.11111% {
    z-index: 0; } }

@-webkit-keyframes top20 {
  0%, 11.11105% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  11.11108% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  99.99994% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top20 {
  0%, 11.11105% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  11.11108% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  99.99994% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom21 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  11.11105%, 11.11108% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  11.11111%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom21 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  11.11105%, 11.11108% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  11.11111%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(2) .card1 {
  -webkit-animation: card21 531441s step-end infinite;
          animation: card21 531441s step-end infinite; }
  .digit:nth-child(2) .card1:before, .digit:nth-child(2) .card1:after {
    -webkit-animation: 531441s linear infinite;
            animation: 531441s linear infinite; }
  .digit:nth-child(2) .card1:before {
    -webkit-animation-name: top21;
            animation-name: top21; }
  .digit:nth-child(2) .card1:after {
    -webkit-animation-name: bottom21;
            animation-name: bottom21; }

@-webkit-keyframes card21 {
  11.11108% {
    z-index: 2; }
  22.22219% {
    z-index: 1; }
  22.22222% {
    z-index: 0; } }

@keyframes card21 {
  11.11108% {
    z-index: 2; }
  22.22219% {
    z-index: 1; }
  22.22222% {
    z-index: 0; } }

@-webkit-keyframes top21 {
  0%, 22.22217% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  22.22219% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top21 {
  0%, 22.22217% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  22.22219% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom22 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  22.22217%, 22.22219% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  22.22222%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom22 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  22.22217%, 22.22219% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  22.22222%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(2) .card2 {
  -webkit-animation: card22 531441s step-end infinite;
          animation: card22 531441s step-end infinite; }
  .digit:nth-child(2) .card2:before, .digit:nth-child(2) .card2:after {
    -webkit-animation: 531441s linear infinite;
            animation: 531441s linear infinite; }
  .digit:nth-child(2) .card2:before {
    -webkit-animation-name: top22;
            animation-name: top22; }
  .digit:nth-child(2) .card2:after {
    -webkit-animation-name: bottom22;
            animation-name: bottom22; }

@-webkit-keyframes card22 {
  22.22219% {
    z-index: 2; }
  33.33331% {
    z-index: 1; }
  33.33333% {
    z-index: 0; } }

@keyframes card22 {
  22.22219% {
    z-index: 2; }
  33.33331% {
    z-index: 1; }
  33.33333% {
    z-index: 0; } }

@-webkit-keyframes top22 {
  0%, 33.33328% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  33.33331% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top22 {
  0%, 33.33328% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  33.33331% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom23 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  33.33328%, 33.33331% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  33.33333%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom23 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  33.33328%, 33.33331% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  33.33333%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(2) .card3 {
  -webkit-animation: card23 531441s step-end infinite;
          animation: card23 531441s step-end infinite; }
  .digit:nth-child(2) .card3:before, .digit:nth-child(2) .card3:after {
    -webkit-animation: 531441s linear infinite;
            animation: 531441s linear infinite; }
  .digit:nth-child(2) .card3:before {
    -webkit-animation-name: top23;
            animation-name: top23; }
  .digit:nth-child(2) .card3:after {
    -webkit-animation-name: bottom23;
            animation-name: bottom23; }

@-webkit-keyframes card23 {
  33.33331% {
    z-index: 2; }
  44.44442% {
    z-index: 1; }
  44.44444% {
    z-index: 0; } }

@keyframes card23 {
  33.33331% {
    z-index: 2; }
  44.44442% {
    z-index: 1; }
  44.44444% {
    z-index: 0; } }

@-webkit-keyframes top23 {
  0%, 44.44439% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.44442% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top23 {
  0%, 44.44439% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.44442% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom24 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.44439%, 44.44442% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  44.44444%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom24 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.44439%, 44.44442% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  44.44444%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(2) .card4 {
  -webkit-animation: card24 531441s step-end infinite;
          animation: card24 531441s step-end infinite; }
  .digit:nth-child(2) .card4:before, .digit:nth-child(2) .card4:after {
    -webkit-animation: 531441s linear infinite;
            animation: 531441s linear infinite; }
  .digit:nth-child(2) .card4:before {
    -webkit-animation-name: top24;
            animation-name: top24; }
  .digit:nth-child(2) .card4:after {
    -webkit-animation-name: bottom24;
            animation-name: bottom24; }

@-webkit-keyframes card24 {
  44.44442% {
    z-index: 2; }
  55.55553% {
    z-index: 1; }
  55.55556% {
    z-index: 0; } }

@keyframes card24 {
  44.44442% {
    z-index: 2; }
  55.55553% {
    z-index: 1; }
  55.55556% {
    z-index: 0; } }

@-webkit-keyframes top24 {
  0%, 55.5555% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.55553% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top24 {
  0%, 55.5555% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.55553% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom25 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.5555%, 55.55553% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  55.55556%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom25 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.5555%, 55.55553% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  55.55556%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(2) .card5 {
  -webkit-animation: card25 531441s step-end infinite;
          animation: card25 531441s step-end infinite; }
  .digit:nth-child(2) .card5:before, .digit:nth-child(2) .card5:after {
    -webkit-animation: 531441s linear infinite;
            animation: 531441s linear infinite; }
  .digit:nth-child(2) .card5:before {
    -webkit-animation-name: top25;
            animation-name: top25; }
  .digit:nth-child(2) .card5:after {
    -webkit-animation-name: bottom25;
            animation-name: bottom25; }

@-webkit-keyframes card25 {
  55.55553% {
    z-index: 2; }
  66.66664% {
    z-index: 1; }
  66.66667% {
    z-index: 0; } }

@keyframes card25 {
  55.55553% {
    z-index: 2; }
  66.66664% {
    z-index: 1; }
  66.66667% {
    z-index: 0; } }

@-webkit-keyframes top25 {
  0%, 66.66661% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.66664% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top25 {
  0%, 66.66661% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.66664% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom26 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.66661%, 66.66664% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  66.66667%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom26 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.66661%, 66.66664% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  66.66667%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(2) .card6 {
  -webkit-animation: card26 531441s step-end infinite;
          animation: card26 531441s step-end infinite; }
  .digit:nth-child(2) .card6:before, .digit:nth-child(2) .card6:after {
    -webkit-animation: 531441s linear infinite;
            animation: 531441s linear infinite; }
  .digit:nth-child(2) .card6:before {
    -webkit-animation-name: top26;
            animation-name: top26; }
  .digit:nth-child(2) .card6:after {
    -webkit-animation-name: bottom26;
            animation-name: bottom26; }

@-webkit-keyframes card26 {
  66.66664% {
    z-index: 2; }
  77.77775% {
    z-index: 1; }
  77.77778% {
    z-index: 0; } }

@keyframes card26 {
  66.66664% {
    z-index: 2; }
  77.77775% {
    z-index: 1; }
  77.77778% {
    z-index: 0; } }

@-webkit-keyframes top26 {
  0%, 77.77772% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.77775% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top26 {
  0%, 77.77772% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.77775% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom27 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.77772%, 77.77775% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  77.77778%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom27 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.77772%, 77.77775% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  77.77778%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(2) .card7 {
  -webkit-animation: card27 531441s step-end infinite;
          animation: card27 531441s step-end infinite; }
  .digit:nth-child(2) .card7:before, .digit:nth-child(2) .card7:after {
    -webkit-animation: 531441s linear infinite;
            animation: 531441s linear infinite; }
  .digit:nth-child(2) .card7:before {
    -webkit-animation-name: top27;
            animation-name: top27; }
  .digit:nth-child(2) .card7:after {
    -webkit-animation-name: bottom27;
            animation-name: bottom27; }

@-webkit-keyframes card27 {
  77.77775% {
    z-index: 2; }
  88.88886% {
    z-index: 1; }
  88.88889% {
    z-index: 0; } }

@keyframes card27 {
  77.77775% {
    z-index: 2; }
  88.88886% {
    z-index: 1; }
  88.88889% {
    z-index: 0; } }

@-webkit-keyframes top27 {
  0%, 88.88883% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.88886% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top27 {
  0%, 88.88883% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.88886% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom28 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.88883%, 88.88886% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  88.88889%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom28 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.88883%, 88.88886% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  88.88889%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(2) .card8 {
  -webkit-animation: card28 531441s step-end infinite;
          animation: card28 531441s step-end infinite; }
  .digit:nth-child(2) .card8:before, .digit:nth-child(2) .card8:after {
    -webkit-animation: 531441s linear infinite;
            animation: 531441s linear infinite; }
  .digit:nth-child(2) .card8:before {
    -webkit-animation-name: top28;
            animation-name: top28; }
  .digit:nth-child(2) .card8:after {
    -webkit-animation-name: bottom28;
            animation-name: bottom28; }

@-webkit-keyframes card28 {
  88.88886% {
    z-index: 2; }
  99.99997% {
    z-index: 1; }
  100% {
    z-index: 0; } }

@keyframes card28 {
  88.88886% {
    z-index: 2; }
  99.99997% {
    z-index: 1; }
  100% {
    z-index: 0; } }

@-webkit-keyframes top28 {
  0%, 99.99994% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  99.99997% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top28 {
  0%, 99.99994% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  99.99997% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom20 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  99.99994%, 99.99997% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  100%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom20 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  99.99994%, 99.99997% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  100%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(3) .card0 {
  -webkit-animation: card30 59049s step-end infinite;
          animation: card30 59049s step-end infinite; }
  .digit:nth-child(3) .card0:before, .digit:nth-child(3) .card0:after {
    -webkit-animation: 59049s linear infinite;
            animation: 59049s linear infinite; }
  .digit:nth-child(3) .card0:before {
    -webkit-animation-name: top30;
            animation-name: top30; }
  .digit:nth-child(3) .card0:after {
    -webkit-animation-name: bottom30;
            animation-name: bottom30; }

@-webkit-keyframes card30 {
  99.99975% {
    z-index: 2; }
  0% {
    z-index: 2; }
  11.11086% {
    z-index: 1; }
  11.11111% {
    z-index: 0; } }

@keyframes card30 {
  99.99975% {
    z-index: 2; }
  0% {
    z-index: 2; }
  11.11086% {
    z-index: 1; }
  11.11111% {
    z-index: 0; } }

@-webkit-keyframes top30 {
  0%, 11.1106% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  11.11086% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  99.99949% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top30 {
  0%, 11.1106% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  11.11086% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  99.99949% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom31 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  11.1106%, 11.11086% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  11.11111%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom31 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  11.1106%, 11.11086% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  11.11111%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(3) .card1 {
  -webkit-animation: card31 59049s step-end infinite;
          animation: card31 59049s step-end infinite; }
  .digit:nth-child(3) .card1:before, .digit:nth-child(3) .card1:after {
    -webkit-animation: 59049s linear infinite;
            animation: 59049s linear infinite; }
  .digit:nth-child(3) .card1:before {
    -webkit-animation-name: top31;
            animation-name: top31; }
  .digit:nth-child(3) .card1:after {
    -webkit-animation-name: bottom31;
            animation-name: bottom31; }

@-webkit-keyframes card31 {
  11.11086% {
    z-index: 2; }
  22.22197% {
    z-index: 1; }
  22.22222% {
    z-index: 0; } }

@keyframes card31 {
  11.11086% {
    z-index: 2; }
  22.22197% {
    z-index: 1; }
  22.22222% {
    z-index: 0; } }

@-webkit-keyframes top31 {
  0%, 22.22171% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  22.22197% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top31 {
  0%, 22.22171% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  22.22197% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom32 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  22.22171%, 22.22197% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  22.22222%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom32 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  22.22171%, 22.22197% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  22.22222%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(3) .card2 {
  -webkit-animation: card32 59049s step-end infinite;
          animation: card32 59049s step-end infinite; }
  .digit:nth-child(3) .card2:before, .digit:nth-child(3) .card2:after {
    -webkit-animation: 59049s linear infinite;
            animation: 59049s linear infinite; }
  .digit:nth-child(3) .card2:before {
    -webkit-animation-name: top32;
            animation-name: top32; }
  .digit:nth-child(3) .card2:after {
    -webkit-animation-name: bottom32;
            animation-name: bottom32; }

@-webkit-keyframes card32 {
  22.22197% {
    z-index: 2; }
  33.33308% {
    z-index: 1; }
  33.33333% {
    z-index: 0; } }

@keyframes card32 {
  22.22197% {
    z-index: 2; }
  33.33308% {
    z-index: 1; }
  33.33333% {
    z-index: 0; } }

@-webkit-keyframes top32 {
  0%, 33.33283% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  33.33308% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top32 {
  0%, 33.33283% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  33.33308% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom33 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  33.33283%, 33.33308% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  33.33333%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom33 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  33.33283%, 33.33308% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  33.33333%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(3) .card3 {
  -webkit-animation: card33 59049s step-end infinite;
          animation: card33 59049s step-end infinite; }
  .digit:nth-child(3) .card3:before, .digit:nth-child(3) .card3:after {
    -webkit-animation: 59049s linear infinite;
            animation: 59049s linear infinite; }
  .digit:nth-child(3) .card3:before {
    -webkit-animation-name: top33;
            animation-name: top33; }
  .digit:nth-child(3) .card3:after {
    -webkit-animation-name: bottom33;
            animation-name: bottom33; }

@-webkit-keyframes card33 {
  33.33308% {
    z-index: 2; }
  44.44419% {
    z-index: 1; }
  44.44444% {
    z-index: 0; } }

@keyframes card33 {
  33.33308% {
    z-index: 2; }
  44.44419% {
    z-index: 1; }
  44.44444% {
    z-index: 0; } }

@-webkit-keyframes top33 {
  0%, 44.44394% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.44419% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top33 {
  0%, 44.44394% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.44419% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom34 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.44394%, 44.44419% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  44.44444%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom34 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.44394%, 44.44419% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  44.44444%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(3) .card4 {
  -webkit-animation: card34 59049s step-end infinite;
          animation: card34 59049s step-end infinite; }
  .digit:nth-child(3) .card4:before, .digit:nth-child(3) .card4:after {
    -webkit-animation: 59049s linear infinite;
            animation: 59049s linear infinite; }
  .digit:nth-child(3) .card4:before {
    -webkit-animation-name: top34;
            animation-name: top34; }
  .digit:nth-child(3) .card4:after {
    -webkit-animation-name: bottom34;
            animation-name: bottom34; }

@-webkit-keyframes card34 {
  44.44419% {
    z-index: 2; }
  55.5553% {
    z-index: 1; }
  55.55556% {
    z-index: 0; } }

@keyframes card34 {
  44.44419% {
    z-index: 2; }
  55.5553% {
    z-index: 1; }
  55.55556% {
    z-index: 0; } }

@-webkit-keyframes top34 {
  0%, 55.55505% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.5553% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top34 {
  0%, 55.55505% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.5553% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom35 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.55505%, 55.5553% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  55.55556%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom35 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.55505%, 55.5553% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  55.55556%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(3) .card5 {
  -webkit-animation: card35 59049s step-end infinite;
          animation: card35 59049s step-end infinite; }
  .digit:nth-child(3) .card5:before, .digit:nth-child(3) .card5:after {
    -webkit-animation: 59049s linear infinite;
            animation: 59049s linear infinite; }
  .digit:nth-child(3) .card5:before {
    -webkit-animation-name: top35;
            animation-name: top35; }
  .digit:nth-child(3) .card5:after {
    -webkit-animation-name: bottom35;
            animation-name: bottom35; }

@-webkit-keyframes card35 {
  55.5553% {
    z-index: 2; }
  66.66641% {
    z-index: 1; }
  66.66667% {
    z-index: 0; } }

@keyframes card35 {
  55.5553% {
    z-index: 2; }
  66.66641% {
    z-index: 1; }
  66.66667% {
    z-index: 0; } }

@-webkit-keyframes top35 {
  0%, 66.66616% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.66641% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top35 {
  0%, 66.66616% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.66641% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom36 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.66616%, 66.66641% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  66.66667%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom36 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.66616%, 66.66641% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  66.66667%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(3) .card6 {
  -webkit-animation: card36 59049s step-end infinite;
          animation: card36 59049s step-end infinite; }
  .digit:nth-child(3) .card6:before, .digit:nth-child(3) .card6:after {
    -webkit-animation: 59049s linear infinite;
            animation: 59049s linear infinite; }
  .digit:nth-child(3) .card6:before {
    -webkit-animation-name: top36;
            animation-name: top36; }
  .digit:nth-child(3) .card6:after {
    -webkit-animation-name: bottom36;
            animation-name: bottom36; }

@-webkit-keyframes card36 {
  66.66641% {
    z-index: 2; }
  77.77752% {
    z-index: 1; }
  77.77778% {
    z-index: 0; } }

@keyframes card36 {
  66.66641% {
    z-index: 2; }
  77.77752% {
    z-index: 1; }
  77.77778% {
    z-index: 0; } }

@-webkit-keyframes top36 {
  0%, 77.77727% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.77752% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top36 {
  0%, 77.77727% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.77752% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom37 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.77727%, 77.77752% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  77.77778%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom37 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.77727%, 77.77752% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  77.77778%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(3) .card7 {
  -webkit-animation: card37 59049s step-end infinite;
          animation: card37 59049s step-end infinite; }
  .digit:nth-child(3) .card7:before, .digit:nth-child(3) .card7:after {
    -webkit-animation: 59049s linear infinite;
            animation: 59049s linear infinite; }
  .digit:nth-child(3) .card7:before {
    -webkit-animation-name: top37;
            animation-name: top37; }
  .digit:nth-child(3) .card7:after {
    -webkit-animation-name: bottom37;
            animation-name: bottom37; }

@-webkit-keyframes card37 {
  77.77752% {
    z-index: 2; }
  88.88863% {
    z-index: 1; }
  88.88889% {
    z-index: 0; } }

@keyframes card37 {
  77.77752% {
    z-index: 2; }
  88.88863% {
    z-index: 1; }
  88.88889% {
    z-index: 0; } }

@-webkit-keyframes top37 {
  0%, 88.88838% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.88863% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top37 {
  0%, 88.88838% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.88863% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom38 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.88838%, 88.88863% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  88.88889%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom38 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.88838%, 88.88863% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  88.88889%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(3) .card8 {
  -webkit-animation: card38 59049s step-end infinite;
          animation: card38 59049s step-end infinite; }
  .digit:nth-child(3) .card8:before, .digit:nth-child(3) .card8:after {
    -webkit-animation: 59049s linear infinite;
            animation: 59049s linear infinite; }
  .digit:nth-child(3) .card8:before {
    -webkit-animation-name: top38;
            animation-name: top38; }
  .digit:nth-child(3) .card8:after {
    -webkit-animation-name: bottom38;
            animation-name: bottom38; }

@-webkit-keyframes card38 {
  88.88863% {
    z-index: 2; }
  99.99975% {
    z-index: 1; }
  100% {
    z-index: 0; } }

@keyframes card38 {
  88.88863% {
    z-index: 2; }
  99.99975% {
    z-index: 1; }
  100% {
    z-index: 0; } }

@-webkit-keyframes top38 {
  0%, 99.99949% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  99.99975% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top38 {
  0%, 99.99949% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  99.99975% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom30 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  99.99949%, 99.99975% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  100%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom30 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  99.99949%, 99.99975% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  100%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(4) .card0 {
  -webkit-animation: card40 6561s step-end infinite;
          animation: card40 6561s step-end infinite; }
  .digit:nth-child(4) .card0:before, .digit:nth-child(4) .card0:after {
    -webkit-animation: 6561s linear infinite;
            animation: 6561s linear infinite; }
  .digit:nth-child(4) .card0:before {
    -webkit-animation-name: top40;
            animation-name: top40; }
  .digit:nth-child(4) .card0:after {
    -webkit-animation-name: bottom40;
            animation-name: bottom40; }

@-webkit-keyframes card40 {
  99.99771% {
    z-index: 2; }
  0% {
    z-index: 2; }
  11.10882% {
    z-index: 1; }
  11.11111% {
    z-index: 0; } }

@keyframes card40 {
  99.99771% {
    z-index: 2; }
  0% {
    z-index: 2; }
  11.10882% {
    z-index: 1; }
  11.11111% {
    z-index: 0; } }

@-webkit-keyframes top40 {
  0%, 11.10654% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  11.10882% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  99.99543% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top40 {
  0%, 11.10654% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  11.10882% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  99.99543% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom41 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  11.10654%, 11.10882% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  11.11111%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom41 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  11.10654%, 11.10882% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  11.11111%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(4) .card1 {
  -webkit-animation: card41 6561s step-end infinite;
          animation: card41 6561s step-end infinite; }
  .digit:nth-child(4) .card1:before, .digit:nth-child(4) .card1:after {
    -webkit-animation: 6561s linear infinite;
            animation: 6561s linear infinite; }
  .digit:nth-child(4) .card1:before {
    -webkit-animation-name: top41;
            animation-name: top41; }
  .digit:nth-child(4) .card1:after {
    -webkit-animation-name: bottom41;
            animation-name: bottom41; }

@-webkit-keyframes card41 {
  11.10882% {
    z-index: 2; }
  22.21994% {
    z-index: 1; }
  22.22222% {
    z-index: 0; } }

@keyframes card41 {
  11.10882% {
    z-index: 2; }
  22.21994% {
    z-index: 1; }
  22.22222% {
    z-index: 0; } }

@-webkit-keyframes top41 {
  0%, 22.21765% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  22.21994% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top41 {
  0%, 22.21765% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  22.21994% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom42 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  22.21765%, 22.21994% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  22.22222%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom42 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  22.21765%, 22.21994% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  22.22222%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(4) .card2 {
  -webkit-animation: card42 6561s step-end infinite;
          animation: card42 6561s step-end infinite; }
  .digit:nth-child(4) .card2:before, .digit:nth-child(4) .card2:after {
    -webkit-animation: 6561s linear infinite;
            animation: 6561s linear infinite; }
  .digit:nth-child(4) .card2:before {
    -webkit-animation-name: top42;
            animation-name: top42; }
  .digit:nth-child(4) .card2:after {
    -webkit-animation-name: bottom42;
            animation-name: bottom42; }

@-webkit-keyframes card42 {
  22.21994% {
    z-index: 2; }
  33.33105% {
    z-index: 1; }
  33.33333% {
    z-index: 0; } }

@keyframes card42 {
  22.21994% {
    z-index: 2; }
  33.33105% {
    z-index: 1; }
  33.33333% {
    z-index: 0; } }

@-webkit-keyframes top42 {
  0%, 33.32876% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  33.33105% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top42 {
  0%, 33.32876% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  33.33105% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom43 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  33.32876%, 33.33105% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  33.33333%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom43 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  33.32876%, 33.33105% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  33.33333%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(4) .card3 {
  -webkit-animation: card43 6561s step-end infinite;
          animation: card43 6561s step-end infinite; }
  .digit:nth-child(4) .card3:before, .digit:nth-child(4) .card3:after {
    -webkit-animation: 6561s linear infinite;
            animation: 6561s linear infinite; }
  .digit:nth-child(4) .card3:before {
    -webkit-animation-name: top43;
            animation-name: top43; }
  .digit:nth-child(4) .card3:after {
    -webkit-animation-name: bottom43;
            animation-name: bottom43; }

@-webkit-keyframes card43 {
  33.33105% {
    z-index: 2; }
  44.44216% {
    z-index: 1; }
  44.44444% {
    z-index: 0; } }

@keyframes card43 {
  33.33105% {
    z-index: 2; }
  44.44216% {
    z-index: 1; }
  44.44444% {
    z-index: 0; } }

@-webkit-keyframes top43 {
  0%, 44.43987% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.44216% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top43 {
  0%, 44.43987% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.44216% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom44 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.43987%, 44.44216% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  44.44444%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom44 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.43987%, 44.44216% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  44.44444%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(4) .card4 {
  -webkit-animation: card44 6561s step-end infinite;
          animation: card44 6561s step-end infinite; }
  .digit:nth-child(4) .card4:before, .digit:nth-child(4) .card4:after {
    -webkit-animation: 6561s linear infinite;
            animation: 6561s linear infinite; }
  .digit:nth-child(4) .card4:before {
    -webkit-animation-name: top44;
            animation-name: top44; }
  .digit:nth-child(4) .card4:after {
    -webkit-animation-name: bottom44;
            animation-name: bottom44; }

@-webkit-keyframes card44 {
  44.44216% {
    z-index: 2; }
  55.55327% {
    z-index: 1; }
  55.55556% {
    z-index: 0; } }

@keyframes card44 {
  44.44216% {
    z-index: 2; }
  55.55327% {
    z-index: 1; }
  55.55556% {
    z-index: 0; } }

@-webkit-keyframes top44 {
  0%, 55.55098% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.55327% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top44 {
  0%, 55.55098% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.55327% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom45 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.55098%, 55.55327% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  55.55556%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom45 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.55098%, 55.55327% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  55.55556%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(4) .card5 {
  -webkit-animation: card45 6561s step-end infinite;
          animation: card45 6561s step-end infinite; }
  .digit:nth-child(4) .card5:before, .digit:nth-child(4) .card5:after {
    -webkit-animation: 6561s linear infinite;
            animation: 6561s linear infinite; }
  .digit:nth-child(4) .card5:before {
    -webkit-animation-name: top45;
            animation-name: top45; }
  .digit:nth-child(4) .card5:after {
    -webkit-animation-name: bottom45;
            animation-name: bottom45; }

@-webkit-keyframes card45 {
  55.55327% {
    z-index: 2; }
  66.66438% {
    z-index: 1; }
  66.66667% {
    z-index: 0; } }

@keyframes card45 {
  55.55327% {
    z-index: 2; }
  66.66438% {
    z-index: 1; }
  66.66667% {
    z-index: 0; } }

@-webkit-keyframes top45 {
  0%, 66.66209% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.66438% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top45 {
  0%, 66.66209% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.66438% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom46 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.66209%, 66.66438% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  66.66667%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom46 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.66209%, 66.66438% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  66.66667%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(4) .card6 {
  -webkit-animation: card46 6561s step-end infinite;
          animation: card46 6561s step-end infinite; }
  .digit:nth-child(4) .card6:before, .digit:nth-child(4) .card6:after {
    -webkit-animation: 6561s linear infinite;
            animation: 6561s linear infinite; }
  .digit:nth-child(4) .card6:before {
    -webkit-animation-name: top46;
            animation-name: top46; }
  .digit:nth-child(4) .card6:after {
    -webkit-animation-name: bottom46;
            animation-name: bottom46; }

@-webkit-keyframes card46 {
  66.66438% {
    z-index: 2; }
  77.77549% {
    z-index: 1; }
  77.77778% {
    z-index: 0; } }

@keyframes card46 {
  66.66438% {
    z-index: 2; }
  77.77549% {
    z-index: 1; }
  77.77778% {
    z-index: 0; } }

@-webkit-keyframes top46 {
  0%, 77.77321% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.77549% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top46 {
  0%, 77.77321% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.77549% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom47 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.77321%, 77.77549% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  77.77778%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom47 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.77321%, 77.77549% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  77.77778%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(4) .card7 {
  -webkit-animation: card47 6561s step-end infinite;
          animation: card47 6561s step-end infinite; }
  .digit:nth-child(4) .card7:before, .digit:nth-child(4) .card7:after {
    -webkit-animation: 6561s linear infinite;
            animation: 6561s linear infinite; }
  .digit:nth-child(4) .card7:before {
    -webkit-animation-name: top47;
            animation-name: top47; }
  .digit:nth-child(4) .card7:after {
    -webkit-animation-name: bottom47;
            animation-name: bottom47; }

@-webkit-keyframes card47 {
  77.77549% {
    z-index: 2; }
  88.8866% {
    z-index: 1; }
  88.88889% {
    z-index: 0; } }

@keyframes card47 {
  77.77549% {
    z-index: 2; }
  88.8866% {
    z-index: 1; }
  88.88889% {
    z-index: 0; } }

@-webkit-keyframes top47 {
  0%, 88.88432% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.8866% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top47 {
  0%, 88.88432% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.8866% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom48 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.88432%, 88.8866% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  88.88889%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom48 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.88432%, 88.8866% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  88.88889%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(4) .card8 {
  -webkit-animation: card48 6561s step-end infinite;
          animation: card48 6561s step-end infinite; }
  .digit:nth-child(4) .card8:before, .digit:nth-child(4) .card8:after {
    -webkit-animation: 6561s linear infinite;
            animation: 6561s linear infinite; }
  .digit:nth-child(4) .card8:before {
    -webkit-animation-name: top48;
            animation-name: top48; }
  .digit:nth-child(4) .card8:after {
    -webkit-animation-name: bottom48;
            animation-name: bottom48; }

@-webkit-keyframes card48 {
  88.8866% {
    z-index: 2; }
  99.99771% {
    z-index: 1; }
  100% {
    z-index: 0; } }

@keyframes card48 {
  88.8866% {
    z-index: 2; }
  99.99771% {
    z-index: 1; }
  100% {
    z-index: 0; } }

@-webkit-keyframes top48 {
  0%, 99.99543% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  99.99771% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top48 {
  0%, 99.99543% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  99.99771% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom40 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  99.99543%, 99.99771% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  100%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom40 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  99.99543%, 99.99771% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  100%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(5) .card0 {
  -webkit-animation: card50 729s step-end infinite;
          animation: card50 729s step-end infinite; }
  .digit:nth-child(5) .card0:before, .digit:nth-child(5) .card0:after {
    -webkit-animation: 729s linear infinite;
            animation: 729s linear infinite; }
  .digit:nth-child(5) .card0:before {
    -webkit-animation-name: top50;
            animation-name: top50; }
  .digit:nth-child(5) .card0:after {
    -webkit-animation-name: bottom50;
            animation-name: bottom50; }

@-webkit-keyframes card50 {
  99.97942% {
    z-index: 2; }
  0% {
    z-index: 2; }
  11.09053% {
    z-index: 1; }
  11.11111% {
    z-index: 0; } }

@keyframes card50 {
  99.97942% {
    z-index: 2; }
  0% {
    z-index: 2; }
  11.09053% {
    z-index: 1; }
  11.11111% {
    z-index: 0; } }

@-webkit-keyframes top50 {
  0%, 11.06996% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  11.09053% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  99.95885% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top50 {
  0%, 11.06996% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  11.09053% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  99.95885% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom51 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  11.06996%, 11.09053% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  11.11111%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom51 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  11.06996%, 11.09053% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  11.11111%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(5) .card1 {
  -webkit-animation: card51 729s step-end infinite;
          animation: card51 729s step-end infinite; }
  .digit:nth-child(5) .card1:before, .digit:nth-child(5) .card1:after {
    -webkit-animation: 729s linear infinite;
            animation: 729s linear infinite; }
  .digit:nth-child(5) .card1:before {
    -webkit-animation-name: top51;
            animation-name: top51; }
  .digit:nth-child(5) .card1:after {
    -webkit-animation-name: bottom51;
            animation-name: bottom51; }

@-webkit-keyframes card51 {
  11.09053% {
    z-index: 2; }
  22.20165% {
    z-index: 1; }
  22.22222% {
    z-index: 0; } }

@keyframes card51 {
  11.09053% {
    z-index: 2; }
  22.20165% {
    z-index: 1; }
  22.22222% {
    z-index: 0; } }

@-webkit-keyframes top51 {
  0%, 22.18107% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  22.20165% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top51 {
  0%, 22.18107% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  22.20165% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom52 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  22.18107%, 22.20165% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  22.22222%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom52 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  22.18107%, 22.20165% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  22.22222%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(5) .card2 {
  -webkit-animation: card52 729s step-end infinite;
          animation: card52 729s step-end infinite; }
  .digit:nth-child(5) .card2:before, .digit:nth-child(5) .card2:after {
    -webkit-animation: 729s linear infinite;
            animation: 729s linear infinite; }
  .digit:nth-child(5) .card2:before {
    -webkit-animation-name: top52;
            animation-name: top52; }
  .digit:nth-child(5) .card2:after {
    -webkit-animation-name: bottom52;
            animation-name: bottom52; }

@-webkit-keyframes card52 {
  22.20165% {
    z-index: 2; }
  33.31276% {
    z-index: 1; }
  33.33333% {
    z-index: 0; } }

@keyframes card52 {
  22.20165% {
    z-index: 2; }
  33.31276% {
    z-index: 1; }
  33.33333% {
    z-index: 0; } }

@-webkit-keyframes top52 {
  0%, 33.29218% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  33.31276% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top52 {
  0%, 33.29218% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  33.31276% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom53 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  33.29218%, 33.31276% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  33.33333%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom53 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  33.29218%, 33.31276% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  33.33333%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(5) .card3 {
  -webkit-animation: card53 729s step-end infinite;
          animation: card53 729s step-end infinite; }
  .digit:nth-child(5) .card3:before, .digit:nth-child(5) .card3:after {
    -webkit-animation: 729s linear infinite;
            animation: 729s linear infinite; }
  .digit:nth-child(5) .card3:before {
    -webkit-animation-name: top53;
            animation-name: top53; }
  .digit:nth-child(5) .card3:after {
    -webkit-animation-name: bottom53;
            animation-name: bottom53; }

@-webkit-keyframes card53 {
  33.31276% {
    z-index: 2; }
  44.42387% {
    z-index: 1; }
  44.44444% {
    z-index: 0; } }

@keyframes card53 {
  33.31276% {
    z-index: 2; }
  44.42387% {
    z-index: 1; }
  44.44444% {
    z-index: 0; } }

@-webkit-keyframes top53 {
  0%, 44.40329% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.42387% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top53 {
  0%, 44.40329% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.42387% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom54 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.40329%, 44.42387% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  44.44444%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom54 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.40329%, 44.42387% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  44.44444%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(5) .card4 {
  -webkit-animation: card54 729s step-end infinite;
          animation: card54 729s step-end infinite; }
  .digit:nth-child(5) .card4:before, .digit:nth-child(5) .card4:after {
    -webkit-animation: 729s linear infinite;
            animation: 729s linear infinite; }
  .digit:nth-child(5) .card4:before {
    -webkit-animation-name: top54;
            animation-name: top54; }
  .digit:nth-child(5) .card4:after {
    -webkit-animation-name: bottom54;
            animation-name: bottom54; }

@-webkit-keyframes card54 {
  44.42387% {
    z-index: 2; }
  55.53498% {
    z-index: 1; }
  55.55556% {
    z-index: 0; } }

@keyframes card54 {
  44.42387% {
    z-index: 2; }
  55.53498% {
    z-index: 1; }
  55.55556% {
    z-index: 0; } }

@-webkit-keyframes top54 {
  0%, 55.5144% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.53498% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top54 {
  0%, 55.5144% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.53498% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom55 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.5144%, 55.53498% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  55.55556%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom55 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.5144%, 55.53498% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  55.55556%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(5) .card5 {
  -webkit-animation: card55 729s step-end infinite;
          animation: card55 729s step-end infinite; }
  .digit:nth-child(5) .card5:before, .digit:nth-child(5) .card5:after {
    -webkit-animation: 729s linear infinite;
            animation: 729s linear infinite; }
  .digit:nth-child(5) .card5:before {
    -webkit-animation-name: top55;
            animation-name: top55; }
  .digit:nth-child(5) .card5:after {
    -webkit-animation-name: bottom55;
            animation-name: bottom55; }

@-webkit-keyframes card55 {
  55.53498% {
    z-index: 2; }
  66.64609% {
    z-index: 1; }
  66.66667% {
    z-index: 0; } }

@keyframes card55 {
  55.53498% {
    z-index: 2; }
  66.64609% {
    z-index: 1; }
  66.66667% {
    z-index: 0; } }

@-webkit-keyframes top55 {
  0%, 66.62551% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.64609% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top55 {
  0%, 66.62551% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.64609% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom56 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.62551%, 66.64609% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  66.66667%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom56 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.62551%, 66.64609% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  66.66667%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(5) .card6 {
  -webkit-animation: card56 729s step-end infinite;
          animation: card56 729s step-end infinite; }
  .digit:nth-child(5) .card6:before, .digit:nth-child(5) .card6:after {
    -webkit-animation: 729s linear infinite;
            animation: 729s linear infinite; }
  .digit:nth-child(5) .card6:before {
    -webkit-animation-name: top56;
            animation-name: top56; }
  .digit:nth-child(5) .card6:after {
    -webkit-animation-name: bottom56;
            animation-name: bottom56; }

@-webkit-keyframes card56 {
  66.64609% {
    z-index: 2; }
  77.7572% {
    z-index: 1; }
  77.77778% {
    z-index: 0; } }

@keyframes card56 {
  66.64609% {
    z-index: 2; }
  77.7572% {
    z-index: 1; }
  77.77778% {
    z-index: 0; } }

@-webkit-keyframes top56 {
  0%, 77.73663% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.7572% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top56 {
  0%, 77.73663% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.7572% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom57 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.73663%, 77.7572% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  77.77778%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom57 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.73663%, 77.7572% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  77.77778%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(5) .card7 {
  -webkit-animation: card57 729s step-end infinite;
          animation: card57 729s step-end infinite; }
  .digit:nth-child(5) .card7:before, .digit:nth-child(5) .card7:after {
    -webkit-animation: 729s linear infinite;
            animation: 729s linear infinite; }
  .digit:nth-child(5) .card7:before {
    -webkit-animation-name: top57;
            animation-name: top57; }
  .digit:nth-child(5) .card7:after {
    -webkit-animation-name: bottom57;
            animation-name: bottom57; }

@-webkit-keyframes card57 {
  77.7572% {
    z-index: 2; }
  88.86831% {
    z-index: 1; }
  88.88889% {
    z-index: 0; } }

@keyframes card57 {
  77.7572% {
    z-index: 2; }
  88.86831% {
    z-index: 1; }
  88.88889% {
    z-index: 0; } }

@-webkit-keyframes top57 {
  0%, 88.84774% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.86831% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top57 {
  0%, 88.84774% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.86831% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom58 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.84774%, 88.86831% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  88.88889%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom58 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.84774%, 88.86831% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  88.88889%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(5) .card8 {
  -webkit-animation: card58 729s step-end infinite;
          animation: card58 729s step-end infinite; }
  .digit:nth-child(5) .card8:before, .digit:nth-child(5) .card8:after {
    -webkit-animation: 729s linear infinite;
            animation: 729s linear infinite; }
  .digit:nth-child(5) .card8:before {
    -webkit-animation-name: top58;
            animation-name: top58; }
  .digit:nth-child(5) .card8:after {
    -webkit-animation-name: bottom58;
            animation-name: bottom58; }

@-webkit-keyframes card58 {
  88.86831% {
    z-index: 2; }
  99.97942% {
    z-index: 1; }
  100% {
    z-index: 0; } }

@keyframes card58 {
  88.86831% {
    z-index: 2; }
  99.97942% {
    z-index: 1; }
  100% {
    z-index: 0; } }

@-webkit-keyframes top58 {
  0%, 99.95885% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  99.97942% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top58 {
  0%, 99.95885% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  99.97942% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom50 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  99.95885%, 99.97942% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  100%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom50 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  99.95885%, 99.97942% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  100%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(6) .card0 {
  -webkit-animation: card60 81s step-end infinite;
          animation: card60 81s step-end infinite; }
  .digit:nth-child(6) .card0:before, .digit:nth-child(6) .card0:after {
    -webkit-animation: 81s linear infinite;
            animation: 81s linear infinite; }
  .digit:nth-child(6) .card0:before {
    -webkit-animation-name: top60;
            animation-name: top60; }
  .digit:nth-child(6) .card0:after {
    -webkit-animation-name: bottom60;
            animation-name: bottom60; }

@-webkit-keyframes card60 {
  99.81481% {
    z-index: 2; }
  0% {
    z-index: 2; }
  10.92593% {
    z-index: 1; }
  11.11111% {
    z-index: 0; } }

@keyframes card60 {
  99.81481% {
    z-index: 2; }
  0% {
    z-index: 2; }
  10.92593% {
    z-index: 1; }
  11.11111% {
    z-index: 0; } }

@-webkit-keyframes top60 {
  0%, 10.74074% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  10.92593% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  99.62963% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top60 {
  0%, 10.74074% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  10.92593% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  99.62963% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom61 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  10.74074%, 10.92593% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  11.11111%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom61 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  10.74074%, 10.92593% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  11.11111%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(6) .card1 {
  -webkit-animation: card61 81s step-end infinite;
          animation: card61 81s step-end infinite; }
  .digit:nth-child(6) .card1:before, .digit:nth-child(6) .card1:after {
    -webkit-animation: 81s linear infinite;
            animation: 81s linear infinite; }
  .digit:nth-child(6) .card1:before {
    -webkit-animation-name: top61;
            animation-name: top61; }
  .digit:nth-child(6) .card1:after {
    -webkit-animation-name: bottom61;
            animation-name: bottom61; }

@-webkit-keyframes card61 {
  10.92593% {
    z-index: 2; }
  22.03704% {
    z-index: 1; }
  22.22222% {
    z-index: 0; } }

@keyframes card61 {
  10.92593% {
    z-index: 2; }
  22.03704% {
    z-index: 1; }
  22.22222% {
    z-index: 0; } }

@-webkit-keyframes top61 {
  0%, 21.85185% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  22.03704% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top61 {
  0%, 21.85185% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  22.03704% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom62 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  21.85185%, 22.03704% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  22.22222%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom62 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  21.85185%, 22.03704% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  22.22222%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(6) .card2 {
  -webkit-animation: card62 81s step-end infinite;
          animation: card62 81s step-end infinite; }
  .digit:nth-child(6) .card2:before, .digit:nth-child(6) .card2:after {
    -webkit-animation: 81s linear infinite;
            animation: 81s linear infinite; }
  .digit:nth-child(6) .card2:before {
    -webkit-animation-name: top62;
            animation-name: top62; }
  .digit:nth-child(6) .card2:after {
    -webkit-animation-name: bottom62;
            animation-name: bottom62; }

@-webkit-keyframes card62 {
  22.03704% {
    z-index: 2; }
  33.14815% {
    z-index: 1; }
  33.33333% {
    z-index: 0; } }

@keyframes card62 {
  22.03704% {
    z-index: 2; }
  33.14815% {
    z-index: 1; }
  33.33333% {
    z-index: 0; } }

@-webkit-keyframes top62 {
  0%, 32.96296% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  33.14815% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top62 {
  0%, 32.96296% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  33.14815% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom63 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  32.96296%, 33.14815% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  33.33333%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom63 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  32.96296%, 33.14815% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  33.33333%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(6) .card3 {
  -webkit-animation: card63 81s step-end infinite;
          animation: card63 81s step-end infinite; }
  .digit:nth-child(6) .card3:before, .digit:nth-child(6) .card3:after {
    -webkit-animation: 81s linear infinite;
            animation: 81s linear infinite; }
  .digit:nth-child(6) .card3:before {
    -webkit-animation-name: top63;
            animation-name: top63; }
  .digit:nth-child(6) .card3:after {
    -webkit-animation-name: bottom63;
            animation-name: bottom63; }

@-webkit-keyframes card63 {
  33.14815% {
    z-index: 2; }
  44.25926% {
    z-index: 1; }
  44.44444% {
    z-index: 0; } }

@keyframes card63 {
  33.14815% {
    z-index: 2; }
  44.25926% {
    z-index: 1; }
  44.44444% {
    z-index: 0; } }

@-webkit-keyframes top63 {
  0%, 44.07407% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.25926% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top63 {
  0%, 44.07407% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.25926% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom64 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.07407%, 44.25926% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  44.44444%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom64 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  44.07407%, 44.25926% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  44.44444%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(6) .card4 {
  -webkit-animation: card64 81s step-end infinite;
          animation: card64 81s step-end infinite; }
  .digit:nth-child(6) .card4:before, .digit:nth-child(6) .card4:after {
    -webkit-animation: 81s linear infinite;
            animation: 81s linear infinite; }
  .digit:nth-child(6) .card4:before {
    -webkit-animation-name: top64;
            animation-name: top64; }
  .digit:nth-child(6) .card4:after {
    -webkit-animation-name: bottom64;
            animation-name: bottom64; }

@-webkit-keyframes card64 {
  44.25926% {
    z-index: 2; }
  55.37037% {
    z-index: 1; }
  55.55556% {
    z-index: 0; } }

@keyframes card64 {
  44.25926% {
    z-index: 2; }
  55.37037% {
    z-index: 1; }
  55.55556% {
    z-index: 0; } }

@-webkit-keyframes top64 {
  0%, 55.18519% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.37037% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top64 {
  0%, 55.18519% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.37037% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom65 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.18519%, 55.37037% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  55.55556%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom65 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  55.18519%, 55.37037% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  55.55556%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(6) .card5 {
  -webkit-animation: card65 81s step-end infinite;
          animation: card65 81s step-end infinite; }
  .digit:nth-child(6) .card5:before, .digit:nth-child(6) .card5:after {
    -webkit-animation: 81s linear infinite;
            animation: 81s linear infinite; }
  .digit:nth-child(6) .card5:before {
    -webkit-animation-name: top65;
            animation-name: top65; }
  .digit:nth-child(6) .card5:after {
    -webkit-animation-name: bottom65;
            animation-name: bottom65; }

@-webkit-keyframes card65 {
  55.37037% {
    z-index: 2; }
  66.48148% {
    z-index: 1; }
  66.66667% {
    z-index: 0; } }

@keyframes card65 {
  55.37037% {
    z-index: 2; }
  66.48148% {
    z-index: 1; }
  66.66667% {
    z-index: 0; } }

@-webkit-keyframes top65 {
  0%, 66.2963% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.48148% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top65 {
  0%, 66.2963% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.48148% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom66 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.2963%, 66.48148% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  66.66667%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom66 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  66.2963%, 66.48148% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  66.66667%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(6) .card6 {
  -webkit-animation: card66 81s step-end infinite;
          animation: card66 81s step-end infinite; }
  .digit:nth-child(6) .card6:before, .digit:nth-child(6) .card6:after {
    -webkit-animation: 81s linear infinite;
            animation: 81s linear infinite; }
  .digit:nth-child(6) .card6:before {
    -webkit-animation-name: top66;
            animation-name: top66; }
  .digit:nth-child(6) .card6:after {
    -webkit-animation-name: bottom66;
            animation-name: bottom66; }

@-webkit-keyframes card66 {
  66.48148% {
    z-index: 2; }
  77.59259% {
    z-index: 1; }
  77.77778% {
    z-index: 0; } }

@keyframes card66 {
  66.48148% {
    z-index: 2; }
  77.59259% {
    z-index: 1; }
  77.77778% {
    z-index: 0; } }

@-webkit-keyframes top66 {
  0%, 77.40741% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.59259% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top66 {
  0%, 77.40741% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.59259% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom67 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.40741%, 77.59259% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  77.77778%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom67 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  77.40741%, 77.59259% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  77.77778%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(6) .card7 {
  -webkit-animation: card67 81s step-end infinite;
          animation: card67 81s step-end infinite; }
  .digit:nth-child(6) .card7:before, .digit:nth-child(6) .card7:after {
    -webkit-animation: 81s linear infinite;
            animation: 81s linear infinite; }
  .digit:nth-child(6) .card7:before {
    -webkit-animation-name: top67;
            animation-name: top67; }
  .digit:nth-child(6) .card7:after {
    -webkit-animation-name: bottom67;
            animation-name: bottom67; }

@-webkit-keyframes card67 {
  77.59259% {
    z-index: 2; }
  88.7037% {
    z-index: 1; }
  88.88889% {
    z-index: 0; } }

@keyframes card67 {
  77.59259% {
    z-index: 2; }
  88.7037% {
    z-index: 1; }
  88.88889% {
    z-index: 0; } }

@-webkit-keyframes top67 {
  0%, 88.51852% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.7037% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top67 {
  0%, 88.51852% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.7037% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom68 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.51852%, 88.7037% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  88.88889%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom68 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  88.51852%, 88.7037% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  88.88889%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(6) .card8 {
  -webkit-animation: card68 81s step-end infinite;
          animation: card68 81s step-end infinite; }
  .digit:nth-child(6) .card8:before, .digit:nth-child(6) .card8:after {
    -webkit-animation: 81s linear infinite;
            animation: 81s linear infinite; }
  .digit:nth-child(6) .card8:before {
    -webkit-animation-name: top68;
            animation-name: top68; }
  .digit:nth-child(6) .card8:after {
    -webkit-animation-name: bottom68;
            animation-name: bottom68; }

@-webkit-keyframes card68 {
  88.7037% {
    z-index: 2; }
  99.81481% {
    z-index: 1; }
  100% {
    z-index: 0; } }

@keyframes card68 {
  88.7037% {
    z-index: 2; }
  99.81481% {
    z-index: 1; }
  100% {
    z-index: 0; } }

@-webkit-keyframes top68 {
  0%, 99.62963% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  99.81481% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top68 {
  0%, 99.62963% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  99.81481% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom60 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  99.62963%, 99.81481% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  100%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom60 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  99.62963%, 99.81481% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  100%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(7) .card0 {
  -webkit-animation: card70 9s step-end infinite;
          animation: card70 9s step-end infinite; }
  .digit:nth-child(7) .card0:before, .digit:nth-child(7) .card0:after {
    -webkit-animation: 9s linear infinite;
            animation: 9s linear infinite; }
  .digit:nth-child(7) .card0:before {
    -webkit-animation-name: top70;
            animation-name: top70; }
  .digit:nth-child(7) .card0:after {
    -webkit-animation-name: bottom70;
            animation-name: bottom70; }

@-webkit-keyframes card70 {
  98.33333% {
    z-index: 2; }
  0% {
    z-index: 2; }
  9.44444% {
    z-index: 1; }
  11.11111% {
    z-index: 0; } }

@keyframes card70 {
  98.33333% {
    z-index: 2; }
  0% {
    z-index: 2; }
  9.44444% {
    z-index: 1; }
  11.11111% {
    z-index: 0; } }

@-webkit-keyframes top70 {
  0%, 7.77778% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  9.44444% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  96.66667% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top70 {
  0%, 7.77778% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  9.44444% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  96.66667% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom71 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  7.77778%, 9.44444% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  11.11111%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom71 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  7.77778%, 9.44444% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  11.11111%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(7) .card1 {
  -webkit-animation: card71 9s step-end infinite;
          animation: card71 9s step-end infinite; }
  .digit:nth-child(7) .card1:before, .digit:nth-child(7) .card1:after {
    -webkit-animation: 9s linear infinite;
            animation: 9s linear infinite; }
  .digit:nth-child(7) .card1:before {
    -webkit-animation-name: top71;
            animation-name: top71; }
  .digit:nth-child(7) .card1:after {
    -webkit-animation-name: bottom71;
            animation-name: bottom71; }

@-webkit-keyframes card71 {
  9.44444% {
    z-index: 2; }
  20.55556% {
    z-index: 1; }
  22.22222% {
    z-index: 0; } }

@keyframes card71 {
  9.44444% {
    z-index: 2; }
  20.55556% {
    z-index: 1; }
  22.22222% {
    z-index: 0; } }

@-webkit-keyframes top71 {
  0%, 18.88889% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  20.55556% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top71 {
  0%, 18.88889% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  20.55556% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom72 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  18.88889%, 20.55556% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  22.22222%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom72 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  18.88889%, 20.55556% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  22.22222%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(7) .card2 {
  -webkit-animation: card72 9s step-end infinite;
          animation: card72 9s step-end infinite; }
  .digit:nth-child(7) .card2:before, .digit:nth-child(7) .card2:after {
    -webkit-animation: 9s linear infinite;
            animation: 9s linear infinite; }
  .digit:nth-child(7) .card2:before {
    -webkit-animation-name: top72;
            animation-name: top72; }
  .digit:nth-child(7) .card2:after {
    -webkit-animation-name: bottom72;
            animation-name: bottom72; }

@-webkit-keyframes card72 {
  20.55556% {
    z-index: 2; }
  31.66667% {
    z-index: 1; }
  33.33333% {
    z-index: 0; } }

@keyframes card72 {
  20.55556% {
    z-index: 2; }
  31.66667% {
    z-index: 1; }
  33.33333% {
    z-index: 0; } }

@-webkit-keyframes top72 {
  0%, 30% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  31.66667% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top72 {
  0%, 30% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  31.66667% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom73 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  30%, 31.66667% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  33.33333%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom73 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  30%, 31.66667% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  33.33333%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(7) .card3 {
  -webkit-animation: card73 9s step-end infinite;
          animation: card73 9s step-end infinite; }
  .digit:nth-child(7) .card3:before, .digit:nth-child(7) .card3:after {
    -webkit-animation: 9s linear infinite;
            animation: 9s linear infinite; }
  .digit:nth-child(7) .card3:before {
    -webkit-animation-name: top73;
            animation-name: top73; }
  .digit:nth-child(7) .card3:after {
    -webkit-animation-name: bottom73;
            animation-name: bottom73; }

@-webkit-keyframes card73 {
  31.66667% {
    z-index: 2; }
  42.77778% {
    z-index: 1; }
  44.44444% {
    z-index: 0; } }

@keyframes card73 {
  31.66667% {
    z-index: 2; }
  42.77778% {
    z-index: 1; }
  44.44444% {
    z-index: 0; } }

@-webkit-keyframes top73 {
  0%, 41.11111% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  42.77778% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top73 {
  0%, 41.11111% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  42.77778% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom74 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  41.11111%, 42.77778% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  44.44444%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom74 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  41.11111%, 42.77778% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  44.44444%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(7) .card4 {
  -webkit-animation: card74 9s step-end infinite;
          animation: card74 9s step-end infinite; }
  .digit:nth-child(7) .card4:before, .digit:nth-child(7) .card4:after {
    -webkit-animation: 9s linear infinite;
            animation: 9s linear infinite; }
  .digit:nth-child(7) .card4:before {
    -webkit-animation-name: top74;
            animation-name: top74; }
  .digit:nth-child(7) .card4:after {
    -webkit-animation-name: bottom74;
            animation-name: bottom74; }

@-webkit-keyframes card74 {
  42.77778% {
    z-index: 2; }
  53.88889% {
    z-index: 1; }
  55.55556% {
    z-index: 0; } }

@keyframes card74 {
  42.77778% {
    z-index: 2; }
  53.88889% {
    z-index: 1; }
  55.55556% {
    z-index: 0; } }

@-webkit-keyframes top74 {
  0%, 52.22222% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  53.88889% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top74 {
  0%, 52.22222% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  53.88889% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom75 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  52.22222%, 53.88889% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  55.55556%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom75 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  52.22222%, 53.88889% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  55.55556%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(7) .card5 {
  -webkit-animation: card75 9s step-end infinite;
          animation: card75 9s step-end infinite; }
  .digit:nth-child(7) .card5:before, .digit:nth-child(7) .card5:after {
    -webkit-animation: 9s linear infinite;
            animation: 9s linear infinite; }
  .digit:nth-child(7) .card5:before {
    -webkit-animation-name: top75;
            animation-name: top75; }
  .digit:nth-child(7) .card5:after {
    -webkit-animation-name: bottom75;
            animation-name: bottom75; }

@-webkit-keyframes card75 {
  53.88889% {
    z-index: 2; }
  65% {
    z-index: 1; }
  66.66667% {
    z-index: 0; } }

@keyframes card75 {
  53.88889% {
    z-index: 2; }
  65% {
    z-index: 1; }
  66.66667% {
    z-index: 0; } }

@-webkit-keyframes top75 {
  0%, 63.33333% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  65% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top75 {
  0%, 63.33333% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  65% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom76 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  63.33333%, 65% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  66.66667%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom76 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  63.33333%, 65% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  66.66667%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(7) .card6 {
  -webkit-animation: card76 9s step-end infinite;
          animation: card76 9s step-end infinite; }
  .digit:nth-child(7) .card6:before, .digit:nth-child(7) .card6:after {
    -webkit-animation: 9s linear infinite;
            animation: 9s linear infinite; }
  .digit:nth-child(7) .card6:before {
    -webkit-animation-name: top76;
            animation-name: top76; }
  .digit:nth-child(7) .card6:after {
    -webkit-animation-name: bottom76;
            animation-name: bottom76; }

@-webkit-keyframes card76 {
  65% {
    z-index: 2; }
  76.11111% {
    z-index: 1; }
  77.77778% {
    z-index: 0; } }

@keyframes card76 {
  65% {
    z-index: 2; }
  76.11111% {
    z-index: 1; }
  77.77778% {
    z-index: 0; } }

@-webkit-keyframes top76 {
  0%, 74.44444% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  76.11111% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top76 {
  0%, 74.44444% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  76.11111% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom77 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  74.44444%, 76.11111% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  77.77778%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom77 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  74.44444%, 76.11111% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  77.77778%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(7) .card7 {
  -webkit-animation: card77 9s step-end infinite;
          animation: card77 9s step-end infinite; }
  .digit:nth-child(7) .card7:before, .digit:nth-child(7) .card7:after {
    -webkit-animation: 9s linear infinite;
            animation: 9s linear infinite; }
  .digit:nth-child(7) .card7:before {
    -webkit-animation-name: top77;
            animation-name: top77; }
  .digit:nth-child(7) .card7:after {
    -webkit-animation-name: bottom77;
            animation-name: bottom77; }

@-webkit-keyframes card77 {
  76.11111% {
    z-index: 2; }
  87.22222% {
    z-index: 1; }
  88.88889% {
    z-index: 0; } }

@keyframes card77 {
  76.11111% {
    z-index: 2; }
  87.22222% {
    z-index: 1; }
  88.88889% {
    z-index: 0; } }

@-webkit-keyframes top77 {
  0%, 85.55556% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  87.22222% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top77 {
  0%, 85.55556% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  87.22222% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom78 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  85.55556%, 87.22222% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  88.88889%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom78 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  85.55556%, 87.22222% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  88.88889%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.digit:nth-child(7) .card8 {
  -webkit-animation: card78 9s step-end infinite;
          animation: card78 9s step-end infinite; }
  .digit:nth-child(7) .card8:before, .digit:nth-child(7) .card8:after {
    -webkit-animation: 9s linear infinite;
            animation: 9s linear infinite; }
  .digit:nth-child(7) .card8:before {
    -webkit-animation-name: top78;
            animation-name: top78; }
  .digit:nth-child(7) .card8:after {
    -webkit-animation-name: bottom78;
            animation-name: bottom78; }

@-webkit-keyframes card78 {
  87.22222% {
    z-index: 2; }
  98.33333% {
    z-index: 1; }
  100% {
    z-index: 0; } }

@keyframes card78 {
  87.22222% {
    z-index: 2; }
  98.33333% {
    z-index: 1; }
  100% {
    z-index: 0; } }

@-webkit-keyframes top78 {
  0%, 96.66667% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  98.33333% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes top78 {
  0%, 96.66667% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  98.33333% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@-webkit-keyframes bottom70 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  96.66667%, 98.33333% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  100%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes bottom70 {
  0% {
    -webkit-animation-timing-function: step-end;
            animation-timing-function: step-end;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  96.66667%, 98.33333% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  100%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

.hoodburger__header {
  color: #FAD000;
  /* @alternate */
  color: var(--mdc-theme-primary, #FAD000);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #FF4438;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #FF4438);
  padding: .5rem .1rem;
  position: relative;
  overflow: hidden;
  font-size: 1.5rem;
  line-height: .75em;
  white-space: nowrap;
  text-align: left; }

.hoodburger__headerBlock {
  display: inline-block;
  margin: 0 .4rem;
  animation: marquee 60s linear infinite reverse; }

.shakka {
  height: 25px;
  width: auto;
  margin-left: 10px;
  margin-top: -5px; }

.hoodburger__headerBlock:nth-child(5n-4) {
  font-family: 'Chewy', cursive; }

.hoodburger__headerBlock:nth-child(5n-3) {
  font-family: 'Spicy Rice', cursive; }

.hoodburger__headerBlock:nth-child(5n-2) {
  font-family: 'Lemon', cursive; }

.hoodburger__headerBlock:nth-child(5n-1) {
  font-family: 'Titan One', cursive; }

.hoodburger__headerBlock:nth-child(5n) {
  font-family: 'Shrikhand', cursive; }

@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  100% {
    -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw); } }

@keyframes marquee {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  100% {
    -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw); } }

form {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-on-surface, #fff);
  width: 50vw;
  padding: 2rem;
  margin: auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: left; }
  form input,
  form textarea {
    width: calc(100% - 2rem);
    padding: .5rem;
    font-size: 1.125em;
    border: 1px solid #000; }
  form div {
    margin-bottom: 20px; }
  form a {
    color: #fff;
    /* @alternate */
    color: var(--mdc-theme-on-surface, #fff); }
    form a.btn-active {
      color: #FF4438;
      /* @alternate */
      color: var(--mdc-theme-surface, #FF4438); }

/*------------------------------------*\
		$PAGE-LAYOUT
\*------------------------------------*/
/**
 * Layout classes for the page
 */
.page-layout-panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.page-layout-content {
  padding: 32px 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: calc(100vh - 128px); }

.image-area {
  position: fixed;
  z-index: 9;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: calc(100vh - 200px); }

.meat-star {
  height: 80px;
  width: auto;
  position: fixed;
  z-index: 9;
  -webkit-animation: moveX 60s linear 0s infinite alternate, moveY 10s linear 0s infinite alternate;
          animation: moveX 60s linear 0s infinite alternate, moveY 10s linear 0s infinite alternate; }

.perth-family-restaurant {
  height: 90px;
  width: auto;
  position: fixed;
  bottom: -400px;
  z-index: 9;
  -webkit-animation: moveXX 100s linear 5s infinite alternate, moveYY 30s linear 5s infinite alternate;
          animation: moveXX 100s linear 5s infinite alternate, moveYY 30s linear 5s infinite alternate; }

.hoodburger-script {
  height: 100px;
  width: auto;
  position: fixed;
  z-index: 9;
  top: -150px;
  -webkit-animation: moveXXX 120s linear 10s infinite alternate, moveY 25s linear 10s infinite alternate;
          animation: moveXXX 120s linear 10s infinite alternate, moveY 25s linear 10s infinite alternate; }

.hoodburger-outlined {
  height: 110px;
  width: auto;
  position: fixed;
  z-index: 9;
  bottom: -250px;
  -webkit-animation: moveXXXX 90s linear 15s infinite alternate, moveYY 90s linear 15s infinite alternate;
          animation: moveXXXX 90s linear 15s infinite alternate, moveYY 90s linear 15s infinite alternate; }

.hoodburger-h {
  height: 70px;
  width: auto;
  position: fixed;
  z-index: 9;
  top: -300px;
  -webkit-animation: moveXXXXX 100s linear 20s infinite alternate, moveY 100s linear 20s infinite alternate;
          animation: moveXXXXX 100s linear 20s infinite alternate, moveY 100s linear 20s infinite alternate; }

.hoodburger-cheeseburger {
  height: 80px;
  width: auto;
  position: fixed;
  z-index: 9;
  bottom: -400px;
  -webkit-animation: moveXXXXX 40s linear 20s infinite alternate, moveYY 40s linear 20s infinite alternate;
          animation: moveXXXXX 40s linear 20s infinite alternate, moveYY 40s linear 20s infinite alternate; }

.hoodburger-tendersandwich {
  height: 90px;
  width: auto;
  position: fixed;
  z-index: 9;
  top: -300px;
  -webkit-animation: moveXXXXXX 80s linear 25s infinite alternate, moveY 80s linear 25s infinite alternate;
          animation: moveXXXXXX 80s linear 25s infinite alternate, moveY 80s linear 25s infinite alternate; }

.hoodburger-frenchfries {
  height: 100px;
  width: auto;
  position: fixed;
  z-index: 9;
  bottom: -300px;
  -webkit-animation: moveXXXXXXX 60s linear 30s infinite alternate, moveYY 60s linear 30s infinite alternate;
          animation: moveXXXXXXX 60s linear 30s infinite alternate, moveYY 60s linear 30s infinite alternate; }

@-webkit-keyframes moveX {
  from {
    left: 0px; }
  to {
    left: 100%; } }

@keyframes moveX {
  from {
    left: 0px; }
  to {
    left: 100%; } }

@-webkit-keyframes moveXX {
  from {
    right: 0px; }
  to {
    right: 100%; } }

@keyframes moveXX {
  from {
    right: 0px; }
  to {
    right: 100%; } }

@-webkit-keyframes moveXXX {
  from {
    left: 100px; }
  to {
    left: 100%; } }

@keyframes moveXXX {
  from {
    left: 100px; }
  to {
    left: 100%; } }

@-webkit-keyframes moveXXXX {
  from {
    right: 100px; }
  to {
    right: 100%; } }

@keyframes moveXXXX {
  from {
    right: 100px; }
  to {
    right: 100%; } }

@-webkit-keyframes moveXXXXX {
  from {
    left: 200px; }
  to {
    left: 100%; } }

@keyframes moveXXXXX {
  from {
    left: 200px; }
  to {
    left: 100%; } }

@-webkit-keyframes moveXXXXXX {
  from {
    right: 200px; }
  to {
    right: 100%; } }

@keyframes moveXXXXXX {
  from {
    right: 200px; }
  to {
    right: 100%; } }

@-webkit-keyframes moveXXXXXXX {
  from {
    left: 300px; }
  to {
    left: 100%; } }

@keyframes moveXXXXXXX {
  from {
    left: 300px; }
  to {
    left: 100%; } }

@-webkit-keyframes moveXXXXXXXX {
  from {
    right: 300px; }
  to {
    right: 100%; } }

@keyframes moveXXXXXXXX {
  from {
    right: 300px; }
  to {
    right: 100%; } }

@-webkit-keyframes moveY {
  from {
    top: 0; }
  to {
    top: calc(100vh - 50px); } }

@keyframes moveY {
  from {
    top: 0; }
  to {
    top: calc(100vh - 50px); } }

@-webkit-keyframes moveYY {
  from {
    bottom: 0; }
  to {
    bottom: calc(100vh - 50px); } }

@keyframes moveYY {
  from {
    bottom: 0; }
  to {
    bottom: calc(100vh - 50px); } }

/*------------------------------------*\
		$header
\*------------------------------------*/
.mdc-top-app-bar {
  width: auto;
  left: 0;
  right: 0;
  background-color: transparent; }
  @media screen and (min-width: 48em) {
    .mdc-top-app-bar {
      width: 100%; } }

.mdc-top-app-bar__row {
  height: 192px; }
  @media screen and (max-width: 64em) {
    .mdc-top-app-bar__row {
      height: 150px;
      width: 100vw; } }

.header__logo {
  margin: 16px; }
  @media screen and (max-width: 64em) {
    .header__logo {
      margin: 20px auto; } }

.header__logo-img {
  height: 160px; }
  @media screen and (max-width: 64em) {
    .header__logo-img {
      display: none; } }

/*------------------------------------*\
		$WRAPPER
\*------------------------------------*/
/**
 * This is ued for the Catalog wrapper
 */
.wrapper {
  max-width: 1200px;
  margin: auto; }

/**
 * #UTILITIES
 */
