/*------------------------------------*\
    $RESPONSIVE
\*------------------------------------*/

/**
 * Simple Mixin media query
 * These can be invoked later on via the `media-query()` mixin found in `_tools.responsive`.
 * Use em instead of pixel https://zellwk.com/blog/media-query-units/
 */

$breakpoints: (
	"phone-small"    "screen and (max-width: 22.438em)",
    "phone"          "screen and (max-width: 64.000em)",
    "tablet"         "screen and (min-width: 48.000em)",
    "desktop"        "screen and (min-width: 64.063em)",
) !default;

/**
 * phone-small 0 - 359px
 * phone 0 - 1024px
 * tablet 769px - 1024px
 * desktop 1025px +
 */

// If we have included this file, set a variable to tell the rest of the
// framework that we have some responsive settings.
$inuit-responsive-settings: true;
