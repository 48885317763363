/*------------------------------------*\
	#LOADER
\*------------------------------------*/

@import "@material/theme/mixins";

.loader {
  animation: rotate 2s linear;
	height: 50px;
  width: 50px;
  transition: opacity 0.3s;
}

.loader--small {
  animation: rotate 2s linear;
  height: 36px;
  width: 36px;
}

.loader__path {
  @include mdc-theme-prop(stroke, primary);

  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
	stroke-dasharray: 1,200;
	stroke-dashoffset: 0;
  stroke-linecap: round;

  .loader--small & {
    animation: dash-small 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-dasharray: 1,200;
  }
}

.loader__path--on-dark {
	@include mdc-theme-prop(stroke, text-secondary-on-dark);
}

@keyframes rotate {
	100% {
    transform: rotate(360deg);
	}
}
@keyframes dash-small {
	0% {
		stroke-dasharray: 1,200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89,200;
		stroke-dashoffset: -20;
	}
	100% {
		stroke-dasharray: 89,200;
		stroke-dashoffset: -60;
	}
}
@keyframes dash {
	0% {
		stroke-dasharray: 1,200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89,200;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 89,200;
		stroke-dashoffset: -124;
	}
}