form {
  @include mdc-theme-prop(background-color, on-surface);
  width: 50vw;
  padding: 2rem;
  margin: auto;
  justify-content: center;
  text-align: left;

  input,
  textarea {
    width: calc(100% - 2rem);
    padding: .5rem;
    font-size: 1.125em;
    border: 1px solid #000;
  }

  div {
    margin-bottom: 20px;
  }

  a {
    @include mdc-theme-prop(color, on-surface);

    &.btn-active {
      @include mdc-theme-prop(color, surface);
    }
  }
}
