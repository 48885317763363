/* MDC */
$mdc-theme-primary: #FAD000;
$mdc-theme-secondary: #EDC7A2;
$mdc-theme-surface: #FF4438;
$mdc-theme-background: #212721;

$mdc-theme-text-primary-on-background: #212721;
$mdc-theme-text-secondary-on-background: #EDC7A2;

/* Message Feedback */
$success-text-color: #50CD8E !default;
$warning-text-color: #fb8c00 !default;
$alert-text-color: #c53929 !default;

// Temp Fix for MDC Drawer background
$mdc-permanent-drawer-fill-color: background !default;

$mdc-typography-font-family: sans-serif;

$mdc-typography-font-weight-values: (
	light: 300,
	regular: 400,
	medium: 600,
	bold: 700,
	black: 900
);

$mdc-typography-styles-button: (
	font-family: unquote("sans-serif"),
	font-weight: map-get($mdc-typography-font-weight-values, medium)
);

/* Scroll Bars */

$webkitScrollBarWidth: 0px;
$scroll-bar-thumb: $mdc-theme-primary;
$scroll-bar-track: $mdc-theme-primary;

html {
	cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" style="font-size: 120px"><text y="120">👉🏻</text></svg>'),
	auto;
}

a{
	cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" style="font-size: 32px"><text y="28">🍔</text></svg>'),
	auto;
}

input{
	cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" style="font-size: 32px"><text y="28">🍦</text></svg>'),
	auto;
}
